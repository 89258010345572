import * as yup from "yup";

export default [
  {
    column: 1,
    fields: [
      {
        name: "name",
        type: "text",
        label: "Truck Type",
        validation: yup.mixed().required("Required"),
      },
    ],
  },
];
