import enums from "enums/index";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import planOrderValidation from "./PlanOrder.validation";

//-------------------------------------------------------------------------------------

export default function usePlanOrderModal({
  order,
  orderId,
  onClose,
  successCallback,
}) {
  const [sendRequest, isLoading] = useHttpClient(true);

  const { i18n } = useTranslation();

  const resolver = useYupValidationResolver(planOrderValidation());

  React.useEffect(() => {
    (async () => {
      if (order) {
        const tempOrder = { ...order };
        if (tempOrder.items?.length) {
          setValue(
            "items",
            tempOrder.items.filter(
              (item) => item.type === enums.ProductType.NEW
            )
          );
          setValue(
            "scraps",
            tempOrder.items.filter(
              (item) => item.type === enums.ProductType.SCRAP
            )
          );
        } else {
          setValue("items", []);
          setValue("scraps", []);
        }
        if (tempOrder.services?.length) {
          setValue("services", tempOrder.services);
        }
      }
    })();
  }, [order]);

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const { handleSubmit, getValues, setValue } = form;
  const plannedDeliveryCity = form.watch("plannedDeliveryCity");
  const submitHandler = async () => {
    let values = getValues();

    values = {
      ...values,
      id: orderId,

      plannedDeliveryLatitude:
        values.plannedDeliveryCoordinates?.split(",")[0]?.trim() ?? null,
      plannedDeliveryLongitude:
        values.plannedDeliveryCoordinates?.split(",")[1]?.trim() ?? null,
      paymentMethodId: values.paymentMethod?.id,
      plannedDeliveryDistrictId: values.plannedDeliveryDistrict?.id,
      zoneId: values.zone?.id,
      status: enums.OrderStatus.PLANNED,
      items: [...values.items, ...values.scraps],
      services: values?.services ?? [],
      desiredDeliveryTime: values.desiredDeliveryTime
        ? new Date(values.desiredDeliveryTime).toISOString()
        : null,
      plannedDeliveryTime: values.plannedDeliveryTime
        ? new Date(values.plannedDeliveryTime).toISOString()
        : null,
    };
    delete values.plannedDeliveryCity;
    delete values.plannedDeliveryDistrict;
    delete values.zone;
    delete values.scraps;
    try {
      const updatedOrder = await sendRequest(api.orders.plan(orderId, values));
      toast.success(`Order has been planned successfully`);
      successCallback(updatedOrder);

      onClose();
    } catch {}
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    isLoading,
    i18n,
    form,
    plannedDeliveryCity,
    submitHandler,
    onError,
    handleSubmit,
  };
}
