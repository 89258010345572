import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import CustomTable from "components/CustomTable/CustomTable";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TooltipWithIcon from "components/TooltipWithIcon/TooltipWithIcon";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useTruckInventory from "./TruckInventory.hook";
import styles from "./TruckInventory.styles";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function TruckInventory(props) {
  const classes = useStyles();

  const { i18n } = useTranslation();

  const { resource, assignedTruck, driverShift } = props;

  const {
    currentPage,
    listData,
    itemsPerPage,
    highlightedText,
    isGetLoading,
    handleChange,
    handleSubmit,
    getData,
  } = useTruckInventory({
    resource,
    assignedTruck,
  });

  const columns = React.useMemo(
    () => [
      {
        dataIndex: ["sapCode"],
        title: "SAP Code",
        canBeHighlighted: true,
      },
      {
        dataIndex: ["manufacturerName"],
        title: "Manufacturer",
      },
      {
        dataIndex: ["size"],
        title: "Size",
      },
      { dataIndex: ["stockItemsCount"], title: "All Stock Count" },
      {
        dataIndex: ["stockItemsOnHoldCount"],
        width: 120,
        title: () => {
          return (
            <div className={classes.tableHeaderContainer}>
              <span>Stock on Hold</span>
              <TooltipWithIcon
                title="Total stock on hold by a pending transfer 'FROM' a storage"
                marginLeft={5}
              />
            </div>
          );
        },
      },

      {
        dataIndex: ["availableStock"],
        width: 120,
        title: () => {
          return (
            <div className={classes.tableHeaderContainer}>
              <span>Available stock</span>
              <TooltipWithIcon
                title="All stock - stock on hold - open orders"
                marginLeft={5}
              />
            </div>
          );
        },
      },
    ],
    [listData]
  );

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <React.Fragment>
          <div className={classes.headerContainer}>
            <h3 className={classes.header}>
              {`Inventory for ${driverShift?.assignedTruck?.truckNumber} `}
            </h3>
          </div>

          <div className={classes.tableContainer}>
            <CustomTable
              columns={columns}
              data={listData.rows}
              loading={isGetLoading}
              addIndexColumn
              onChange={handleChange}
            />
          </div>
        </React.Fragment>
      </div>
    </div>
  );
}

TruckInventory.propTypes = {
  resource: PropTypes.string,
};
