import enums from "enums/index";
import qs from "qs";
import React from "react";
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";

//------------------------------------------------------------------------------

export default function useStockTransfers(props) {
  const {
    shouldGetTransfers,
    setShouldGetTransfers,
    selectedTruck,
    driverShift,
  } = props;
  const history = useHistory();
  const match = useRouteMatch();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [listData, setListData] = React.useState({ count: 0, rows: [] });
  const [itemsPerPage, setItemsPerPage] = React.useState(10);
  const [highlightedText, setHighlightedText] = React.useState("");
  const [sendGetRequest, isGetLoading] = useHttpClient();

  const getData = React.useCallback(
    async (page = currentPage, rowsPerPage = itemsPerPage) => {
      try {
        const response = await sendGetRequest(
          api.driverShifts.getDriverShiftsTransfers(
            page,
            rowsPerPage,
            driverShift?.id,
            enums.DriverShiftStockTransferType.START_OF_SHIFT
          )
        );
        setListData({
          count: response.count,
          rows: response.data,
        });
        setShouldGetTransfers(false);
      } catch (err) {
        toast.error("An error has occurred");
      }
    },
    [sendGetRequest, setListData, currentPage, itemsPerPage, shouldGetTransfers]
  );

  React.useEffect(() => {
    (async () => {
      try {
        const settings = JSON.parse(localStorage.getItem("settings"));
        const rowsPerPage =
          settings && settings[match.path] && settings[match.path].rowsPerPage
            ? settings[match.path].rowsPerPage
            : 10;

        setItemsPerPage(rowsPerPage);

        let { page, search, filters } = qs.parse(
          window.location.search?.split("?")[1]
        );
        setHighlightedText(search ? search : "");

        setCurrentPage(1);
        await getData(1, rowsPerPage, search);
      } catch (err) {
        toast.error("An error has occurred");
      }
      setShouldGetTransfers(false);
    })();
  }, [shouldGetTransfers]);

  React.useEffect(() => {
    if (shouldGetTransfers) {
      getData();
    }
  }, [shouldGetTransfers]);
  const handleChange = async (pagination) => {
    setCurrentPage(pagination.current);
    setItemsPerPage(pagination.pageSize);

    await getData(
      pagination.current,
      pagination.pageSize,
      undefined,
      undefined
    );
  };

  return {
    currentPage,
    listData,
    itemsPerPage,
    highlightedText,
    isGetLoading,
    setListData,
    getData,
    handleChange,
  };
}
