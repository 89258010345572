import enums from "enums/index";
import * as yup from "yup";

//----------------------------------------------------------

export default () => {
  return yup.object().shape({
    product: yup.mixed().required("Required"),
    amount: yup.number().required("Required"),
    from: yup.mixed().when("transferType", {
      is: (transferParty) =>
        transferParty?.id ===
        enums.ValidStartOfShiftStockTransfersTypes.FROM_WAREHOUSE_TO_TRUCK,
      then: yup.mixed().required("Required"),
    }),
    to: yup.mixed().when("transferType", {
      is: (transferParty) =>
        transferParty?.id ===
        enums.ValidStartOfShiftStockTransfersTypes.FROM_TRUCK_TO_WAREHOUSE,
      then: yup.mixed().required("Required"),
    }),
    transferType: yup.mixed().required("Required"),
  });
};
