import * as yup from "yup";

//----------------------------------------------------------

export default () => {
  return yup.object().shape({
    truckNumber: yup.mixed().required("Required"),
    truckType: yup.mixed().required("Required"),
    status: yup.mixed().required("Required"),
  });
};
