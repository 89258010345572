import translations from "constants/translations/index";
import api from "services/api";
import * as yup from "yup";

export default [
  {
    column: 1,
    fields: [
      {
        name: "name",
        type: "text",
        label: "Name",
        validation: yup.mixed().required("Required"),
      },
      {
        name: "email",
        type: "text",
        label: "Email",
        validation: yup.mixed(),
      },
      {
        name: "primaryPhoneNumber",
        type: "text",
        label: "Primary Phone",
        validation: yup.mixed().required("Required"),
      },
      {
        name: "secondaryPhoneNumber",
        type: "text",
        label: "Secondary Phone",
      },
      {
        name: "cardCode",
        type: "text",
        label: "Card Code",
      },
      {
        name: "dateJoined",
        type: "date",
        label: "Date Joined",
      },
      {
        name: "source",
        type: "select",
        label: "Source",
        options: {
          data: Object.keys(translations["en"].customerSource).map((key) => ({
            value: key,
            name: translations["en"].customerSource[key],
          })),
        },
      },
      {
        name: "gender",
        type: "select",
        label: "Gender",
        options: {
          data: Object.keys(translations["en"].gender).map((key) => ({
            value: key,
            name: translations["en"].gender[key],
          })),
        },
        validation: yup.mixed().required("Required"),
      },
      {
        name: "defaultDistrictId",
        fetchedName: "defaultDistrict",
        type: "autocomplete",
        label: "Default District",
        options: {
          getData: api.districts.getAll,
          getOptionLabel: (option) =>
            option?.city?.nameEn + " - " + option?.name,
          getSubmitValue: (option) => option?.id,
          blurOnSelect: false,
          loadOnFocus: true,
        },
      },
    ],
  },
];
