import usersRoutes from "./usersRoutes";
import adminIcon from "@material-ui/icons/Lock";
import permissionsRoutes from "./permissionsRoutes";
import enums from "enums/index";

export default {
  name: "Access Management",
  icon: adminIcon,
  layout: "/admin",
  collapse: true,
  state: "usersCollapse",
  resource: enums.Resource.ACCESS_MANAGEMENT,
  superAdmin: true,
  views: [
    { ...usersRoutes },
    // { ...departmentsRoutes },
    // { ...rolesRoutes },
    { ...permissionsRoutes },
  ],
};
