import * as yup from "yup";

//----------------------------------------------------------

export default () => {
  return yup.object().shape({
    owner: yup.mixed().required("Required"),
    maker: yup.mixed().required("Required"),
    model: yup.mixed().required("Required"),
    year: yup.mixed().required("Required"),
    batterySize: yup.mixed().required("Required"),
  });
};
