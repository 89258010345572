import enums from "enums/index";
import React from "react";
import BatterySizeRecommendationModal from "views/Orders/components/BatterySizeRecommendationModal/BatterySizeRecommendationModal";

// appear in breadcrumbs and page headers
const listPageName = "Battery Size Rec.";

const layout = "/admin";

const resource = enums.Resource.ORDERS_MANAGEMENT;

export default {
  name: listPageName,
  sidebarName: listPageName,
  icon: "battery_unknown",
  component: BatterySizeRecommendationModal,
  isModal: true,
  action: "read",
  layout: layout,
  resource,
};
