import * as yup from "yup";

//----------------------------------------------------------

export default () => {
  return yup.object().shape({
    assignedTruck: yup.mixed().required("Required"),
    assignedDriver: yup.mixed().required("Required"),
  });
};
