import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CheckPermission from "components/CheckPermission/CheckPermission";
import Button from "components/CustomButtons/Button.js";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import CustomTable from "components/CustomTable/CustomTable";
import enums from "enums/index";
import React from "react";
import OrderItemModal from "./components/OrderItemModal/OrderServiceModal";
import styles from "./OrderServices.styles";
import { isOrderEditable } from "utils/index";
import { AuthContext } from "shared/context/auth-context";
import useOrderServices from "./OrderServices.hook";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function OrderServices() {
  const classes = useStyles();

  const {
    order,
    isAssignModalOpen,
    isGetOrderLoading,
    editedService,
    services,
    onAssignModalClose,
    handleAssignService,
    handleRemoveService,
    onRemoveModalClose,
  } = useOrderServices();

  const { userData } = React.useContext(AuthContext);

  const columns = React.useMemo(
    () => [
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Price",
        dataIndex: "price",
      },

      {
        title: "",
        render: (_, record) => {
          return (
            <div className={classes.actionsContainer}>
              {isOrderEditable(order, userData) && (
                <CheckPermission action={enums.Action.UPDATE}>
                  <Button
                    justIcon
                    edit
                    color="white"
                    textColor="success"
                    onClick={() => handleAssignService(record)}
                  />
                </CheckPermission>
              )}
              {isOrderEditable(order, userData) && (
                <CheckPermission action={enums.Action.DELETE}>
                  <ConfirmationButton
                    name="this service"
                    entityName="Service"
                    onConfirm={() => handleRemoveService(record)}
                    onSuccess={() => onRemoveModalClose(record)}
                    buttonProps={{
                      justIcon: true,
                    }}
                  />
                </CheckPermission>
              )}
            </div>
          );
        },
      },
    ],
    [order]
  );

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        {isAssignModalOpen && (
          <OrderItemModal
            isOpen={isAssignModalOpen}
            onClose={onAssignModalClose}
            create={!editedService}
            edit={!!editedService}
            service={editedService}
          />
        )}
        <React.Fragment>
          <div className={classes.headerContainer}>
            <h3 className={classes.header}>Order Services</h3>
            {isOrderEditable(order, userData) && (
              <CheckPermission action={enums.Action.CREATE}>
                <Button
                  onClick={() => handleAssignService()}
                  className={classes.addButton}
                  color="success"
                >
                  <AddIcon className={classes.addIcon} />
                  Add Order Service
                </Button>
              </CheckPermission>
            )}
          </div>
          <div className={classes.tableContainer}>
            <CustomTable
              columns={columns}
              data={services}
              loading={isGetOrderLoading}
              addIndexColumn
            />
          </div>
        </React.Fragment>
      </div>
    </div>
  );
}
