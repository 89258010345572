import { makeStyles } from "@material-ui/core/styles";
import CustomTable from "components/CustomTable/CustomTable";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useInvoicesAndReportsList from "./InvoicesAndReportsList.hook";
import styles from "./InvoicesAndReportsList.styles";
import InvoicesStatusButtons from "./components/InvoicesStatusButtons";
import FileCard from "components/InfoWithLabel/FileCard/FileCard";
import { displayFileName, getDateString } from "utils/index";
import { Tag } from "antd";
import enums from "enums/index";
import translations from "constants/translations/index";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function InvoicesAndReportsList(props) {
  const classes = useStyles();

  const { i18n } = useTranslation();

  const { resource, driverShift } = props;

  const {
    reportsList,
    isGetReportsRequestLoading,
    handleChangeReports,
    updateInvoiceState,
  } = useInvoicesAndReportsList({
    resource,
    driverShift,
  });

  const columns = React.useMemo(
    () => [
      {
        title: "Report Type",
        dataIndex: "type",
        getData: (data) => {
          if (data) return translations["en"].driverShiftReportType[data];
          else return "-";
        },
        width: 100,
      },
      {
        render: (_, record) => {
          return record?.type === enums.DriverShiftReportType.INVOICE ? (
            record?.invoiceUrl ? (
              <div>
                <FileCard
                  name={`${getDateString(
                    record.createdAt,
                    "en-EG",
                    true
                  )}.xlsx`}
                  url={record?.invoiceUrl}
                />
              </div>
            ) : (
              <div>
                <Tag color="red">No completed orders yet in this shift</Tag>
              </div>
            )
          ) : record?.url ? (
            <div>
              <FileCard name={displayFileName(record?.url)} url={record?.url} />
            </div>
          ) : (
            <div>
              <Tag color="red">Report not yet generated</Tag>
            </div>
          );
        },
        title: "Reports File",
        width: 200,
      },
      {
        title: "",

        render: (_, record) => {
          if (
            record?.type === enums.DriverShiftReportType.INVOICE &&
            record?.id
          ) {
            return (
              <div style={{ display: "flex", width: "fit-content" }}>
                <InvoicesStatusButtons
                  invoiceId={record?.id}
                  isUploaded={record?.isUploaded}
                  successCallback={updateInvoiceState}
                />
              </div>
            );
          }
        },
        width: 270,
      },
    ],
    [reportsList]
  );

  return (
    <div>
      <GridContainer>
        <div className={classes.topContainer}>
          <h4 className={classes.title}>Shift Reports and Invoice</h4>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTable
              columns={columns}
              data={reportsList.rows}
              loading={isGetReportsRequestLoading}
              addIndexColumn
              onChange={handleChangeReports}
            />
          </GridItem>
        </div>
      </GridContainer>
    </div>
  );
}

InvoicesAndReportsList.propTypes = {
  resource: PropTypes.string,
};
