import enums from "enums/index";

export default {
  productCategory: {
    [enums.ProductCategory.BATTERIES]: "Batteries",
    [enums.ProductCategory.WB]: "WB",
    [enums.ProductCategory.TOOLS_AND_ACCESSORIES]: "Tools and Accessories",
  },
  orderStatus: {
    [enums.OrderStatus.LEAD]: "Lead",
    [enums.OrderStatus.PLANNED]: "Planned",
    [enums.OrderStatus.ASSIGNED]: "Assigned",
    [enums.OrderStatus.IN_PROGRESS]: "In Progress",
    [enums.OrderStatus.COMPLETED]: "Completed",
    [enums.OrderStatus.CANCELLED]: "Cancelled",
    [enums.OrderStatus.LOST]: "Lost",
    [enums.OrderStatus.PENDING_STOCK_APPROVAL]: "Pending Stock Approval",
    [enums.OrderStatus.STOCK_REJECTED]: "Stock Rejected",
  },
  sizeType: {
    [enums.SizeType.BATTERIES]: "Batteries",
    [enums.SizeType.WB]: "WB",
  },
  driverShiftStatus: {
    [enums.DriverShiftStatus.ACTIVE]: "Active",
    [enums.DriverShiftStatus.COMPLETED]: "Completed",
  },
  productType: {
    [enums.ProductType.NEW]: "New",
    [enums.ProductType.SCRAP]: "Scrap",
  },
  validStartOfShiftStockTransfersTypes: {
    [enums.ValidStartOfShiftStockTransfersTypes.FROM_WAREHOUSE_TO_TRUCK]:
      "Add stock from warehouse to truck",
    [enums.ValidStartOfShiftStockTransfersTypes.FROM_TRUCK_TO_WAREHOUSE]:
      "Remove stock from truck to warehouse ",
  },
  validStockTransfersTypesForSuperAdmin: {
    [enums.ValidStockTransfersTypes.INTERNAL_TRANSFER]:
      "Internal Transfer (storage to storage)",
    [enums.ValidStockTransfersTypes.ADDING_STOCK_TO_STORAGE]:
      "Adding stock to storage",
    [enums.ValidStockTransfersTypes.LOSING_STOCK_FROM_STORAGE]:
      "Losing stock from storage",
  },
  validStockTransfersTypesForWarehouseOfficer: {
    [enums.ValidStockTransfersTypes.INTERNAL_TRANSFER]:
      "Internal Transfer (storage to storage)",
  },
  driverShiftReportType: {
    [enums.DriverShiftReportType.START_OF_SHIFT]: "Start of Shift",
    [enums.DriverShiftReportType.END_OF_SHIFT]: "End of Shift",
    [enums.DriverShiftReportType.INVOICE]: "Invoice",
  },

  promoCodeType: {
    [enums.PromoCodeType.FIXED]: "Fixed",
    [enums.PromoCodeType.PERCENTAGE]: "Percentage",
  },
  transferPartyType: {
    [enums.TransferPartyType.CUSTOMER]: "Customer",
    [enums.TransferPartyType.STORAGE]: "Storage",
  },
  stockItemTransferStatus: {
    [enums.StockItemTransferStatus.PENDING]: "Pending",
    [enums.StockItemTransferStatus.APPROVED]: "Approved",
    [enums.StockItemTransferStatus.CANCELLED]: "Cancelled",
  },
  warrantyStatus: {
    [enums.WarrantyStatus.PENDING_ACTIVATION]: "Pending Activation",
    [enums.WarrantyStatus.ACTIVE]: "Active",
    [enums.WarrantyStatus.INACTIVE]: "Inactive",
  },
  storageType: {
    [enums.StorageType.WAREHOUSE]: "Warehouse",
    [enums.StorageType.TRUCK]: "Truck",
  },
  truckStatus: {
    [enums.TruckStatus.ACTIVE]: "Active",
    [enums.TruckStatus.INACTIVE]: "In Active",
  },
  driverStatus: {
    [enums.DriverStatus.ACTIVE]: "Active",
    [enums.DriverStatus.INACTIVE]: "In Active",
  },
  gender: {
    [enums.Gender.MALE]: "Male",
    [enums.Gender.FEMALE]: "Female",
  },
  warehouseType: {
    [enums.WarehouseType.SHOP]: "Shop",
    [enums.WarehouseType.WAREHOUSE]: "Warehouse",
    [enums.WarehouseType.VIRTUAL]: "Virtual",
  },

  deliveryType: {
    [enums.DeliveryType.ON_DEMAND]: "On-Demand",
    [enums.DeliveryType.SCHEDULED]: "Scheduled",
  },
  leadFollowUp: {
    [enums.LeadFollowUp.TRUE]: "Yes",
    [enums.LeadFollowUp.FALSE]: "No",
    [enums.LeadFollowUp.ORDERED]: "Ordered",
  },
  marketingSpendChannel: {
    [enums.MarketingSpendChannel.FACEBOOK]: "Facebook",
  },
  callType: {
    [enums.CallType.INBOUND]: "Inbound",
    [enums.CallType.OUTBOUND_ANSWERED]: "Outbound - Answered",
    [enums.CallType.OUTBOUND_UNANSWERED]: "Outbound - Unanswered",
  },
  callReason: {
    [enums.CallReason.NEW_ORDER]: "New Order",
    [enums.CallReason.ORDER_FOLLOW_UP]: "Order Follow Up",
    [enums.CallReason.ORDER_ISSUE]: "Order Issue",
    [enums.CallReason.GENERAL_ENQUIRY]: "General Enquiry",
  },
  resource: {
    [enums.Resource.ACCESS_MANAGEMENT]: "Access Management",
    [enums.Resource.SETTINGS]: "Settings",
    [enums.Resource.CAR_TYPES]: "Car Types",
    [enums.Resource.PRODUCTS_MANAGEMENT]: "Products Management",
    [enums.Resource.CLIENTS_MANAGEMENT]: "Clients Management",
    [enums.Resource.ZONING]: "Zoning",
    [enums.Resource.ORDERS_MANAGEMENT]: "Orders Management",
    [enums.Resource.STOCK_MANAGEMENT]: "Stock Management",
    [enums.Resource.STOCK_MANAGEMENT_DRIVERS]: "Stock Management -> Drivers",
    [enums.Resource.STOCK_MANAGEMENT_TRUCKS]: "Stock Management -> Trucks",
    [enums.Resource.STOCK_MANAGEMENT_WAREHOUSES]:
      "Stock Management -> Warehouses",
    [enums.Resource.STOCK_MANAGEMENT_STOCK_STATISTICS]:
      "Stock Management -> Stock Statistics",
    [enums.Resource.STOCK_MANAGEMENT_STOCK_TRANSFERS_APPROVAL]:
      "Stock Management -> Stock Transfers Approval",
    [enums.Resource.STOCK_MANAGEMENT_ORDER_TRANSFERS_APPROVAL]:
      "Stock Management -> Order Transfers Approval",
    [enums.Resource.STOCK_MANAGEMENT_WARRANTIES]:
      "Stock Management -> Warranties",
    [enums.Resource.STOCK_MANAGEMENT_INVOICES]: "Stock Management -> Invoices",
    [enums.Resource.STOCK_MANAGEMENT_STOCK_TRANSFERS_SHEETS]:
      "Stock Management -> Stock Transfers Sheets",
    [enums.Resource.STOCK_MANAGEMENT_WARRANTIES_SHEETS]:
      "Stock Management -> Warranties Sheets",
    [enums.Resource.DRIVER_SHIFT_MANAGEMENT]: "Driver Shift Management",
  },
  customerSource: {
    [enums.CustomerSource.FACEBOOK]: "Facebook",
    [enums.CustomerSource.FACEBOOK_GROUP]: "Facebook Group",
    [enums.CustomerSource.INSTAGRAM]: "Instagram",
    [enums.CustomerSource.SHOPIFY]: "Shopify",
    [enums.CustomerSource.REFERRAL]: "Referral",
    [enums.CustomerSource.SMS_CAMPAIGN]: "SMS Campaign",
    [enums.CustomerSource.VALUE]: "Value",
    [enums.CustomerSource.EVENTS]: "Events",
    [enums.CustomerSource.MAINTENANCE_CENTERS]: "Maintenance Centers",
    [enums.CustomerSource.WHATSAPP]: "Whatsapp",
  },
  customerChannel: {
    [enums.CustomerChannel.WHATSAPP]: "Whatsapp",
    [enums.CustomerChannel.FACEBOOK]: "Facebook",
    [enums.CustomerChannel.INSTAGRAM]: "Instagram",
    [enums.CustomerChannel.HOTLINE]: "Hotline",
    [enums.CustomerChannel.SHOPIFY]: "Shopify",
  },
  roleCode: {
    [enums.RoleCode.SUPER_ADMIN]: "Super Admin",
    [enums.RoleCode.ORDERING_ADMIN]: "Ordering Admin",
    [enums.RoleCode.STOCK_ADMIN]: "Stock Manager",
    [enums.RoleCode.OPS_MANAGER]: "Operations Manager",
    [enums.RoleCode.WAREHOUSE_OFFICER]: "Warehouse Officer",
  },
  anErrorHasOccurred: "An error has occurred",
};
