import React from "react";
import { Redirect, Route } from "react-router-dom";
import { AuthContext } from "shared/context/auth-context";

export default function PrivateRoute({ resources, action, render, ...rest }) {
  const auth = React.useContext(AuthContext);

  const isAccessible = React.useMemo(
    () => auth.getAccessPermission({ resources, action }),
    [auth.getAccessPermission, resources, action]
  );

  return (
    <Route
      {...rest}
      render={
        isAccessible
          ? render
          : () => (
              <Redirect
                to={{ pathname: `/admin/${auth.userData.resources[0]}` }}
              />
            )
      }
    />
  );
}
