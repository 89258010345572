import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Skeleton from "@material-ui/lab/Skeleton";

import Button from "components/CustomButtons/Button.js";
import FormFieldGridItem from "components/Forms/FormField/FormFieldGridItem";
import GridContainer from "components/Grid/GridContainer.js";
import Loading from "components/Loading/Loading";
import PropTypes from "prop-types";
import api from "services/api";
import { useWarehousesForm } from "./WarehousesForm.hook";
import styles from "./WarehousesForm.styles";
import React from "react";
import GridItem from "components/Grid/GridItem";
import FormField from "components/Forms/FormField/FormField";
import Info from "components/Typography/Info";
import InfoWithLabel from "components/InfoWithLabel/InfoWithLabel";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function WarehousesForm(props) {
  const { edit, create, warehouse } = props;

  const {
    isLoading,
    isGetLoading,
    form,
    data,
    watch,
    onSubmit,
  } = useWarehousesForm(props);

  const classes = useStyles();

  return (
    <div className={classes.formRoot}>
      <div className={classes.formHeader}>
        {create ? (
          <AddIcon className={classes.icon} />
        ) : (
          <EditIcon className={classes.icon} />
        )}
        {edit ? (
          isGetLoading ? (
            <Skeleton variant="rect" height={25} width={250} />
          ) : (
            `Edit ${warehouse?.name}'s Warehouse`
          )
        ) : (
          `Create Warehouse`
        )}
      </div>

      <Loading loading={isGetLoading} style={{ height: "60vh" }}>
        <form
          style={{ padding: 40, paddingBottom: 0, paddingTop: 0 }}
          onSubmit={onSubmit}
          autoComplete="off"
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <FormField
                name="name"
                md={12}
                fetchedName="name"
                type="text"
                form={form}
                label="Name"
              />
              <FormField
                name="address"
                md={12}
                fetchedName="address"
                type="text"
                form={form}
                label="Address"
              />

              <FormField
                name="storage.warehouseCode"
                md={12}
                fetchedName="storage.warehouseCode"
                type="text"
                label="Warehouse Code"
                form={form}
              />
              <FormField
                name="warehouseType"
                type="select"
                label="Warehouse Type"
                options={{
                  enum: "warehouseType",
                }}
                form={form}
              />
            </GridItem>
          </GridContainer>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="primary"
              type="submit"
              loading={isLoading}
              style={{ margin: "20px 0" }}
              create={create}
              edit={edit}
              loadFromRight
            />
          </div>
        </form>
      </Loading>
    </div>
  );
}

WarehousesForm.propTypes = {
  edit: PropTypes.bool,
  create: PropTypes.bool,
};
