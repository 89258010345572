import DataList from "components/DataList/DataList";
import FormContainer from "components/Forms/FormContainer/FormContainer";
import enums from "enums/index";
import api from "services/api";
import carModelsForm from "views/CarTypes/carModelsForm";
import React from "react";

// appear in breadcrumbs and page headers
const listPageName = "Car Models";
const createPageName = "Create Car Model";
const editPageName = "Edit Car Model";

// appears on alerts; example: Entity created successfully
const entityName = "Car Model";

const layout = "/admin";

const path = "car-models";

const formStructure = carModelsForm;

const dataResource = api.carModels;

const resource = enums.Resource.CAR_TYPES;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    canBeHighlighted: true,
  },
  {
    title: "Car Maker",
    dataIndex: ["maker", "name"],
    canBeHighlighted: true,
  },
  {
    dataIndex: ["stockBatterySize", "name"],
    title: "Stock Battery Size",
  },
  {
    dataIndex: ["stockWiperBladeSize", "name"],
    title: "Stock Wiper Blade Size",
  },

  {
    title: "Year",
    dataIndex: "year",
    canBeHighlighted: true,
  },
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: "commute",
  render: () => (
    <DataList
      entityName={entityName}
      path={path}
      columns={columns}
      actions={{ edit: true, delete: true }}
      dataResource={dataResource}
      resource={resource}
      listPageName={listPageName}
      formStructure={formStructure}
      formType={enums.FormContainerType.MODAL}
    />
  ),
  action: "read",
  layout: layout,
  // randomKey: true,
  children: true,
  superAdmin: true,
  resource,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          create
        />
      ),
      layout: layout,
      randomKey: true,
      action: "create",
      superAdmin: true,
      resource,
    },
    {
      path: `/${path}/:id/edit`,
      name: editPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          edit
        />
      ),
      layout: layout,
      randomKey: true,
      action: "update",
      superAdmin: true,
      resource,
    },
  ],
};
