import * as yup from "yup";
import enums from "enums/index";
export default [
  {
    column: 1,
    fields: [
      {
        name: "name",
        type: "text",
        label: "Name",
        validation: yup.mixed().required("Required"),
      },
      {
        name: "address",
        type: "text",
        label: "Address",
        validation: yup.mixed().required("Required"),
      },
      {
        name: "storage.warehouseCode",
        type: "text",
        label: "Warehouse Code",
      },
      {
        name: "warehouseType",
        type: "select",
        label: "Warehouse Type",
        options: {
          enum: "warehouseType",
        },
        validation: yup.mixed().required("Required"),
      },
    ],
  },
];
