import DataList from "components/DataList/DataList";
import FormContainer from "components/Forms/FormContainer/FormContainer";
import enums from "enums/index";
import React from "react";
import api from "services/api";
import districtsForm from "views/Zoning/districtsForm";

// appear in breadcrumbs and page headers
const listPageName = "Districts";
const createPageName = "Create District";
const editPageName = "Edit District";

// appears on alerts; example: Entity created successfully
const entityName = "District";

const resource = enums.Resource.ZONING;

const layout = "/admin";

const path = "districts";

const formStructure = districtsForm;

const dataResource = api.districts;

const columns = [
  {
    title: "Name (English)",
    dataIndex: "nameEn",
    canBeHighlighted: true,
  },
  {
    title: "Name (Arabic)",
    dataIndex: "nameAr",
    canBeHighlighted: true,
  },
  {
    title: "City",
    dataIndex: ["city", "nameEn"],
  },
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: "gps_fixed",
  render: () => (
    <DataList
      entityName={entityName}
      path={path}
      columns={columns}
      actions={{ edit: true, delete: true }}
      dataResource={dataResource}
      resource={resource}
      listPageName={listPageName}
      formStructure={formStructure}
      formType={enums.FormContainerType.MODAL}
    />
  ),
  action: "read",
  resource,
  layout: layout,
  // randomKey: true,
  children: true,
  superAdmin: true,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          create
        />
      ),
      layout: layout,
      randomKey: true,
      action: "create",
      superAdmin: true,
      resource,
    },
    {
      path: `/${path}/:id/edit`,
      name: editPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          edit
        />
      ),
      layout: layout,
      superAdmin: true,
      randomKey: true,
      action: "update",
      resource,
    },
  ],
};
