import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import { deepen, flattenObject } from "utils/index";
import { OrderContext } from "views/Orders/pages/OrderView/Order.context";
import { useHttpClient } from "shared/hooks/http-hook";
import ItemValidation from "./OrderItem.validation";
import enums from "enums/index";
import { OrderDetailsFormContext } from "views/Orders/components/OrderDetailsForm/OrderDetailsForm.context";
import api from "services/api";

//-------------------------------------------------------------------------------------

export default function useOrderItemModal({
  itemProp,
  onClose,
  onAdd,
  onEdit,
}) {
  const { order } = React.useContext(OrderContext);
  const { selectedCar } = React.useContext(OrderDetailsFormContext);

  const { i18n } = useTranslation();

  const resolver = useYupValidationResolver(ItemValidation());

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
    defaultValues: {
      amount: 1,
    },
  });

  const [promoCodeDisabled, setPromoCodeDisabled] = React.useState(false);

  const [sendCheckPromoCodeRequest, isCheckPromoCodeLoading] = useHttpClient(
    true
  );
  const [
    sendCheckStockAvailabilityRequest,
    isCheckStockAvailabilityLoading,
  ] = useHttpClient(true);

  const { handleSubmit, getValues, setValue, watch } = form;
  const promoCode = watch("promoCode.code");
  const promoCodeId = watch("promoCode.id");
  const promoCodeObj = watch("promoCode");

  const [sendRequest, isLoading] = useHttpClient(true);
  const [remainingAmount, setRemainingAmount] = React.useState(null);
  const productId = form.watch("product")?.id;

  useEffect(() => {
    setPromoCodeDisabled(!!promoCodeId);
  }, [promoCodeId]);

  React.useEffect(() => {
    if (productId) {
      (async () => {
        try {
          const response = await sendCheckStockAvailabilityRequest(
            api.products.getProductAvailability(productId, order.id)
          );

          setRemainingAmount(response);
        } catch (err) {
          toast.error("An error has occurred");
        }
      })();
    }
  }, [productId]);

  React.useEffect(() => {
    if (itemProp) {
      const formData = {
        ...flattenObject(itemProp),
        orderId: order.id,
      };

      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key]);
      });
    } else {
      setValue("orderId", order.id);
    }
  }, [itemProp, order.id]);

  const checkAndApplyPromoCode = async () => {
    if (!promoCode) {
      toast.error("Please enter a promo code");
      return;
    }
    try {
      const response = await sendCheckPromoCodeRequest(
        api.promoCodes.check(promoCode)
      );
      if (response) {
        toast.success(`Promo code has been applied successfully`);
        setValue("promoCode", response);
      }
    } catch (error) {}
  };

  const removePromoCode = () => {
    setValue("promoCode", { code: "" });
  };
  const createHandler = (values) => async () => {
    try {
      onAdd(deepen(values));
      // toast.success(`Order item has been added successfully`);
      onClose();
    } catch {}
  };

  const editHandler = (values) => async () => {
    try {
      onEdit(deepen(values));
      // toast.success(`Order item has been updated successfully`);

      onClose();
    } catch {}
  };

  const submitHandler = async (e) => {
    if (e) {
      if (typeof e.preventDefault === "function") {
        e.preventDefault();
      }
      if (typeof e.stopPropagation === "function") {
        e.stopPropagation();
      }
    }

    let values = getValues();
    values = {
      ...values,
      promoCodeId: values.promoCode?.id ?? null,
      productId: values.product?.id,
      totalPriceBeforeDiscount: 0,
      totalPrice: 0,
      orderId: order.id,
      unitPrice: values.product?.unitPrice || 0,
      type: enums.ProductType.NEW,
    };
    if (!values.promoCodeId) delete values.promoCode;

    if (values.amount && values.product?.unitPrice) {
      values.totalPriceBeforeDiscount = (
        values.amount * values.product.unitPrice
      ).toFixed(2);
      values.totalPrice = values.totalPriceBeforeDiscount;
      if (values.promoCode) {
        values.totalPrice =
          values.totalPrice * ((100 - values.promoCode?.amount) / 100);
      }
    }

    const checkStockAvailability = await sendRequest(
      api.orders.checkStockAvailability(
        [{ productId: productId, amount: values.amount }],
        order.id
      )
    );
    handleSubmit(
      !!itemProp ? editHandler(values) : createHandler(values),
      onError
    )();
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    i18n,
    form,
    selectedCar,
    order,
    isCheckPromoCodeLoading,
    promoCodeDisabled,
    remainingAmount,
    isLoading,
    productId,
    isCheckStockAvailabilityLoading,
    submitHandler,
    onError,
    checkAndApplyPromoCode,
    removePromoCode,
  };
}
