import api from "services/api";
import React from "react";
import StockTransfersSheetsList from "views/StockManagement/StockTransfersSheetsManagement/pages/StockTransfersSheetsList/StockTransfersSheetsList";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import enums from "enums/index";
// appear in breadcrumbs and page headers
// appear in breadcrumbs and page headers
const listPageName = "Stock Transfers Sheets";

// appears on alerts; example: Entity created successfully
const entityName = "stock transfer sheet";

const layout = "/admin";

const path = "transfers-sheets";

const dataResource = api.stockTransfersSheets;

const resource = [
  enums.Resource.STOCK_MANAGEMENT,
  enums.Resource.STOCK_MANAGEMENT_STOCK_TRANSFERS_SHEETS,
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: CompareArrowsIcon,
  render: () => <StockTransfersSheetsList />,
  action: "read",
  resource,
  layout: layout,
  randomKey: true,
};
