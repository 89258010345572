import enums from "enums/index";
import * as yup from "yup";

//-------------------------------------------------------------------------------------

const orderInfoRequiredCondition = (status) =>
  status !== enums.OrderStatus.LEAD &&
  status !== enums.OrderStatus.CANCELLED &&
  status !== enums.OrderStatus.LOST;
const matchingInfoRequiredCondition = (status) =>
  status !== enums.OrderStatus.LEAD &&
  status !== enums.OrderStatus.PLANNED &&
  status !== enums.OrderStatus.CANCELLED &&
  status !== enums.OrderStatus.LOST;
const deliveryInfoRequiredCondition = (status) =>
  status !== enums.OrderStatus.LEAD &&
  status !== enums.OrderStatus.PLANNED &&
  status !== enums.OrderStatus.ASSIGNED &&
  status !== enums.OrderStatus.CANCELLED &&
  status !== enums.OrderStatus.LOST;
const cancellationInfoRequiredCondition = (status) =>
  status === enums.OrderStatus.CANCELLED;

export default () => {
  return yup.object({
    customer: yup.mixed().required("Required"),
    car: yup.mixed().required("Required"),
    channel: yup.mixed().required("Required"),
    leadFollowUp: yup.mixed().required("Required"),
    followUpDate: yup.mixed().when("leadFollowUp", {
      is: enums.LeadFollowUp.TRUE,
      then: yup.mixed().required("Required"),
    }),
    leadLossReason: yup.mixed().when("leadFollowUp", {
      is: enums.LeadFollowUp.FALSE,
      then: yup.mixed().required("Required"),
    }),
    notes: yup.mixed(),
    // Order Info, required if status is not LEAD
    deliveryType: yup.mixed().when("status", {
      is: (status) => orderInfoRequiredCondition(status),
      then: yup.mixed().required("Required"),
    }),
    paymentMethod: yup.mixed().when("status", {
      is: (status) => orderInfoRequiredCondition(status),
      then: yup.mixed().required("Required"),
    }),
    zone: yup.mixed().when("status", {
      is: (status) => matchingInfoRequiredCondition(status),
      then: yup.mixed().required("Required"),
    }),
    plannedDeliveryTime: yup.mixed().when("status", {
      is: (status) => orderInfoRequiredCondition(status),
      then: yup.mixed().required("Required"),
    }),
    desiredDeliveryTime: yup.mixed().when("status", {
      is: (status) => orderInfoRequiredCondition(status),
      then: yup.mixed().required("Required"),
    }),
    plannedDeliveryStreet: yup.mixed().when("status", {
      is: (status) => matchingInfoRequiredCondition(status),
      then: yup.mixed().required("Required"),
    }),

    plannedDeliveryCoordinates: yup.mixed().when("status", {
      is: (status) => matchingInfoRequiredCondition(status),
      then: yup
        .string()
        .test(
          "isValidCoordinates",
          "Invalid coordinates format it should be in lat,lng format",
          (value) => {
            if (value) {
              const coordinates = value.split(",");

              return (
                coordinates.length === 2 &&
                /^\d/.test(coordinates[0]) &&
                /\d$/.test(coordinates[1])
              );
            }

            return false;
          }
        ),
    }),
    plannedDeliveryDistrict: yup.mixed().when("status", {
      is: (status) => matchingInfoRequiredCondition(status),
      then: yup.mixed().required("Required"),
    }),
    plannedDeliveryCity: yup.mixed().when("status", {
      is: (status) => matchingInfoRequiredCondition(status),
      then: yup.mixed().required("Required"),
    }),

    // Matching Info, required if status is not LEAD or PLANNED
    assignedDriver: yup.mixed().when("status", {
      is: (status) => matchingInfoRequiredCondition(status),
      then: yup.mixed().required("Required"),
    }),
    assignedTruck: yup.mixed().when("status", {
      is: (status) => matchingInfoRequiredCondition(status),
      then: yup.mixed().required("Required"),
    }),
    items: yup.mixed().when("status", {
      is: (status) => orderInfoRequiredCondition(status),
      then: yup
        .array()
        .of(
          yup.object().shape({
            amount: yup.mixed().required("Required"),
            product: yup.mixed().required("Required"),
          })
        )
        .test(
          "order items validation",
          "Please add at least one item or service",
          (value, context) => {
            const items = context.parent.items;
            const services = context.parent.services;

            // Check if either items or services is present
            return (
              (items && items.length > 0) || (services && services.length > 0)
            );
          }
        ),
    }),

    services: yup.mixed().when("status", {
      is: (status) => orderInfoRequiredCondition(status),
      then: yup
        .array()
        .of(
          yup.object().shape({
            service: yup.mixed().required("Required"),
          })
        )
        .test(
          "order items validation",
          "Please add at least one item or service",
          (value, context) => {
            const items = context.parent.items;
            const services = context.parent.services;

            // Check if either items or services is present
            return (
              (items && items.length > 0) || (services && services.length > 0)
            );
          }
        ),
    }),

    // Delivery Info, required if status is not LEAD or PLANNED or ASSIGNED

    // actualDeliveryTime: yup.mixed().when("status", {
    //   is: (status) => deliveryInfoRequiredCondition(status),
    //   then: yup.mixed().required("Required"),
    // }),
    // actualDeliveryStreet: yup.mixed().when("status", {
    //   is: (status) => deliveryInfoRequiredCondition(status),
    //   then: yup.mixed().required("Required"),
    // }),
    // actualDeliveryCoordinates: yup.mixed().when("status", {
    //   is: (status) => deliveryInfoRequiredCondition(status),
    //   then: yup
    //     .string()
    //     .test(
    //       "isValidCoordinates",
    //       "Invalid coordinates format it should be in lat,lng format",
    //       (value) => {
    //         if (value) {
    //           const coordinates = value.split(",");

    //           // Check if there are at least one number before and after the comma
    //           return (
    //             coordinates.length === 2 &&
    //             /^\d/.test(coordinates[0]) &&
    //             /\d$/.test(coordinates[1])
    //           );
    //         }

    //         return false;
    //       }
    //     )
    //     .required("Required"),
    // }),

    // actualDeliveryDistrict: yup.mixed().when("status", {
    //   is: (status) => deliveryInfoRequiredCondition(status),
    //   then: yup.mixed().required("Required"),
    // }),
    // actualDeliveryCity: yup.mixed().when("status", {
    //   is: (status) => deliveryInfoRequiredCondition(status),
    //   then: yup.mixed().required("Required"),
    // }),
    // orderRating: yup.mixed().when("status", {
    //   is: (status) => deliveryInfoRequiredCondition(status),
    //   then: yup.mixed().required("Required"),
    // }),
    // // Cancellation Info, required if status is CANCELLED
    // cancellationReason: yup.mixed().when("status", {
    //   is: (status) => cancellationInfoRequiredCondition(status),
    //   then: yup.mixed().required("Required"),
    // }),
  });
};
