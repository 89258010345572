import * as yup from "yup";
import carModelsForm from "./carModelsForm";
import api from "services/api";
import { displayCarName } from "utils/index";

export default [
  {
    column: 1,
    fields: [
      {
        name: "name",
        type: "text",
        label: "Name",
        validation: yup.mixed().required("Required"),
      },
      {
        name: "carModelIds",
        fetchedName: "models",
        type: "autocomplete",
        label: "Car Models",
        options: {
          getData: () => api.carModels.getAll(),
          getOptionLabel: (option) => displayCarName(option),
          getSubmitValue: (options) => options?.map((option) => option?.id),
          multiple: true,
          addNew: true,
          formStructure: carModelsForm,
          freeSolo: true,
          dataResource: api.carModels,
          blurOnSelect: false,
        },
        validation: yup
          .mixed()
          .required("Required")
          .test({
            message: "Required",
            test: (models) => models?.length > 0,
          }),
      },
    ],
  },
];
