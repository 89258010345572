import { yellowColor } from "assets/jss/material-dashboard-pro-react.js";
import customDropdownStyle from "assets/jss/material-dashboard-pro-react/components/customDropdownStyle.js";

const styles = (theme) => ({
  ...customDropdownStyle(theme),
  search: {
    margin: "0",
    paddingTop: "7px",
    paddingBottom: "7px",
  },
  searchInput: {
    paddingTop: "2px",
  },
  searchButton: {
    marginLeft: 20,
    [theme.breakpoints.down("sm")]: {
      //   top: "-50px !important",
      marginRight: "38px",
      float: "right",
    },
  },
  top: {
    zIndex: "4",
  },
  searchIcon: {
    width: "17px",
    zIndex: "4",
  },
  headerLinksSvg: {
    width: "20px !important",
    height: "20px !important",
  },
  topContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 60,
  },
  searchContainer: { display: "flex", alignItems: "center" },
  editIcon: { cursor: "pointer", color: yellowColor[0] },
  deleteIcon: { cursor: "pointer", color: "red" },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  },
  exportButton: { height: 35, margin: 0 },
  cancelButton: {
    marginLeft: 5,
    fontSize: 12,
    padding: "5px 15px",
    height: 30,
    width: 67,
  },
  statusButton: {
    marginLeft: 5,
    fontSize: 12,
    padding: "5px 15px",
    height: 30,
    width: 135,
  },
  viewButton: {
    padding: "5px 5px 3.5px 10px",
    border: "none !important",
    marginLeft: 5,
    boxShadow:
      "0 2px 2px 0 rgba(43, 41, 118, 0.14), 0 3px 1px -2px rgba(43, 41, 118, 0.2), 0 1px 5px 0 rgba(43, 41, 118, 0.12)",
  },
  nextIcon: {
    // marginBottom: "2px !important",
  },
});

export default styles;
