import { makeStyles } from "@material-ui/core/styles";
import CustomTable from "components/CustomTable/CustomTable";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import translations from "constants/translations/index";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./StockTransfers.styles";
import CheckPermission from "components/CheckPermission/CheckPermission";
import useStockTransfers from "./StockTransfers.hook";
import { displayTransferParty } from "utils/index";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function StockTransfers(props) {
  const classes = useStyles();

  const { i18n } = useTranslation();

  const {
    resource,
    shouldGetTransfers,
    setShouldGetTransfers,
    selectedTruck,
    title,
    driverShift,
  } = props;

  const {
    currentPage,
    listData,
    itemsPerPage,
    highlightedText,
    isGetLoading,
    handleChange,
    handleSubmit,
    getData,
  } = useStockTransfers({
    resource,
    setShouldGetTransfers,
    shouldGetTransfers,
    selectedTruck,
    driverShift,
  });

  const columns = React.useMemo(
    () => [
      {
        dataIndex: ["product", "sapCode"],
        title: "SAP Code",
        canBeHighlighted: true,
      },

      {
        dataIndex: ["product", "description"],
        title: "Item Description",
      },

      {
        title: "To",
        dataIndex: ["to"],
        render: (data) => displayTransferParty(data),
      },
      {
        title: "Status",
        dataIndex: ["status"],
        getData: (data) =>
          translations[i18n.language].stockItemTransferStatus[data],
      },

      {
        dataIndex: ["product", "size", "name"],
        title: "Battery Size",
      },
      {
        dataIndex: ["product", "type"],
        title: "Battery Type",
      },
      {
        dataIndex: ["amount"],
        title: "Amount",
      },
    ],
    [listData]
  );

  return (
    <div>
      <div className={classes.topContainer}>
        <div className={classes.buttonsContainer} />
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTable
            columns={columns}
            data={listData.rows}
            onChange={handleChange}
            pagination={{
              total: listData.count,
              current: currentPage,
              pageSize: itemsPerPage,
              position: ["topRight"],
              pageSizeOptions: [10, 20, 50],
            }}
            loading={isGetLoading}
            highlightedText={highlightedText}
            handleSubmit={handleSubmit}
            title={title}
            addIndexColumn
            size="middle"
            fixIndexColumn
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

StockTransfers.propTypes = {
  resource: PropTypes.string,
};
