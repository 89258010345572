import DataList from "components/DataList/DataList";
import FormContainer from "components/Forms/FormContainer/FormContainer";
import enums from "enums/index";
import api from "services/api";
import React from "react";
import productsForm from "views/ProductsManagement/productsForm";
import translations from "constants/translations/index";

// appear in breadcrumbs and page headers
const listPageName = "Products";
const createPageName = "Create Product";
const editPageName = "Edit Product";

// appears on alerts; example: Entity created successfully
const entityName = "Product";

const layout = "/admin";

const path = "products";

const formStructure = productsForm;

const dataResource = api.products;

const resource = enums.Resource.PRODUCTS_MANAGEMENT;

const filterInputs = [
  {
    name: "type",
    label: "Type",
    type: "select",
    options: {
      data: Object.keys(translations["en"].productType).map((key) => ({
        value: key,
        name: translations["en"].productType[key],
      })),
      addAllChoice: true,
    },
  },
];

const columns = [
  {
    title: "SAP Code",
    dataIndex: "sapCode",
    canBeHighlighted: true,
  },
  {
    title: "Group",
    dataIndex: "group",
    getData: (data) => {
      if (data) return translations["en"].productCategory[data];
      else return "-";
    },
  },
  {
    title: "Unit Price",
    dataIndex: "unitPrice",
  },
  {
    dataIndex: ["size", "name"],
    title: "Size",
  },
  {
    title: "Manufacturer",
    dataIndex: ["manufacturer", "name"],
  },
  {
    title: "Type",
    dataIndex: "type",
    getData: (data) => {
      if (data) return translations["en"].productType[data];
      else return "-";
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    canBeHighlighted: true,
  },
  {
    title: "Serial Required",
    dataIndex: "serialRequired",
    getData: (data) => {
      if (data) return "Yes";
      else return "No";
    },
  },
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: "widgets",
  render: () => (
    <DataList
      filterInputs={filterInputs}
      entityName={entityName}
      path={path}
      columns={columns}
      actions={{ edit: true, delete: true }}
      dataResource={dataResource}
      resource={resource}
      listPageName={listPageName}
      formStructure={formStructure}
      formType={enums.FormContainerType.MODAL}
      getRowName={(row) => (row?.sapCode ? `#${row?.sapCode}` : entityName)}
    />
  ),
  action: "read",
  layout: layout,
  // randomKey: true,
  children: true,
  superAdmin: true,
  resource,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          create
        />
      ),
      layout: layout,
      randomKey: true,
      action: "create",
      superAdmin: true,
      resource,
    },
    {
      path: `/${path}/:id/edit`,
      name: editPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          edit
        />
      ),
      layout: layout,
      randomKey: true,
      action: "update",
      superAdmin: true,
      resource,
    },
  ],
};
