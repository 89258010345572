import { makeStyles } from "@material-ui/core/styles";
import { Person } from "@material-ui/icons";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomModal from "components/CustomModal/CustomModal";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoWithLabel from "components/InfoWithLabel/InfoWithLabel";
import PropTypes from "prop-types";
import React from "react";
import styles from "./BatterySizeRecommendation.styles";
import Button from "components/CustomButtons/Button.js";
import GenericInput from "components/GenericInput/GenericInput";
import api from "services/api";
import { displayCarName } from "utils/index";
import { ListItem } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import ListItemText from "@material-ui/core/ListItemText";
import cx from "classnames";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function BatterySizeRecommendationModal({
  buttonProps,
  ...rest
}) {
  const classes = useStyles();

  const { icon: iconComponent, name, sidebarName } = buttonProps;
  const {
    collapseItemMini,
    collapseItemText,
    itemText,
    navLinkClasses,
    innerNavLinkClasses,
    miniIcon,
    itemIcon,
  } = rest;

  const [makerInput, setMakerInput] = React.useState();
  const [modelInput, setModelInput] = React.useState();
  const [yearInput, setYearInput] = React.useState();

  const [isOpen, setIsOpen] = React.useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  React.useEffect(() => {
    if (isOpen == false) {
      setMakerInput(null);
      setModelInput(null);
      setYearInput(null);
    }
  }, [isOpen]);

  return (
    <React.Fragment>
      <div
        style={{ cursor: "pointer" }}
        className={cx(
          { [navLinkClasses]: iconComponent !== undefined },
          { [innerNavLinkClasses]: iconComponent === undefined }
        )}
        onClick={() => setIsOpen(true)}
      >
        {iconComponent !== undefined ? (
          typeof iconComponent === "string" ? (
            <Icon className={itemIcon + " " + miniIcon}>{iconComponent}</Icon>
          ) : (
            <iconComponent className={itemIcon} />
          )
        ) : (
          <span className={collapseItemMini}>
            {name
              ?.split(" ")
              ?.map((word) => word[0])
              ?.join("")}
          </span>
        )}
        <ListItemText
          primary={sidebarName}
          disableTypography={true}
          className={cx(
            { [itemText]: iconComponent !== undefined },
            { [collapseItemText]: iconComponent === undefined }
          )}
        />
      </div>
      {isOpen && (
        <CustomModal
          open={isOpen}
          onClose={onClose}
          modalHeaderClassName={classes.modalHeader}
          modalBodyClassName={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card style={{ margin: 0 }}>
                <CardBody>
                  <div className={classes.header}>
                    <React.Fragment>
                      <Person className={classes.icon} /> Battery Size
                      Recommendation Info
                    </React.Fragment>
                  </div>

                  <GenericInput
                    type="autocomplete"
                    md={12}
                    name="maker"
                    fetchedName="maker"
                    label="Car Maker"
                    options={{
                      getData: api.carMakers.getAll,
                      getOptionLabel: (option) => option.name,
                      // getSubmitValue: (option) => option?.id,
                      loadOnFocus: true,
                      blurOnSelect: false,
                    }}
                    value={makerInput}
                    onChange={(_, value) => {
                      setMakerInput(value);
                      setModelInput(null);
                      setYearInput(null);
                    }}
                  />
                  <GenericInput
                    type="autocomplete"
                    md={12}
                    name="model"
                    fetchedName="model"
                    label="Car Model"
                    options={{
                      getData: () =>
                        api.carModels.getCarModelByMakerName(makerInput?.id),
                      getOptionLabel: (option) => option.name,
                      // getSubmitValue: (option) => option?.id,
                      loadOnFocus: true,
                      blurOnSelect: false,
                      disabled: !makerInput,
                      disabledTooltipText: "Please select a car maker first",
                    }}
                    value={modelInput}
                    onChange={(_, value) => {
                      setModelInput(value);
                      setYearInput(null);
                    }}
                  />

                  <GenericInput
                    type="autocomplete"
                    md={12}
                    name="year"
                    fetchedName="year"
                    label="Car Year"
                    value={yearInput}
                    options={{
                      getData: () =>
                        api.carModels.getCarYearsByModelName(modelInput?.name),
                      getOptionLabel: (option) =>
                        option?.year?.toString() || "Unknown",
                      loadOnFocus: true,
                      blurOnSelect: false,
                      disabled: !modelInput,
                      disabledTooltipText: "Please select a car model first",
                    }}
                    onChange={(_, value) => setYearInput(value)}
                  />
                  <InfoWithLabel
                    label="Selected Car Model"
                    info={yearInput ? displayCarName(yearInput) : "N/A"}
                  />
                  <InfoWithLabel
                    label="Recommended Battery Size"
                    info={yearInput?.stockBatterySize?.name || "N/A"}
                  />

                  <InfoWithLabel
                    label="Recommended Wiper Blade Size"
                    info={yearInput?.stockWiperBladeSize?.name || "N/A"}
                  />

                  <div className={classes.footer}>
                    <Button color="primary" onClick={onClose}>
                      Close
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </CustomModal>
      )}
    </React.Fragment>
  );
}

BatterySizeRecommendationModal.defaultProps = {
  onClose: () => {},
  isOpen: false,
};

BatterySizeRecommendationModal.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  customer: PropTypes.object,
};
