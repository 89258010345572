import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModal/CustomModal";
import FormField from "components/Forms/FormField/FormField";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import api from "services/api";
import styles from "./AssignOrderModal.styles";
import useAssignOrderModal from "./AssignOrderModal.hook";
import InfoWithLabel from "components/InfoWithLabel/InfoWithLabel";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function AssignOrderModal(props) {
  const { isOpen, onClose, orderId, successCallback, order } = props;

  const classes = useStyles();

  const { i18n } = useTranslation();

  const {
    isLoading,
    submitHandler,
    form,
    onError,
    handleSubmit,
    plannedDeliveryCity,
    // AvailableTruckStocks,
  } = useAssignOrderModal({
    onClose,
    orderId,
    successCallback,
    order,
  });

  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ margin: 0 }}>
            <CardBody>
              <div className={classes.formHeader}>
                <React.Fragment>
                  <EditIcon className={classes.icon} /> Assign Order
                </React.Fragment>
              </div>
              <form
                style={{ padding: 20, paddingBottom: 0 }}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(submitHandler, onError)();
                }}
                autoComplete="off"
              >
                <FormField
                  md={12}
                  name="assignedDriver"
                  type="autocomplete"
                  label="Assigned Driver"
                  form={form}
                  options={{
                    getData: api.drivers.getAll,
                    getOptionLabel: (option) => option?.name,
                    loadOnFocus: true,
                  }}
                />
                <InfoWithLabel
                  md={5}
                  sm={5}
                  label="Default truck for selected driver"
                  info={
                    form.watch("assignedDriver")?.defaultTruck?.truckNumber ||
                    "N/A"
                  }
                />
                <FormField
                  md={12}
                  name="assignedTruck"
                  type="autocomplete"
                  label="Assigned Truck"
                  form={form}
                  options={{
                    getData: () =>
                      api.orders.checkTruckStockAvailability(orderId),
                    getOptionLabel: (option) =>
                      option?.truckNumber +
                      (option?.available
                        ? "                      (Available)"
                        : "                      (No Sufficient Stock) "),
                    loadOnFocus: true,
                  }}
                />
                <FormField
                  name="zone"
                  type="autocomplete"
                  label="Zone Delivery"
                  form={form}
                  options={{
                    getData: api.zones.getAll,
                    getOptionLabel: (option) =>
                      option.name + " - " + option.fees,
                    loadOnFocus: true,
                  }}
                />
                <FormField
                  name="plannedDeliveryStreet"
                  type="text"
                  label="Planned Delivery Street"
                  form={form}
                />
                <InfoWithLabel
                  md={5}
                  sm={5}
                  label="Default City"
                  info={order?.car?.owner?.defaultDistrict?.city?.name ?? "N/A"}
                />
                <FormField
                  name="plannedDeliveryCity"
                  type="autocomplete"
                  label="Planned Delivery City"
                  form={form}
                  options={{
                    getData: api.cities.getAll,
                    getOptionLabel: (option) => option.name,
                    getSubmitValue: (option) => option.id,
                    loadOnFocus: true,
                    blurOnSelect: false,
                  }}
                />
                <InfoWithLabel
                  md={5}
                  sm={5}
                  label="Default District"
                  info={order?.car?.owner?.defaultDistrict?.name ?? "N/A"}
                />
                <FormField
                  name="plannedDeliveryDistrict"
                  type="autocomplete"
                  label="Planned Delivery District"
                  form={form}
                  options={{
                    getData: api.districts.getAll,
                    getOptionLabel: (option) => option.name,
                    getSubmitValue: (option) => option.id,
                    filters: { cityId: plannedDeliveryCity?.id },
                    loadOnFocus: true,
                    blurOnSelect: false,
                    disabled: !plannedDeliveryCity,
                    disabledTooltipText: "Please select a city first",
                  }}
                />
                <FormField
                  name="plannedDeliveryCoordinates"
                  type="text"
                  label="Planned Delivery Coordinates (lat,lng)"
                  form={form}
                />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    color="primary"
                    type="submit"
                    loading={isLoading}
                    style={{ margin: "20px 0" }}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </CustomModal>
  );
}

AssignOrderModal.defaultProps = {
  onClose: () => {},
};

AssignOrderModal.propTypes = {
  onClose: PropTypes.func,
};
