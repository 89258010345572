import { makeStyles } from "@material-ui/core/styles";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import enums from "enums/index";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import styles from "./DriverShiftButtons.styles";
import React from "react";
import Button from "components/CustomButtons/Button";
import CompleteDriverShiftModal from "views/DriverShiftManagement/CompleteDriverShiftModal/CompleteDriverShiftModal";

const useStyles = makeStyles(styles);

export default function DriverShiftButtons(props) {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const [isCompleteShiftButton, setIsCompleteShiftButton] = useState(false);

  const {
    driverShiftId,
    driverShiftStatus,
    successCallback,
    driverShift,
  } = props;

  const [updateStatusRequest, isUpdateStatusLoading] = useHttpClient(true);

  const updateDriverShiftStatus = useCallback(
    async (driverShiftId, successCallback) => {
      let response;

      if (driverShiftStatus === enums.DriverShiftStatus.ACTIVE)
        response = await updateStatusRequest(
          api.driverShifts.complete(driverShiftId)
        );
      successCallback(response);
      toast.success("Driver Shift updated successfully");
    },
    [successCallback]
  );

  const getDriverShiftButtons = useCallback(
    (orderId, status) => {
      return status === enums.DriverShiftStatus.ACTIVE ? (
        <>
          <>
            <Button
              onClick={() => {
                setIsCompleteShiftButton(true);
              }}
              className={classes.statusButton}
              color="primary"
              textColor="white"
            >
              Complete
            </Button>
          </>
        </>
      ) : status === enums.DriverShiftStatus.COMPLETED ? (
        <>
          <div className={classes.statusButton} />;
        </>
      ) : null;
    },
    [driverShift]
  );

  return (
    <React.Fragment>
      {getDriverShiftButtons(driverShiftId, driverShiftStatus)}
      {isCompleteShiftButton && (
        <CompleteDriverShiftModal
          isOpen={isCompleteShiftButton}
          onClose={() => setIsCompleteShiftButton(false)}
          key={isCompleteShiftButton}
          updateDriverShiftStatus={updateDriverShiftStatus}
          successCallback={successCallback}
          driverShift={driverShift}
        />
      )}
    </React.Fragment>
  );
}

DriverShiftButtons.propTypes = {
  driverShiftId: PropTypes.string,
  driverShiftStatus: PropTypes.string,
};
