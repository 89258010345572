import { makeStyles } from "@material-ui/core/styles";
import CustomTable from "components/CustomTable/CustomTable";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useStockTransfersSheetsList from "./StockTransfersSheetsList.hook";
import styles from "./StockTransfersSheetsList.styles";
import FileCard from "components/InfoWithLabel/FileCard/FileCard";
import { displayFileName, getDateString } from "utils/index";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function StockTransfersSheetsList(props) {
  const classes = useStyles();

  const { i18n } = useTranslation();

  const { resource } = props;

  const {
    currentPage,
    listData,
    searchQuery,
    filtersState,
    itemsPerPage,
    highlightedText,
    isGetLoading,
    onSearchChange,
    onFilterChange,
    handleChange,
    handleSubmit,
  } = useStockTransfersSheetsList({
    resource,
  });

  const columns = React.useMemo(
    () => [
      {
        title: "Stock Transfer Sheet Date",
        dataIndex: "createdAt",
        getData: (data) => (data ? getDateString(data, "en-EG", true) : "-"),
        width: 100,
      },
      {
        render: (_, record) => {
          return (
            <div>
              <FileCard
                name={displayFileName(record?.stockTransfersSheetUrl)}
                url={record?.stockTransfersSheetUrl}
              />
            </div>
          );
        },
        width: 200,
      },
    ],
    [listData]
  );

  return (
    <div>
      <div className={classes.topContainer}>
        <div className={classes.buttonsContainer}></div>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTable
            columns={columns}
            data={listData.rows}
            onChange={handleChange}
            pagination={{
              total: listData.count,
              current: currentPage,
              pageSize: itemsPerPage,
              position: ["topRight"],
              pageSizeOptions: [10, 20, 50],
            }}
            loading={isGetLoading}
            highlightedText={highlightedText}
            handleSubmit={handleSubmit}
            title="Stock Transfers Sheets Summary"
            addIndexColumn
            size="middle"
            fixIndexColumn
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

StockTransfersSheetsList.propTypes = {
  resource: PropTypes.string,
};
