import React from "react";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import WarrantiesSheetsList from "views/StockManagement/WarrantiesSheetsManagement/pages/WarrantiesSheetsList/WarrantiesSheetsList";
import enums from "enums/index";

// appear in breadcrumbs and page headers
const listPageName = "Warranties Sheets";

const layout = "/admin";

const path = "warranty-sheets";

const resource = [
  enums.Resource.STOCK_MANAGEMENT,
  enums.Resource.STOCK_MANAGEMENT_WARRANTIES_SHEETS,
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: FileCopyIcon,
  render: () => <WarrantiesSheetsList />,
  action: "read",
  resource,
  layout: layout,
  randomKey: true,
};
