import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CheckPermission from "components/CheckPermission/CheckPermission";
import Button from "components/CustomButtons/Button.js";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import CustomTable from "components/CustomTable/CustomTable";
import enums from "enums/index";
import React from "react";
import styles from "./ScrapItems.styles";
import { useTranslation } from "react-i18next";
import useScrapItems from "./ScrapItems.hook";
import ScrapItemModal from "./components/ScrapItemModal/ScrapItemModal";
import { isOrderEditable } from "utils/index";
import { AuthContext } from "shared/context/auth-context";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function ScrapItems() {
  const classes = useStyles();

  const {
    order,
    isAssignModalOpen,
    isGetOrderLoading,
    editedItem,
    scrapItems,
    onAssignModalClose,
    handleAssignItem,
    handleRemoveItem,
    onRemoveModalClose,
  } = useScrapItems();

  const { i18n } = useTranslation();
  const { userData } = React.useContext(AuthContext);

  const columns = React.useMemo(
    () => [
      {
        title: "Battery Size",
        dataIndex: ["product", "size", "name"],
      },
      {
        title: "Unit Price",
        dataIndex: "unitPrice",
      },
      {
        title: "Amount",
        dataIndex: "amount",
      },
      {
        title: "Total Price",
        dataIndex: "totalPrice",
      },
      {
        title: "",
        render: (_, record) => {
          return (
            <div className={classes.actionsContainer}>
              {isOrderEditable(order, userData) && (
                <CheckPermission action={enums.Action.UPDATE}>
                  <Button
                    justIcon
                    edit
                    color="white"
                    textColor="success"
                    onClick={() => handleAssignItem(record)}
                  />
                </CheckPermission>
              )}
              {isOrderEditable(order, userData) && (
                <CheckPermission action={enums.Action.DELETE}>
                  <ConfirmationButton
                    name="scrap item"
                    entityName="Scrap item"
                    onConfirm={() => handleRemoveItem(record)}
                    onSuccess={() => onRemoveModalClose(record)}
                    buttonProps={{
                      justIcon: true,
                    }}
                  />
                </CheckPermission>
              )}
            </div>
          );
        },
      },
    ],
    [order]
  );

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        {isAssignModalOpen && (
          <ScrapItemModal
            isOpen={isAssignModalOpen}
            onClose={onAssignModalClose}
            create={!editedItem}
            edit={!!editedItem}
            item={editedItem}
          />
        )}
        <React.Fragment>
          <div className={classes.headerContainer}>
            <h3 className={classes.header}>Scrap Items</h3>
            {isOrderEditable(order, userData) && (
              <CheckPermission action={enums.Action.CREATE}>
                <Button
                  onClick={() => handleAssignItem()}
                  className={classes.addButton}
                  color="success"
                >
                  <AddIcon className={classes.addIcon} />
                  Add Scrap Item
                </Button>
              </CheckPermission>
            )}
          </div>
          <div className={classes.tableContainer}>
            <CustomTable
              columns={columns}
              data={scrapItems}
              loading={isGetOrderLoading}
              addIndexColumn
            />
          </div>
        </React.Fragment>
      </div>
    </div>
  );
}
