import { primaryColor } from "assets/jss/material-dashboard-pro-react";

const styles = (theme) => ({
  section: {
    width: "100%",
    marginBottom: 80,
  },
  sectionTitle: {
    fontSize: 23,
    fontWeight: 500,
    color: primaryColor[0],
  },
  formHeader: {
    display: "flex",
    alignItems: "center",
    fontSize: 20,
    fontWeight: 400,
    marginBottom: 10,
    padding: "20px 30px",
    borderBottom: "1px solid #ddd",
    margin: "0px",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  formRoot: {
    padding: "30px 0px",
  },
  modalHeader: {
    width: "100%",
    position: "absolute",
  },
  modalBody: {
    padding: 0,
    maxWidth: 800,
    maxHeight: "90vh",
  },
  icon: {
    marginRight: 15,
    fontSize: 26,
    color: "#6f7f88",
  },
  questionCard: {
    color: "black",
    borderColor: "black",
  },
});

export default styles;
