import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Card from "components/Card/Card.js";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModal/CustomModal";
import FormField from "components/Forms/FormField/FormField";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import api from "services/api";
import styles from "./ApproveOrderTransferModal.styles";
import useApproveOrderTransfersModal from "./ApproveOrderTransferModal.hook";
import translations from "constants/translations/index";
import enums from "enums/index";
import OrderTransfers from "./components/OrderTransfers/OrderTransfers";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function ApproveOrderTransfersModal(props) {
  const { isOpen, onClose, orderId, successCallback, orderStatus } = props;

  const classes = useStyles();

  const { i18n } = useTranslation();

  const {
    isLoading,
    submitHandler,
    form,
    onError,
    handleSubmit,
    orderTransfers,
    orderWarranties,
    submitRejectHandler,
    isApproveLoading,
    isRejectLoading,
  } = useApproveOrderTransfersModal({
    onClose,
    orderId,
    successCallback,
  });
  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ margin: 0 }}>
            <CardBody>
              <div className={classes.formHeader}>
                <React.Fragment>
                  {orderStatus === enums.OrderStatus.PENDING_STOCK_APPROVAL ? (
                    <CheckBoxIcon className={classes.icon} />
                  ) : (
                    <VisibilityIcon className={classes.icon} />
                  )}
                  {orderStatus === enums.OrderStatus.PENDING_STOCK_APPROVAL
                    ? "Approve"
                    : "View"}{" "}
                  Order Transfers
                </React.Fragment>
              </div>

              <OrderTransfers
                form={form}
                orderTransfers={orderTransfers}
                isLoading={isLoading}
                orderWarranties={orderWarranties}
              />
              {orderStatus === enums.OrderStatus.PENDING_STOCK_APPROVAL && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 10,
                  }}
                >
                  <Button
                    color="primary"
                    onClick={submitHandler}
                    loading={isApproveLoading}
                    style={{ margin: "10px" }}
                  >
                    Approve
                  </Button>
                  <Button
                    color="danger"
                    onClick={submitRejectHandler}
                    loading={isRejectLoading}
                    style={{ margin: "10px" }}
                  >
                    Deny
                  </Button>
                </div>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </CustomModal>
  );
}

ApproveOrderTransfersModal.defaultProps = {
  onClose: () => {},
};

ApproveOrderTransfersModal.propTypes = {
  onClose: PropTypes.func,
};
