import * as yup from "yup";

export default [
  {
    column: 1,
    fields: [
      {
        name: "name",
        type: "text",
        label: "Size",
        validation: yup.mixed().required("Required"),
      },
      {
        name: "type",
        type: "select",
        label: "Type",
        options: {
          enum: "sizeType",
        },
        validation: yup.mixed().required("Required"),
      },
    ],
  },
];
