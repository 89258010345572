import React from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";

//-------------------------------------------------------------------------------------

export const useDriverShiftView = ({ path }) => {
  const [sendRequest, isGetShiftLoading] = useHttpClient();

  const [driverShift, setDriverShift] = React.useState(null);

  const [currentTab, setCurrentTab] = React.useState(0);

  const [isOpen, setIsOpen] = React.useState(false);

  const history = useHistory();

  const dataResource = React.useMemo(() => api.driverShifts, []);

  const onClose = () => {
    setIsOpen(false);
  };

  const getLatestShift = React.useCallback(async () => {
    const id = window.location.pathname.split("/").pop().trim();
    if (id && !isNaN(id)) {
      try {
        const response = await sendRequest(dataResource.getOne(id));
        setDriverShift({ quotations: [], ...response });
      } catch (err) {
        toast.error("An error has occurred");
        history.push({
          pathname: `/admin/${path}`,
        });
      }
    } else {
      toast.error("An error has occurred");
      history.push({
        pathname: `/admin/${path}`,
      });
    }
  }, [dataResource]);

  React.useEffect(() => {
    getLatestShift();
  }, [getLatestShift]);

  const updateShiftState = React.useCallback(
    (updatedShift) => {
      setDriverShift({
        ...driverShift,
        ...updatedShift,
      });
    },
    [driverShift]
  );

  return {
    isGetShiftLoading,
    driverShift,
    currentTab,
    isOpen,
    setDriverShift,
    setCurrentTab,
    getLatestShift,
    onClose,
    updateShiftState,
  };
};
