import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import CustomTable from "components/CustomTable/CustomTable";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TooltipWithIcon from "components/TooltipWithIcon/TooltipWithIcon";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useViewingTruckInventoryAndAddStock from "./ViewingTruckInventoryAndAddingStock.hook";
import styles from "./ViewingTruckInventoryAndAddingStock.styles";
import AddStockTransferModal from "./components/AddStockTransferModal/AddStockTransferModal";
import StockTransfers from "./components/StockTransfers/StockTransfers";
import CachedIcon from "@material-ui/icons/Cached";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function ViewingTruckInventoryAndAddingStock(props) {
  const classes = useStyles();

  const { i18n } = useTranslation();
  const [
    isAddStockTransferModalOpen,
    setIsAddStockTransferModalOpen,
  ] = useState(false);

  const { resource, selectedTruck, driverShift } = props;

  const {
    currentPage,
    listData,
    itemsPerPage,
    highlightedText,
    isGetLoading,
    handleChange,
    handleSubmit,
    setShouldGetTransfers,
    shouldGetTransfers,
    getData,
  } = useViewingTruckInventoryAndAddStock({
    resource,
    // filterInputs,
    selectedTruck,
  });

  const columns = React.useMemo(
    () => [
      {
        dataIndex: ["sapCode"],
        title: "SAP Code",
        canBeHighlighted: true,
      },
      {
        dataIndex: ["manufacturerName"],
        title: "Manufacturer",
      },
      {
        dataIndex: ["size"],
        title: "Size",
      },
      { dataIndex: ["stockItemsCount"], title: "All Stock Count" },
      {
        dataIndex: ["stockItemsOnHoldCount"],
        width: 120,
        title: () => {
          return (
            <div className={classes.tableHeaderContainer}>
              <span>Stock on Hold</span>
              <TooltipWithIcon
                title="Total stock on hold by a pending transfer 'FROM' a storage"
                marginLeft={5}
              />
            </div>
          );
        },
      },
      {
        dataIndex: ["availableStock"],
        width: 120,
        title: () => {
          return (
            <div className={classes.tableHeaderContainer}>
              <span>Available stock</span>
              <TooltipWithIcon
                title="All stock - stock on hold"
                marginLeft={5}
              />
            </div>
          );
        },
      },
    ],
    [listData]
  );

  return (
    <div>
      {isAddStockTransferModalOpen && (
        <AddStockTransferModal
          selectedTruck={selectedTruck}
          driverShiftId={driverShift?.id}
          isOpen={isAddStockTransferModalOpen}
          getData={getData}
          onClose={() => {
            setIsAddStockTransferModalOpen(false);
            setShouldGetTransfers(true);
            getData();
          }}
          key={isAddStockTransferModalOpen}
        />
      )}

      <div className={classes.topContainer}>
        <div className={classes.buttonsContainer}>
          <Button
            create
            color="white"
            onClick={() => setIsAddStockTransferModalOpen(true)}
            CachedIcon
          >
            Stock transfer
          </Button>
        </div>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTable
            columns={columns}
            data={listData.rows}
            pagination={{
              total: listData.count,
              current: currentPage,
              pageSize: itemsPerPage,
              position: ["topRight"],
              pageSizeOptions: [10, 20, 50],
            }}
            loading={isGetLoading}
            highlightedText={highlightedText}
            title={`Inventory for ${driverShift?.assignedTruck?.truckNumber} `}
            addIndexColumn
            size="middle"
            fixIndexColumn
            onChange={handleChange}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <StockTransfers
            shouldGetTransfers={shouldGetTransfers}
            setShouldGetTransfers={setShouldGetTransfers}
            selectedTruck={selectedTruck}
            title={`Stock transfers for ${driverShift?.assignedTruck?.truckNumber} `}
            driverShift={driverShift}
          ></StockTransfers>
        </GridItem>
      </GridContainer>
    </div>
  );
}

ViewingTruckInventoryAndAddingStock.propTypes = {
  resource: PropTypes.string,
};
