import enums from "enums/index";
import React from "react";
import api from "services/api";
import { OrderContext } from "views/Orders/pages/OrderView/Order.context";

//-------------------------------------------------------------------------------------

export default function useScrapItems() {
  const [isAssignModalOpen, setIsAssignModalOpen] = React.useState(false);
  const [editedItem, setEditedItem] = React.useState(null);

  const {
    order,
    setOrder,
    isGetOrderLoading,
    getLatestOrder,
  } = React.useContext(OrderContext);

  const scrapItems = React.useMemo(
    () =>
      order.items?.length
        ? order.items.filter((item) => item.type === enums.ProductType.SCRAP)
        : [],
    [order]
  );

  const handleAssignItem = React.useCallback(
    (data) => {
      setEditedItem(data);
      setIsAssignModalOpen(true);
    },
    [setIsAssignModalOpen, setEditedItem]
  );

  const onAssignModalClose = React.useCallback(() => {
    setIsAssignModalOpen(false);
    setEditedItem(null);
  }, [setIsAssignModalOpen, setEditedItem]);

  const handleRemoveItem = React.useCallback(
    async (record) => {
      const response = await api.orderItems.delete(record.id);
    },
    [order]
  );

  const onRemoveModalClose = React.useCallback(
    (record) => {
      const newItems = order.items.filter((item) => record.id !== item.id);
      getLatestOrder();
    },
    [order, getLatestOrder]
  );

  return {
    order,
    isAssignModalOpen,
    isGetOrderLoading,
    editedItem,
    scrapItems,
    onAssignModalClose,
    handleAssignItem,
    handleRemoveItem,
    onRemoveModalClose,
  };
}
