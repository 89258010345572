import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import CancelOrderValidation from "./ApproveOrderTransfer.validation";

//-------------------------------------------------------------------------------------

export default function useApproveOrderTransfersModal({
  orderId,
  onClose,
  successCallback,
}) {
  const [sendRequest, isLoading] = useHttpClient(true);
  const [orderTransfers, setOrderTransfers] = React.useState([]);
  const [orderWarranties, setOrderWarranties] = React.useState([]);
  const [sendApproveRequest, isApproveLoading] = useHttpClient(true);
  const [sendRejectRequest, isRejectLoading] = useHttpClient(true);

  const { i18n } = useTranslation();

  const resolver = useYupValidationResolver(CancelOrderValidation());

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  React.useEffect(() => {
    if (orderId) {
      (async () => {
        try {
          const response = await sendRequest(
            api.orders.getAllOrderStockTransfer(orderId)
          );
          const responseWarranty = await sendRequest(
            api.warranties.getOrderWarranties(orderId)
          );

          setOrderWarranties(responseWarranty);
          setOrderTransfers(response.data);
        } catch (err) {
          toast.error("An error has occurred");
        }
      })();
    }
  }, [orderId]);

  const { handleSubmit, getValues, setValue } = form;
  const submitHandler = async () => {
    try {
      const updatedOrder = await sendApproveRequest(
        api.orders.approveOrder(orderId)
      );
      const response = await sendRequest(
        api.orders.getAllOrderStockTransfer(orderId)
      );

      setOrderTransfers(response.data);

      toast.success(`Order  has been completed successfully`);

      successCallback(updatedOrder);

      onClose();
    } catch {}
  };
  const submitRejectHandler = async () => {
    try {
      const updatedOrder = await sendRejectRequest(
        api.orders.rejectOrder(orderId)
      );
      toast.success(`Order has been rejected successfully`);
      successCallback(updatedOrder);
      const response = await sendRequest(
        api.orders.getAllOrderStockTransfer(orderId)
      );

      setOrderTransfers(response.data);

      onClose();
    } catch {}
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    isLoading,
    i18n,
    form,
    submitHandler,
    onError,
    handleSubmit,
    orderTransfers,
    orderWarranties,
    submitRejectHandler,
    isApproveLoading,
    isRejectLoading,
  };
}
