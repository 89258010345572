import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CheckPermission from "components/CheckPermission/CheckPermission";
import Button from "components/CustomButtons/Button.js";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import CustomTable from "components/CustomTable/CustomTable";
import enums from "enums/index";
import React from "react";
import styles from "./SerializedItems.styles";
import { Controller } from "react-hook-form";
import useSerializedItems from "./SerializedItems.hook";
import SerializedItemModal from "./components/SerializedItemModal";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function SerializedItems({
  form,
  // onAdd,
  // onEdit,
  order,
}) {
  const classes = useStyles();

  const { control, errors } = form;

  const serializedItems = form.watch("serializedItems");

  const {
    isAssignModalOpen,
    editedItem,
    stockItems,
    onAssignModalClose,
    handleAssignItem,
    onRemoveModalClose,
    // onAdd,
    onEdit,
  } = useSerializedItems({ form });

  const columns = [
    {
      title: "SAP Code",
      dataIndex: ["product", "sapCode"],
    },
    {
      title: "Description",
      dataIndex: ["product", "description"],
    },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      render: (_, record) => {
        if (record.id) return record.unitPrice;
        return record.product.unitPrice;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Total Price",
      dataIndex: "totalPrice",
    },
    {
      title: "Serial Numbers",
      dataIndex: "serialNumbers",
      render: (_, record) => {
        if (record.id) return record.serialNumbers?.join(", ");
        return record.serialNumbers;
      },
    },
    {
      title: "",
      render: (_, record) => {
        return (
          <div className={classes.actionsContainer}>
            <CheckPermission action={enums.Action.UPDATE}>
              <Button
                justIcon
                create
                color="white"
                textColor="success"
                onClick={() => handleAssignItem(record)}
              />
            </CheckPermission>
          </div>
        );
      },
    },
  ];

  return (
    <Controller
      render={(props) => (
        <div className={classes.root}>
          <div className={classes.section}>
            {isAssignModalOpen && (
              <SerializedItemModal
                isOpen={isAssignModalOpen}
                onClose={onAssignModalClose}
                create={!editedItem}
                edit={!!editedItem}
                item={editedItem}
                completeOrderForm={form}
                order={order}
                onEdit={onEdit}
              />
            )}

            {serializedItems.length > 0 && (
              <React.Fragment>
                <div className={classes.headerContainer}>
                  <h3 className={classes.header}>
                    Please add Serial numbers for the following items
                  </h3>
                </div>
                <div className={classes.tableContainer}>
                  <CustomTable
                    columns={columns}
                    data={serializedItems}
                    addIndexColumn
                  />
                </div>
              </React.Fragment>
            )}
            <div className={classes.error}>
              {errors?.serializedItems?.message}
            </div>
          </div>
        </div>
      )}
      name="serializedItems"
      control={control}
    />
  );
}
