import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TooltipWithIcon from "components/TooltipWithIcon/TooltipWithIcon";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useReviewingShiftDetailsAndGeneratingReports from "./ReviewingShiftDetailsAndGeneratingReports.hook";
import styles from "./ReviewingShiftDetailsAndGeneratingReports.styles";
import StockTransfers from "../ViewingTruckInventoryAndAddingStock/components/StockTransfers/StockTransfers";
import CachedIcon from "@material-ui/icons/Cached";
import InfoWithLabelGridItem from "components/InfoWithLabel/InfoWithLabelGridItem";
import translations from "constants/translations/index";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function ReviewingShiftDetailsAndGeneratingReports(props) {
  const classes = useStyles();

  const { i18n } = useTranslation();

  const { selectedTruck, driverShift } = props;

  const {
    currentPage,
    listData,
    itemsPerPage,
    highlightedText,
    isGetLoading,
    handleChange,
    handleSubmit,
    getData,
    shouldGetTransfers,
    setShouldGetTransfers,
  } = useReviewingShiftDetailsAndGeneratingReports({
    // filterInputs,
    selectedTruck,
  });

  return (
    <div className={classes.root}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div className={classes.section}>
            <InfoWithLabelGridItem
              label="Driver shift Status"
              info={
                translations[i18n.language].driverShiftStatus[
                  driverShift?.status
                ]
              }
            />
            <InfoWithLabelGridItem
              label="Driver"
              info={driverShift?.assignedDriver?.name}
            />
            <InfoWithLabelGridItem
              label="Truck Number"
              info={driverShift?.assignedTruck?.truckNumber}
            />
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <StockTransfers
            shouldGetTransfers={shouldGetTransfers}
            setShouldGetTransfers={setShouldGetTransfers}
            selectedTruck={selectedTruck}
            driverShift={driverShift}
            title={`Start of shift transfers for ${driverShift?.assignedTruck?.truckNumber} `}
          ></StockTransfers>
        </GridItem>
      </GridContainer>
    </div>
  );
}

ReviewingShiftDetailsAndGeneratingReports.propTypes = {};
