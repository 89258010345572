import enums from "enums/index";
import qs from "qs";
import React from "react";
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";

//------------------------------------------------------------------------------

export default function useStockTransfers(props) {
  const { resource, selectedTruck, driverShift } = props;
  const history = useHistory();
  const match = useRouteMatch();

  const [
    currentStartOfShiftTransfersPage,
    setCurrentStartOfShiftTransfersPage,
  ] = React.useState(1);
  const [
    startOfShiftTransfersList,
    setStartOfShiftTransfersList,
  ] = React.useState({ count: 0, rows: [] });

  const [
    currentEndOfShiftTransfersPage,
    setCurrentEndOfShiftTransfersPage,
  ] = React.useState(1);
  const [endOfShiftTransfersList, setEndOfShiftTransfersList] = React.useState({
    count: 0,
    rows: [],
  });

  const [itemsPerPage, setItemsPerPage] = React.useState(10);
  const [highlightedText, setHighlightedText] = React.useState("");
  const [
    sendGetStartOfShiftTransfersRequests,
    isGetStartOfShiftTransfersLoading,
  ] = useHttpClient();
  const [
    sendGetEndOfShiftTransfersRequest,
    isGetEndOfShiftTransfersLoading,
  ] = useHttpClient();

  const getStartOfShiftTransfers = React.useCallback(
    async (
      page = currentEndOfShiftTransfersPage,
      rowsPerPage = itemsPerPage
    ) => {
      try {
        const response = await sendGetStartOfShiftTransfersRequests(
          api.driverShifts.getDriverShiftsTransfers(
            page,
            rowsPerPage,
            driverShift?.id,
            enums.DriverShiftStockTransferType.START_OF_SHIFT
          )
        );
        setStartOfShiftTransfersList({
          count: response.count,
          rows: response.data,
        });
      } catch (err) {
        toast.error("An error has occurred");
      }
    },
    [
      sendGetStartOfShiftTransfersRequests,
      setStartOfShiftTransfersList,
      currentStartOfShiftTransfersPage,
      itemsPerPage,
      selectedTruck,
    ]
  );

  React.useEffect(() => {
    (async () => {
      try {
        const settings = JSON.parse(localStorage.getItem("settings"));
        const rowsPerPage =
          settings && settings[match.path] && settings[match.path].rowsPerPage
            ? settings[match.path].rowsPerPage
            : 10;

        setItemsPerPage(rowsPerPage);

        let { page, search, filters } = qs.parse(
          window.location.search?.split("?")[1]
        );
        setHighlightedText(search ? search : "");

        setCurrentStartOfShiftTransfersPage(1);
        await getStartOfShiftTransfers(1, rowsPerPage, search);
      } catch (err) {
        toast.error("An error has occurred");
      }
    })();
  }, []);
  const handleChangeStartOfShiftTransfers = async (pagination) => {
    setCurrentStartOfShiftTransfersPage(pagination.current);
    setItemsPerPage(pagination.pageSize);

    await getStartOfShiftTransfers(
      pagination.current,
      pagination.pageSize,
      undefined,
      undefined
    );
  };

  const getEndOfShiftStockTransfers = React.useCallback(
    async (
      page = currentEndOfShiftTransfersPage,
      rowsPerPage = itemsPerPage
    ) => {
      try {
        const response = await sendGetEndOfShiftTransfersRequest(
          api.driverShifts.getDriverShiftsTransfers(
            page,
            rowsPerPage,
            driverShift?.id,
            enums.DriverShiftStockTransferType.END_OF_SHIFT
          )
        );
        setEndOfShiftTransfersList({
          count: response.count,
          rows: response.data,
        });
      } catch (err) {
        toast.error("An error has occurred");
      }
    },
    [
      sendGetEndOfShiftTransfersRequest,
      setEndOfShiftTransfersList,
      currentEndOfShiftTransfersPage,
      itemsPerPage,
      selectedTruck,
    ]
  );

  React.useEffect(() => {
    (async () => {
      try {
        const settings = JSON.parse(localStorage.getItem("settings"));
        const rowsPerPage =
          settings && settings[match.path] && settings[match.path].rowsPerPage
            ? settings[match.path].rowsPerPage
            : 10;

        setItemsPerPage(rowsPerPage);

        let { page, search, filters } = qs.parse(
          window.location.search?.split("?")[1]
        );
        setHighlightedText(search ? search : "");

        setCurrentEndOfShiftTransfersPage(1);
        await getEndOfShiftStockTransfers(1, rowsPerPage, search);
      } catch (err) {
        toast.error("An error has occurred");
      }
    })();
  }, []);
  const handleChangeEndOfShiftTransfers = async (pagination) => {
    setCurrentEndOfShiftTransfersPage(pagination.current);
    setItemsPerPage(pagination.pageSize);

    await getEndOfShiftStockTransfers(
      pagination.current,
      pagination.pageSize,
      undefined,
      undefined
    );
  };

  return {
    currentEndOfShiftTransfersPage,
    currentStartOfShiftTransfersPage,
    startOfShiftTransfersList,
    endOfShiftTransfersList,
    setStartOfShiftTransfersList,
    setEndOfShiftTransfersList,
    itemsPerPage,
    highlightedText,
    isGetStartOfShiftTransfersLoading,
    isGetEndOfShiftTransfersLoading,
    getEndOfShiftStockTransfers,
    getStartOfShiftTransfers,
    handleChangeEndOfShiftTransfers,
    handleChangeStartOfShiftTransfers,
  };
}
