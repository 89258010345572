import { makeStyles } from "@material-ui/core/styles";
import CustomTable from "components/CustomTable/CustomTable";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import translations from "constants/translations/index";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ReportsAndInvoices.styles";
import CheckPermission from "components/CheckPermission/CheckPermission";
import useReportsAndInvoices from "./ReportsAndInvoices.hook";
import {
  displayFileName,
  displayTransferParty,
  getDateString,
} from "utils/index";
import Button from "components/CustomButtons/Button";
import FileCard from "components/InfoWithLabel/FileCard/FileCard";
import enums from "enums/index";
import { Tag } from "antd";
import InvoicesStatusButtons from "./components/InvoicesStatusButtons/InvoicesStatusButtons";
import { useHttpClient } from "shared/hooks/http-hook";
import api from "services/api";
import StartOfShiftReportButtons from "./components/StartOfShiftReportButtons/StartOfShiftReportButtons";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function ReportsAndInvoices(props) {
  const classes = useStyles();

  const { i18n } = useTranslation();

  const { resource, title, driverShift } = props;

  const [sendRequest, isLoading] = useHttpClient(true);

  const {
    reportsList,

    isGetReportsRequestLoading,
    getReports,
    handleChangeReports,
    updateInvoiceState,

    listData,
  } = useReportsAndInvoices({
    resource,
    driverShift,
  });

  const columns = React.useMemo(
    () => [
      {
        title: "Report Type",
        dataIndex: "type",
        getData: (data) => {
          if (data) return translations["en"].driverShiftReportType[data];
          else return "-";
        },
        width: 100,
      },
      {
        render: (_, record) => {
          return record?.type === enums.DriverShiftReportType.INVOICE ? (
            record?.invoiceUrl ? (
              <div>
                <FileCard
                  name={`${getDateString(
                    record.createdAt,
                    "en-EG",
                    true
                  )}.xlsx`}
                  url={record?.invoiceUrl}
                />
              </div>
            ) : (
              <div>
                <Tag color="red">No completed orders yet in this shift</Tag>
              </div>
            )
          ) : record?.url ? (
            <div>
              <FileCard name={displayFileName(record?.url)} url={record?.url} />
            </div>
          ) : (
            <div>
              <Tag color="red">Report not yet generated</Tag>
            </div>
          );
        },
        title: "Reports File",
        width: 200,
      },
      {
        title: "",

        render: (_, record) => {
          if (
            record?.type === enums.DriverShiftReportType.INVOICE &&
            record?.id
          ) {
            return (
              <div style={{ display: "flex", width: "fit-content" }}>
                <InvoicesStatusButtons
                  invoiceId={record?.id}
                  isUploaded={record?.isUploaded}
                  successCallback={updateInvoiceState}
                />
              </div>
            );
          } else if (
            record?.type === enums.DriverShiftReportType.START_OF_SHIFT &&
            driverShift?.status === enums.DriverShiftStatus.ACTIVE
          ) {
            return (
              <div style={{ display: "flex", width: "fit-content" }}>
                <StartOfShiftReportButtons
                  driverShiftId={driverShift?.id}
                  successCallback={getReports}
                />
              </div>
            );
          }
        },
        width: 270,
      },
    ],
    [reportsList]
  );

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <React.Fragment>
          <div className={classes.headerContainer}>
            <h3 className={classes.header}>Reports and invoices</h3>
          </div>

          <div className={classes.tableContainer}>
            <CustomTable
              columns={columns}
              data={reportsList.rows}
              loading={isGetReportsRequestLoading}
              addIndexColumn
              onChange={handleChangeReports}
            />
          </div>
        </React.Fragment>
      </div>
    </div>
  );
}

ReportsAndInvoices.propTypes = {
  resource: PropTypes.string,
};
