import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "components/CustomButtons/Button.js";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useDriverDetailsForm } from "./DriverShiftDetailsForm.hook";
import AssigningDriverToShift from "./components/AssigningDriverToShift/AssigningDriverToShift";
import ViewingTruckInventoryAndAddingStock from "./components/ViewingTruckInventoryAndAddingStock/ViewingTruckInventoryAndAddingStock";
import ReviewingShiftDetailsAndGeneratingReports from "./components/ReviewingShiftDetailsAndGeneratingReports/ReviewingShiftDetailsAndGeneratingReports";
import styles from "./DriverShiftDetailsForm.styles";
import { useHistory } from "react-router";
import api from "services/api";

const useStyles = makeStyles(styles);

export default function DriverShiftDetailsForm(props) {
  const { path } = props;
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = [
    "Select Driver And Truck ",
    "View Truck Inventory",
    "Review Shift Details",
  ];
  const history = useHistory();

  const {
    form,
    submitHandler,
    isLoading,
    onError,
    handleSubmit,
    selectedTruck,
    driverShift,
    isCreateShiftReportLoading,
    createStartOfShiftReport,
  } = useDriverDetailsForm({
    path,
    setActiveStep,
  });

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <AssigningDriverToShift
            form={form}
            submitHandler={submitHandler}
            isLoading={isLoading}
            onError={onError}
            handleSubmit={handleSubmit}
          />
        );
        break;
      case 1:
        return (
          <ViewingTruckInventoryAndAddingStock
            selectedTruck={selectedTruck}
            driverShift={driverShift}
          />
        );
        break;
      case 2:
        return (
          <ReviewingShiftDetailsAndGeneratingReports
            selectedTruck={selectedTruck}
            driverShift={driverShift}
          />
        );
        break;
      default:
        return "Unknown step";
    }
  }

  const handleNext = async () => {
    if (activeStep === 0) {
      handleSubmit(submitHandler, onError)();
    } else if (activeStep === steps.length - 1) {
      await createStartOfShiftReport(driverShift.id);
      history.push(`/admin/driver-shifts/${driverShift.id}?tab=3`);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography>{getStepContent(index)}</Typography>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                    type="submit"
                    loading={isLoading || isCreateShiftReportLoading}
                  >
                    {activeStep === steps.length - 1
                      ? "Finish And Generate Report"
                      : "Next"}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} className={classes.button}>
            Reset
          </Button>
        </Paper>
      )}
    </div>
  );
}
