import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import { deepen, flattenObject } from "utils/index";
import { OrderContext } from "views/Orders/pages/OrderView/Order.context";
import ItemValidation from "./ScrapItem.validation";
import enums from "enums/index";

//-------------------------------------------------------------------------------------

export default function useScrapItemModal({ itemProp, onClose }) {
  const [sendRequest, isLoading] = useHttpClient(true);

  const { order, getLatestOrder } = React.useContext(OrderContext);

  const { i18n } = useTranslation();

  const resolver = useYupValidationResolver(ItemValidation());

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
    defaultValues: {
      amount: 1,
    },
  });

  const { handleSubmit, getValues, setValue } = form;

  React.useEffect(() => {
    if (itemProp) {
      const formData = {
        ...flattenObject(itemProp),
        orderId: order.id,
      };

      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key]);
      });
    } else {
      setValue("orderId", order.id);
    }
  }, [itemProp, order.id]);

  const createHandler = (values) => async () => {
    try {
      await sendRequest(api.orderItems.create(deepen(values)));
      toast.success(`Order scrap item has been added successfully`);

      getLatestOrder();
      onClose();
    } catch {}
  };

  const editHandler = (values) => async () => {
    try {
      await sendRequest(api.orderItems.patch(deepen(values)));
      toast.success(`Order scrap item has been updated successfully`);

      getLatestOrder();
      onClose();
    } catch {}
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let values = getValues();
    values = {
      ...values,
      productId: values.product?.id,
      orderId: order.id,
      unitPrice: values.product?.unitPrice || 0,
      type: enums.ProductType.SCRAP,
    };
    delete values.product;

    handleSubmit(
      !!itemProp ? editHandler(values) : createHandler(values),
      onError
    )();
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    isLoading,
    i18n,
    form,
    submitHandler,
    onError,
  };
}
