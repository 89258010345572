import React from "react";
import { AuthContext } from "shared/context/auth-context";

export default function CheckPermission({
  resources,
  action,
  roles,
  children,
}) {
  const auth = React.useContext(AuthContext);

  const isAccessible = React.useMemo(
    () =>
      resources ? auth.getAccessPermission({ resources, action, roles }) : true,
    [auth.getAccessPermission, resources, action, roles]
  );

  return isAccessible ? <React.Fragment>{children}</React.Fragment> : null;
}
