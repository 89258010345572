import { makeStyles } from "@material-ui/core/styles";
import FormFieldGridItem from "components/Forms/FormField/FormFieldGridItem";
import GridContainer from "components/Grid/GridContainer.js";
import api from "services/api";
import styles from "./AssigningDriverToShift.styles";
import React from "react";

import { useTranslation } from "react-i18next";

import InfoWithLabelGridItem from "components/InfoWithLabel/InfoWithLabelGridItem";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function AssigningDriverToShift(props) {
  const { handleSubmit, form, submitHandler, onError } = props;
  const classes = useStyles();

  const { i18n } = useTranslation();

  return (
    <form
      className={classes.form}
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(submitHandler, onError)();
      }}
    >
      <div className={classes.section}>
        <h3>Matching Info</h3>
        <GridContainer>
          <FormFieldGridItem
            type="autocomplete"
            form={form}
            name="assignedDriver"
            label="Assigned Driver"
            options={{
              getData: api.drivers.getAll,
              getOptionLabel: (option) => option.name,
              loadOnFocus: true,
            }}
          />
          <InfoWithLabelGridItem
            md={5}
            sm={5}
            label="Default truck for selected driver"
            info={
              form.watch("assignedDriver")?.defaultTruck?.truckNumber || "N/A"
            }
          />
          <FormFieldGridItem
            type="autocomplete"
            form={form}
            name="assignedTruck"
            label="Assigned Truck"
            options={{
              getData: api.trucks.getAll,
              getOptionLabel: (option) => option.truckNumber,
              loadOnFocus: true,
            }}
          />
        </GridContainer>
      </div>
    </form>
  );
}
