import DataList from "components/DataList/DataList";
import FormContainer from "components/Forms/FormContainer/FormContainer";
import enums from "enums/index";
import api from "services/api";
import React from "react";
import translations from "constants/translations/index";
import { getDateString } from "utils/index";
import driversForm from "views/TrucksManagement/driversForm";

// appear in breadcrumbs and page headers
const listPageName = "Drivers";
const createPageName = "Create Driver";
const editPageName = "Edit Driver";

// appears on alerts; example: Entity created successfully
const entityName = "Driver";

const layout = "/admin";

const path = "drivers";

const formStructure = driversForm;

const dataResource = api.drivers;

const resource = [
  enums.Resource.STOCK_MANAGEMENT,
  enums.Resource.STOCK_MANAGEMENT_DRIVERS,
];

const columns = [
  {
    dataIndex: "name",
    title: "Name",
    canBeHighlighted: true,
  },
  {
    dataIndex: "phone",
    title: "Phone",
    canBeHighlighted: true,
  },
  {
    dataIndex: "nationalId",
    title: "National ID",
    canBeHighlighted: true,
  },
  {
    dataIndex: "joiningDate",
    title: "Joining Date",
    render: (data) => (data ? getDateString(data) : "-"),
  },
  {
    dataIndex: "leaveDate",
    title: "Leave Date",
    render: (data) => (data ? getDateString(data) : "-"),
  },
  {
    dataIndex: "status",
    title: "Status",
    getData: (data) => {
      if (data) return translations["en"].driverStatus[data];
      else return "-";
    },
  },
  {
    dataIndex: ["defaultTruck", "truckNumber"],
    title: "Default Truck",
  },
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: "man",
  render: () => (
    <DataList
      entityName={entityName}
      path={path}
      columns={columns}
      actions={{ edit: true, delete: true }}
      dataResource={dataResource}
      resource={resource}
      listPageName={listPageName}
      formStructure={formStructure}
      formType={enums.FormContainerType.MODAL}
    />
  ),
  action: "read",
  layout: layout,
  // randomKey: true,
  children: true,
  superAdmin: true,
  resource,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          create
        />
      ),
      layout: layout,
      randomKey: true,
      action: "create",
      superAdmin: true,
      resource,
    },
    {
      path: `/${path}/:id/edit`,
      name: editPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          edit
        />
      ),
      layout: layout,
      randomKey: true,
      action: "update",
      superAdmin: true,
      resource,
    },
  ],
};
