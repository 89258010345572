import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import PropTypes from "prop-types";
import styles from "./TooltipWithIcon.styles";
import React from "react";

const useStyles = makeStyles(styles);

export default function TooltipWithIcon({
  title,
  icon,
  iconClassName,
  marginLeft,
}) {
  const classes = useStyles();

  return (
    <Tooltip title={title} arrow style={{ marginLeft: marginLeft ?? 0 }}>
      {icon ? (
        icon
      ) : (
        <InfoOutlined
          className={iconClassName ? iconClassName : classes.icon}
        />
      )}
    </Tooltip>
  );
}

TooltipWithIcon.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node,
  iconClassName: PropTypes.string,
  marginLeft: PropTypes.number,
};
