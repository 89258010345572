import { makeStyles } from "@material-ui/core/styles";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import enums from "enums/index";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import styles from "./TransferStatusButtons.styles";
import React from "react";
//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function TransferStatusButtons(props) {
  const classes = useStyles();

  const { stockTransferId, status, successCallback } = props;

  const [updateStatusRequest, isUpdateStatusLoading] = useHttpClient(true);

  const approveTransfer = useCallback(async (stockTransferId) => {
    let updatedOrder;
    updatedOrder = await updateStatusRequest(
      api.stockItemsTransfers.approveTransfer(stockTransferId)
    );
    successCallback(updatedOrder);
    toast.success("Transfer updated successfully");
  });
  const rejectTransfer = useCallback(async (stockTransferId) => {
    let updatedOrder;
    updatedOrder = await updateStatusRequest(
      api.stockItemsTransfers.rejectTransfer(stockTransferId)
    );
    successCallback(updatedOrder);
    toast.success("Transfer updated successfully");
  });

  const getOrderStatusButtons = useCallback((stockTransferId, status) => {
    const cancelPlaceHolder = <div className={classes.cancelButton} />;
    const statusPlaceHolder = <div className={classes.statusButton} />;
    const cancelButton = (
      <>
        <ConfirmationButton
          key={Math.random()}
          confirmMessage="Are you sure you want to cancel this transfer ?"
          successHeaderText="Submitted"
          successMessage="Transfer has been Cancelled successfully."
          headerMessage="Cancel"
          confirmButtonText="Yes"
          confirmButtonColor="success"
          onConfirm={() => rejectTransfer(stockTransferId)}
          loading={isUpdateStatusLoading}
          buttonProps={{
            className: classes.statusButton,
            color: "danger",
            textColor: "white",
          }}
        >
          Cancel
        </ConfirmationButton>
      </>
    );
    const approveButton = (
      <>
        <ConfirmationButton
          key={Math.random()}
          confirmMessage="Are you sure you want to approve this transfer ?"
          successHeaderText="Submitted"
          successMessage="Transfer has been approved successfully."
          headerMessage="Approve"
          confirmButtonText="Yes"
          confirmButtonColor="success"
          onConfirm={() => approveTransfer(stockTransferId)}
          loading={isUpdateStatusLoading}
          buttonProps={{
            className: classes.statusButton,
            color: "success",
            textColor: "white",
          }}
        >
          Approve
        </ConfirmationButton>
      </>
    );
    return status === enums.StockItemTransferStatus.PENDING ? (
      <>
        {approveButton}
        {cancelButton}
      </>
    ) : (
      <>
        {statusPlaceHolder}
        {cancelPlaceHolder}
      </>
    );
  }, []);

  return (
    <React.Fragment>
      {getOrderStatusButtons(stockTransferId, status)}
    </React.Fragment>
  );
}

TransferStatusButtons.propTypes = {
  status: PropTypes.string,
  // successCallback: PropTypes.func,
};
