import enums from "enums/index";
import batterySizeRecommendationRoutes from "./batterySizeRecommendationRoutes";
import ordersRoutes from "./ordersRoutes";
import AssignmentIcon from "@material-ui/icons/Assignment";

export default {
  name: "Order Management",
  icon: AssignmentIcon,
  layout: "/admin",
  collapse: true,
  state: "ordersCollapse",
  resource: enums.Resource.ORDERS_MANAGEMENT,
  views: [ordersRoutes, batterySizeRecommendationRoutes],
};
