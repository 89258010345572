import DataList from "components/DataList/DataList";
import FormContainer from "components/Forms/FormContainer/FormContainer";
import enums from "enums/index";
import api from "services/api";
import React from "react";
import translations from "constants/translations/index";
import { getDateString } from "utils/index";
import TrucksList from "views/TrucksManagement/Trucks/TrucksList/TrucksList";

// appear in breadcrumbs and page headers
const listPageName = "Trucks";
const createPageName = "Create Truck";
const editPageName = "Edit Truck";

// appears on alerts; example: Entity created successfully
const entityName = "Truck";

const layout = "/admin";

const path = "trucks";

const dataResource = api.trucks;

const resource = [
  enums.Resource.STOCK_MANAGEMENT,
  enums.Resource.STOCK_MANAGEMENT_TRUCKS,
];

// appear in breadcrumbs and page headers

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: "drive_eta",
  render: () => <TrucksList />,
  action: "read",
  layout: layout,
  superAdmin: true,
  resource,
};
