import enums from "enums/index";
import React from "react";
import WarrantiesList from "views/StockManagement/WarrantiesManagement/WarrantiesList";

// appear in breadcrumbs and page headers
const listPageName = "Warranties";

const layout = "/admin";

const path = "warranties";

const resource = [
  enums.Resource.STOCK_MANAGEMENT,
  enums.Resource.STOCK_MANAGEMENT_WARRANTIES,
];
export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: "upload_file",
  render: () => <WarrantiesList />,
  action: "read",
  resource,
  layout: layout,
};
