import api from "services/api";
import React from "react";
import InvoicesList from "views/StockManagement/InvoicesManagement/pages/InvoicesList/InvoicesList";
import enums from "enums/index";

// appear in breadcrumbs and page headers
const listPageName = "Invoices";

// appears on alerts; example: Entity created successfully
const entityName = "invoice";

const layout = "/admin";

const path = "invoices";

const dataResource = api.invoices;

const resource = [
  enums.Resource.STOCK_MANAGEMENT,
  enums.Resource.STOCK_MANAGEMENT_INVOICES,
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: "request_quote",
  render: () => <InvoicesList />,
  action: "read",
  resource,
  layout: layout,
  randomKey: true,
};
