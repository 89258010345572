import * as yup from "yup";

//----------------------------------------------------------

export default () => {
  return yup.object().shape({
    product: yup.mixed().required("Required"),
    amount: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .required("Required"),
  });
};
