import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router";
import styles from "./TrucksFormModal.styles";
import CustomModal from "components/CustomModal/CustomModal";
import TrucksForm from "./TrucksForm";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function TrucksFormModal(props) {
  const { path, isOpen, onClose, truck } = props;

  const history = useHistory();
  const classes = useStyles();

  const refresh = () => history.push({ pathname: `/admin/${path}` });

  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <div className={classes.formContainer}>
        <TrucksForm
          {...props}
          refresh={refresh}
          truck={truck}
          handleModalClose={onClose}
        />
      </div>
    </CustomModal>
  );
}

TrucksFormModal.propTypes = {
  edit: PropTypes.bool,
  create: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};
