import enums from "enums/index";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import AddWarrantySheetValidation from "./AddWarrantySheetModal.validation";
import { useHistory } from "react-router";

//-------------------------------------------------------------------------------------

export default function useAddWarrantySheetModal({ onClose, successCallback }) {
  const [sendRequest, isLoading] = useHttpClient(true);

  const { i18n } = useTranslation();

  const dataResource = React.useMemo(() => api.warranties, []);

  const history = useHistory();

  const resolver = useYupValidationResolver(AddWarrantySheetValidation());

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const { handleSubmit, getValues, setValue } = form;
  const submitHandler = async () => {
    // Assuming it's an array of files, and you are interested in the first one.

    try {
      let values = getValues();

      values = {
        ...values,
        warrantySheetUrl: values?.serialNumbersSheet,
        driverId: values.driver?.id,
      };
      const newSerialNumbers = await sendRequest(
        dataResource.createFromSheet(values)
      );
      onClose();

      successCallback(newSerialNumbers);
      toast.success(`Warranties added successfully`);

      // Rest of your code here
    } catch (error) {
      console.error(error);
    }
  };

  // values = {
  //   ...values,
  //   fromId: values.from?.id,
  //   toId: values.to?.id,
  //   productId: values.product?.id,
  //   serialNumbers: serialNumbers,
  // };
  // try {
  //   const newTransfer = await sendRequest(dataResource.create(values));
  //   onClose();

  //   // successCallback(newTransfer);
  //   toast.success(`Transfer Request added successfully`);
  // } catch (err) {
  //   console.log(err);
  // }

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    isLoading,
    i18n,
    form,
    submitHandler,
    onError,
    handleSubmit,
  };
}
