const styles = (theme) => ({
  formHeader: {
    display: "flex",
    alignItems: "center",
    fontSize: 20,
    fontWeight: 400,
    padding: "10px 30px",
    paddingBottom: 20,
    borderBottom: "1px solid #ddd",
    margin: "0px -20px",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  infoContainer: {
    padding: "0px 20px",
    marginBottom: -20,
  },
  icon: {
    marginRight: 15,
    fontSize: 20,
    color: "#6f7f88",
  },
  modalHeader: {
    width: "100%",
    position: "absolute",
  },
  modalBody: {
    padding: 0,
    width: 900,
    maxHeight: "90vh",
  },
  radioRoot: {
    justifyContent: "flex-start",
    marginLeft: 20,
    marginBottom: -20,
  },
  radioLabel: {
    marginRight: 20,
  },
  radioButtonLabel: {
    marginLeft: -10,
    marginRight: 10,
  },
  radioButtonsContainer: {
    flexDirection: "row",
  },
  radioButton: {
    marginRight: 5,
  },
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 20,
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
});

export default styles;
