import { makeStyles } from "@material-ui/core/styles";
import CustomTable from "components/CustomTable/CustomTable";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import translations from "constants/translations/index";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./StockTransfersApprovalList.styles";
import CheckPermission from "components/CheckPermission/CheckPermission";
import TransferStatusButtons from "./components/TransferStatusButtons";
import useStockTransfersApprovalList from "./StockTransfersApprovalList.hook";
import { displayTransferParty } from "utils/index";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function StockTransfersApprovalList(props) {
  const classes = useStyles();

  const { i18n } = useTranslation();

  const { resource } = props;

  const {
    currentPage,
    listData,
    searchQuery,
    filtersState,
    itemsPerPage,
    highlightedText,
    isGetLoading,
    onSearchChange,
    onFilterChange,
    handleChange,
    handleSubmit,
    updateTransferState,
  } = useStockTransfersApprovalList({
    resource,
  });

  const columns = React.useMemo(
    () => [
      {
        dataIndex: ["product", "sapCode"],
        title: "SAP Code",
        canBeHighlighted: true,
      },

      {
        dataIndex: ["product", "description"],
        title: "Item Description",
      },
      {
        title: "From",
        dataIndex: ["from"],
        render: (data) => displayTransferParty(data),
      },

      {
        title: "To",
        dataIndex: ["to"],
        render: (data) => displayTransferParty(data),
      },
      {
        title: "Status",
        dataIndex: ["status"],
        getData: (data) =>
          translations[i18n.language].stockItemTransferStatus[data],
      },

      {
        dataIndex: ["product", "size", "name"],
        title: "Battery Size",
      },
      {
        dataIndex: ["product", "type"],
        title: "Battery Type",
      },
      {
        dataIndex: ["amount"],
        title: "Amount",
      },
      {
        dataIndex: ["apInvoiceNumber"],
        title: "AP Invoice Number",
      },

      {
        title: "",
        render: (_, record) => {
          return (
            <div style={{ display: "flex", width: "fit-content" }}>
              <TransferStatusButtons
                stockTransferId={record?.id}
                status={record?.status}
                successCallback={updateTransferState}
              />
            </div>
          );
        },
        width: 270,
      },
    ],
    [listData]
  );

  return (
    <div>
      <div className={classes.topContainer}>
        <div className={classes.buttonsContainer} />
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTable
            columns={columns}
            data={listData.rows}
            onChange={handleChange}
            pagination={{
              total: listData.count,
              current: currentPage,
              pageSize: itemsPerPage,
              position: ["topRight"],
              pageSizeOptions: [10, 20, 50],
            }}
            loading={isGetLoading}
            highlightedText={highlightedText}
            filterProps={{
              value: filtersState,
              onChange: onFilterChange,
            }}
            searchProps={{
              onChange: onSearchChange,
              value: searchQuery,
              placeholder: "Search",
            }}
            handleSubmit={handleSubmit}
            title="Stock Transfers Requests Summary"
            addIndexColumn
            size="middle"
            fixIndexColumn
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

StockTransfersApprovalList.propTypes = {
  resource: PropTypes.string,
};
