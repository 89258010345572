import enums from "enums/index";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import AddWarrantyValidation from "./AddWarrantyModal.validation";
import { useHistory } from "react-router";

//-------------------------------------------------------------------------------------

export default function useAddWarrantyModal({
  onClose,
  successCallback,
  editedWarranty,
  editingWarrantiesList,
}) {
  const [sendRequest, isLoading] = useHttpClient(true);

  const { i18n } = useTranslation();

  const dataResource = React.useMemo(() => api.warranties, []);

  const history = useHistory();

  const resolver = useYupValidationResolver(AddWarrantyValidation());

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const { handleSubmit, getValues, setValue } = form;

  React.useEffect(() => {
    if (editedWarranty) {
      setValue("serialNumber", editedWarranty.serialNumber);
      setValue("driver", editedWarranty.driver);
    }
  }, [editedWarranty]);

  const submitHandler = async () => {
    let values = getValues();

    values = {
      ...values,
      driverId: values.driver?.id,
    };
    try {
      let newSerialNumber;
      if (editedWarranty) {
        values.id = editedWarranty.id;

        newSerialNumber = await sendRequest(dataResource.patch(values));
        editingWarrantiesList(newSerialNumber);
        toast.success(`Warranty updated successfully`);
      } else {
        newSerialNumber = await sendRequest(dataResource.create(values));
        toast.success(`Warranty added successfully`);
        successCallback(newSerialNumber);
      }
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    isLoading,
    i18n,
    form,
    submitHandler,
    onError,
    handleSubmit,
  };
}
