import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import CarsFormValidation from "./CarsForm.validation";
import { useHistory } from "react-router";

//------------------------------------------------------------------

export const useCarsForm = ({
  edit,
  create,
  typeId,
  handleAddNew,
  handleModalClose,
  car,
  owner,
}) => {
  const [data, setData] = React.useState(null);

  const [sendRequest, isLoading] = useHttpClient();

  const [sendGetRequest, isGetLoading] = useHttpClient();

  const dataResource = React.useMemo(() => api.cars, []);
  const history = useHistory();

  const resolver = useYupValidationResolver(CarsFormValidation());

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { isDirty },
  } = form;
  const batterySizeDefault = watch("year")?.stockBatterySize;

  React.useEffect(() => {
    if (owner) setValue("owner", owner);
  }, [owner]);

  React.useEffect(() => {
    if (batterySizeDefault && create)
      setValue("batterySize", batterySizeDefault);
  }, [batterySizeDefault]);

  React.useEffect(() => {
    if (car) {
      Object.keys(car).forEach((key) => setValue(key, car[key]));
      setValue("year", car.model);
      setValue("maker", car.model?.maker);
      const values = getValues();
    }
  }, [car]);

  const createHandler = (values) => async () => {
    try {
      const response = await sendRequest(dataResource.create(values));
      toast.success(`Car Model has been added successfully`);
      if (handleModalClose) handleModalClose();
      if (handleAddNew) handleAddNew(response);
    } catch (err) {
      console.log(err);
      toast.error("An error has occurred");
    }
  };

  const editHandler = (values) => async () => {
    try {
      const response = await sendRequest(dataResource.patch(values));
      toast.success(`Car has been updated successfully`);
      history.go(0);
      if (handleModalClose) handleModalClose();
    } catch (err) {
      toast.error("An error has occurred");
    }
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let values = getValues();
    values = {
      ...values,
      modelId: values.year?.id,
      ownerId: values.owner?.id,
      batterySizeId: values.batterySize?.id,
      wiperBladeSizeId: values.wiperBladeSize?.id,
    };

    delete values.year;
    delete values.owner;
    delete values.maker;
    delete values.model;
    delete values.batterySize;
    delete values.wiperBladeSize;

    handleSubmit(
      create ? createHandler(values) : editHandler(values),
      onError
    )();
  };

  return {
    isLoading,
    isGetLoading,
    form,
    data,
    watch,
    typeId,
    onSubmit,
  };
};
