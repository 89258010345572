import OrderCancellationReasonsRoutes from "./orderCancellationReasonsRoutes";
import orderIssueTypesRoutes from "./orderIssueTypesRoutes";
import orderLossReasonsRoutes from "./orderLossReasonsRoutes";
import truckTypesRoutes from "./truckTypesRoutes";
import paymentMethodsRoutes from "./paymentMethodsRoutes";
import SettingsIcon from "@material-ui/icons/Settings";
import promoCodesRoutes from "./promoCodesRoutes";
import marketingSpendsRoutes from "./marketingSpendsRoutes";
import marketingSpendChannelsRoutes from "./marketingSpendChannelsRoutes";
import enums from "enums/index";

export default {
  name: "Settings",
  icon: SettingsIcon,
  layout: "/admin",
  collapse: true,
  state: "settingsCollapse",
  resource: enums.Resource.SETTINGS,
  superAdmin: true,
  views: [
    { ...OrderCancellationReasonsRoutes },
    { ...orderIssueTypesRoutes },
    { ...orderLossReasonsRoutes },
    { ...truckTypesRoutes },
    { ...paymentMethodsRoutes },
    { ...promoCodesRoutes },
    { ...marketingSpendsRoutes },
    { ...marketingSpendChannelsRoutes },
  ],
};
