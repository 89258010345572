import {
  defaultFont,
  successColor,
} from "assets/jss/material-dashboard-pro-react.js";

//-------------------------------------------------------------------------------------

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 20,
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
});

export default styles;
