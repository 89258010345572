import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModal/CustomModal";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import api from "services/api";
import usePlanOrderModal from "./PlanOrderModal.hook";
import styles from "./PlanOrderModal.styles";
import ScrapItems from "../../OrderDetailsForm/components/ScrapItems/ScrapItems";
import FormFieldGridItem from "components/Forms/FormField/FormFieldGridItem";
import OrderItems from "../../OrderDetailsForm/components/OrderItems/OrderItems";
import { OrderDetailsFormContext } from "../../OrderDetailsForm/OrderDetailsForm.context";
import InfoWithLabel from "components/InfoWithLabel/InfoWithLabel";
import InfoWithLabelGridItem from "components/InfoWithLabel/InfoWithLabelGridItem";
import OrderServices from "../../OrderDetailsForm/components/OrderServices/OrderServices";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function PlanOrderModal(props) {
  const { isOpen, onClose, orderId, successCallback, order } = props;

  const classes = useStyles();

  const { i18n } = useTranslation();

  const {
    isLoading,
    submitHandler,
    form,
    plannedDeliveryCity,
    onError,
    handleSubmit,
  } = usePlanOrderModal({
    onClose,
    orderId,
    successCallback,
    order,
  });
  const { selectedCar } = React.useContext(OrderDetailsFormContext);

  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ margin: 0 }}>
            <CardBody>
              <div className={classes.formHeader}>
                <React.Fragment>
                  <EditIcon className={classes.icon} /> Plan Order
                </React.Fragment>
              </div>
              <form
                style={{ padding: 20, paddingBottom: 0, marginRight: -60 }}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(submitHandler, onError)();
                }}
                autoComplete="off"
              >
                <GridContainer style={{ marginBottom: 60 }}>
                  <FormFieldGridItem
                    name="deliveryType"
                    type="select"
                    label="Delivery Type"
                    form={form}
                    options={{
                      enum: "deliveryType",
                    }}
                  />
                  <FormFieldGridItem
                    type="autocomplete"
                    form={form}
                    name="paymentMethod"
                    label="Payment Method"
                    options={{
                      getData: api.paymentMethods.getAll,
                      getOptionLabel: (option) => option.name,
                      getSubmitValue: (option) => option?.id,
                      loadOnFocus: true,
                      blurOnSelect: false,
                    }}
                  />
                  <FormFieldGridItem
                    name="zone"
                    type="autocomplete"
                    label="Zone Delivery"
                    form={form}
                    options={{
                      getData: api.zones.getAll,
                      getOptionLabel: (option) =>
                        option.name + " - " + option.fees,
                      loadOnFocus: true,
                    }}
                  />
                  <FormFieldGridItem
                    name="plannedDeliveryTime"
                    type="datetime-local"
                    label="Planned Delivery Date"
                    form={form}
                  />
                  <FormFieldGridItem
                    name="desiredDeliveryTime"
                    type="datetime-local"
                    label="Desired Delivery Date"
                    form={form}
                  />
                  <FormFieldGridItem
                    name="plannedDeliveryStreet"
                    type="text"
                    label="Planned Delivery Street"
                    form={form}
                  />
                  <InfoWithLabelGridItem
                    md={5}
                    sm={5}
                    label="Default City"
                    info={
                      order?.car?.owner?.defaultDistrict?.city?.name ?? "N/A"
                    }
                  />
                  <FormFieldGridItem
                    name="plannedDeliveryCity"
                    type="autocomplete"
                    label="Planned Delivery City"
                    form={form}
                    options={{
                      getData: api.cities.getAll,
                      getOptionLabel: (option) => option.name,
                      getSubmitValue: (option) => option.id,
                      loadOnFocus: true,
                      blurOnSelect: false,
                    }}
                  />
                  <InfoWithLabelGridItem
                    md={5}
                    sm={5}
                    label="Default District"
                    info={order?.car?.owner?.defaultDistrict?.name ?? "N/A"}
                  />
                  <FormFieldGridItem
                    name="plannedDeliveryDistrict"
                    type="autocomplete"
                    label="Planned Delivery District"
                    form={form}
                    options={{
                      getData: api.districts.getAll,
                      getOptionLabel: (option) => option.name,
                      getSubmitValue: (option) => option.id,
                      filters: { cityId: plannedDeliveryCity?.id },
                      loadOnFocus: true,
                      blurOnSelect: false,
                      disabled: !plannedDeliveryCity,
                      disabledTooltipText: "Please select a city first",
                    }}
                  />
                  <FormFieldGridItem
                    name="plannedDeliveryCoordinates"
                    type="text"
                    label="Planned Delivery Coordinates (lat,lng)"
                    form={form}
                  />
                </GridContainer>
                <OrderServices form={form} order={order} />
                <OrderItems form={form} order={order} />
                <ScrapItems form={form} order={order} />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    color="primary"
                    type="submit"
                    loading={isLoading}
                    style={{ margin: "20px 0", marginRight: 70 }}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </CustomModal>
  );
}

PlanOrderModal.defaultProps = {
  onClose: () => {},
};

PlanOrderModal.propTypes = {
  onClose: PropTypes.func,
};
