import enums from "enums/index";
import React, { useEffect, useState } from "react";
import { OrderContext } from "views/Orders/pages/OrderView/Order.context";

//-------------------------------------------------------------------------------------

export default function useOrderServices({ form, orderProp }) {
  const [isAssignModalOpen, setIsAssignModalOpen] = React.useState(false);
  const [editedService, setEditedService] = React.useState(null);

  const { order: orderContext, isGetOrderLoading } = React.useContext(
    OrderContext
  );
  const [orderServices, setOrderServices] = useState([]);

  const order = React.useMemo(() => orderProp || orderContext, [
    orderProp,
    orderContext,
  ]);

  useEffect(() => {
    setOrderServices(order.services?.length ? order.services : []);
  }, [order]);

  const { setValue } = form;

  const handleAssignService = React.useCallback(
    (data) => {
      setEditedService(data);
      setIsAssignModalOpen(true);
    },
    [setIsAssignModalOpen, setEditedService]
  );

  const onAssignModalClose = React.useCallback(() => {
    setIsAssignModalOpen(false);
    setEditedService(null);
  }, [setIsAssignModalOpen, setEditedService]);

  const onRemoveModalClose = React.useCallback(
    (record) => {
      setOrderServices((prevServices) => {
        const newServices = prevServices.filter(
          (service) => service.key !== record.key
        );
        setValue("services", newServices);
        return newServices;
      });
    },
    [orderServices]
  );

  const onAdd = React.useCallback(
    (data) => {
      setOrderServices((prevServices) => {
        const newServices = [...prevServices, { ...data, key: Math.random() }];
        setValue("services", newServices);
        return newServices;
      });
    },
    [orderServices]
  );

  const onEdit = React.useCallback(
    (data) => {
      setOrderServices((prevServices) => {
        const newServices = prevServices.map((service) => {
          if (service.key === data.key) {
            return data;
          }
          return service;
        });
        setValue("services", newServices);
        return newServices;
      });
    },
    [orderServices]
  );

  return {
    order,
    isAssignModalOpen,
    isGetOrderLoading,
    editedService,
    orderServices,
    onAssignModalClose,
    handleAssignService,
    onRemoveModalClose,
    onAdd,
    onEdit,
  };
}
