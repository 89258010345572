import { makeStyles } from "@material-ui/core/styles";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import enums from "enums/index";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import styles from "./InvoicesStatusButtons.styles";
import React from "react";
//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function InvoicesStatusButtons(props) {
  const classes = useStyles();

  const { invoiceId, isUploaded, successCallback } = props;

  const [updateStatusRequest, isUpdateStatusLoading] = useHttpClient(true);

  const uploadInvoice = useCallback(async (invoiceId) => {
    let updatedInvoice;
    updatedInvoice = await updateStatusRequest(
      api.invoices.patch({ id: invoiceId, isUploaded: true })
    );
    successCallback(updatedInvoice);
    toast.success("Invoice Set as Uploaded successfully.");
  });

  const getInvoiceStatusButtons = useCallback((invoiceId, isUploaded) => {
    const cancelPlaceHolder = <div className={classes.cancelButton} />;
    const statusPlaceHolder = <div className={classes.statusButton} />;

    const approveButton = (
      <>
        <ConfirmationButton
          key={Math.random()}
          confirmMessage="Are you sure you want to set this invoice as uploaded ?"
          successHeaderText="Submitted"
          successMessage="Invoice Set as Uploaded successfully."
          headerMessage="Approve"
          confirmButtonText="Yes"
          confirmButtonColor="success"
          onConfirm={() => uploadInvoice(invoiceId)}
          loading={isUpdateStatusLoading}
          buttonProps={{
            className: classes.statusButton,
            color: "success",
            textColor: "white",
          }}
        >
          Set as Uploaded
        </ConfirmationButton>
      </>
    );
    return !isUploaded ? (
      <>
        {approveButton}
        {/* {cancelButton} */}
      </>
    ) : (
      <>
        {statusPlaceHolder}
        {cancelPlaceHolder}
      </>
    );
  }, []);

  return (
    <React.Fragment>
      {getInvoiceStatusButtons(invoiceId, isUploaded)}
    </React.Fragment>
  );
}

InvoicesStatusButtons.propTypes = {
  status: PropTypes.string,
  // successCallback: PropTypes.func,
};
