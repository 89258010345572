import * as yup from "yup";

export default [
  {
    column: 1,
    fields: [
      {
        name: "code",
        type: "text",
        label: "Code",
        validation: yup.mixed().required("Required"),
      },
      //   {
      //     name: "type",
      //     type: "select",
      //     label: "Type",
      //     options: {
      //       enum: "promoCodeType",
      //     },
      //     validation: yup.mixed().required("Required"),
      //   },
      {
        name: "amount",
        type: "number",
        label: "Amount",
        validation: yup.mixed().required("Required"),
      },
    ],
  },
];
