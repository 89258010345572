import enums from "enums/index";
import React from "react";
import api from "services/api";
import { OrderContext } from "views/Orders/pages/OrderView/Order.context";

//-------------------------------------------------------------------------------------

export default function useOrderServices() {
  const [isAssignModalOpen, setIsAssignModalOpen] = React.useState(false);
  const [editedService, setEditedService] = React.useState(null);

  const {
    order,
    setOrder,
    isGetOrderLoading,
    getLatestOrder,
  } = React.useContext(OrderContext);

  const services = React.useMemo(
    () => (order.services?.length ? order.services : []),
    [order]
  );

  const handleAssignService = React.useCallback(
    (data) => {
      setEditedService(data);
      setIsAssignModalOpen(true);
    },
    [setIsAssignModalOpen, setEditedService]
  );

  const onAssignModalClose = React.useCallback(() => {
    setIsAssignModalOpen(false);
    setEditedService(null);
  }, [setIsAssignModalOpen, setEditedService]);

  const handleRemoveService = React.useCallback(
    async (record) => {
      const response = await api.orderServices.delete(record.id);
    },
    [order]
  );

  const onRemoveModalClose = React.useCallback(
    (record) => {
      const newServices = order.services.filter(
        (service) => record.id !== service.id
      );
      getLatestOrder();
    },
    [order, getLatestOrder]
  );

  return {
    order,
    isAssignModalOpen,
    isGetOrderLoading,
    editedService,
    services,
    onAssignModalClose,
    handleAssignService,
    handleRemoveService,
    onRemoveModalClose,
  };
}
