import enums from "enums/index";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { saveAs } from "file-saver";

//-------------------------------------------------------------------------------------

export default function useCompleteDriverShiftModal({
  order,
  driverShift,
  onClose,
  successCallback,
}) {
  const [sendRequest, isLoading] = useHttpClient(true);
  const [checkOnProcessedOrders, setCheckOnProcessedOrders] = React.useState(
    false
  );
  const [
    sendGenerateReportRequest,
    isLoadingGenerateReportRequest,
  ] = useHttpClient(true);
  const [
    sendGenerateInvoiceRequest,
    isLoadingGenerateInvoiceRequest,
  ] = useHttpClient(true);

  const { i18n } = useTranslation();

  const handleGeneratingReportAndInvoice = async (data) => {
    try {
      // let responseReport;
      const responseReport = await sendGenerateReportRequest(
        api.driverShifts.generateEndOfShiftReport(driverShift?.id)
      );
      const responseInvoice = await sendGenerateInvoiceRequest(
        api.driverShifts.generateInvoice(driverShift?.id)
      );

      toast.success("Report and invoice generated successfully");
      // successCallback();
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  return {
    handleGeneratingReportAndInvoice,
    isLoadingGenerateReportRequest,
    isLoadingGenerateInvoiceRequest,
    setCheckOnProcessedOrders,
    checkOnProcessedOrders,
  };
}
