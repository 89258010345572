import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import CustomTable from "components/CustomTable/CustomTable";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useCarsList from "./CarsList.hook";
import styles from "./CarsList.styles";
import CarsFormModalPage from "../CarsFormModal/CarsFormModal";
import CheckPermission from "components/CheckPermission/CheckPermission";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import enums from "enums/index";
import api from "services/api";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function CarsList(props) {
  const classes = useStyles();

  const { i18n } = useTranslation();
  // const [isAddCarModelOpen, setIsAddCarModelOpen] = useState(false);
  // const [editedCar, setEditedCar] = useState(null);

  const { resource } = props;
  const path = "cars";

  const {
    currentPage,
    listData,
    searchQuery,
    filtersState,
    itemsPerPage,
    highlightedText,
    isGetLoading,
    isAssignModalOpen,
    editedCar,
    onAssignModalClose,
    onSearchChange,
    onFilterChange,
    handleChange,
    handleSubmit,
    handleAssignItem,
    handleRemoveItem,
  } = useCarsList({
    resource,
  });

  const columns = React.useMemo(
    () => [
      {
        title: "Model",
        dataIndex: "model",
        canBeHighlighted: true,
        render: (_, record) =>
          `${record.model?.maker?.name} - ${record.model?.name} - ${
            record.model?.year ? record.model?.year : ""
          }`,
      },
      {
        dataIndex: ["batterySize", "name"],
        title: "Battery Size",
      },
      {
        dataIndex: ["wiperBladeSize", "name"],
        title: "Wiper Blade Size",
      },
      {
        title: "Owner",
        dataIndex: ["owner", "name"],
        canBeHighlighted: true,
      },
      {
        title: "Owner Phone Number",
        dataIndex: ["owner", "primaryPhoneNumber"],
        canBeHighlighted: true,
      },
      {
        title: "",
        render: (_, record) => {
          return (
            <div className={classes.actionsContainer}>
              <Button
                justIcon
                edit
                color="white"
                textColor="success"
                onClick={() => handleAssignItem(record)}
              />
              <ConfirmationButton
                name="this item"
                entityName="Item"
                onConfirm={() => handleRemoveItem(record)}
                // onSuccess={() => onRemoveModalClose(record)}
                buttonProps={{
                  justIcon: true,
                }}
              />
            </div>
          );
        },
      },
    ],
    [listData]
  );

  return (
    <div>
      {isAssignModalOpen && (
        <CarsFormModalPage
          isOpen={isAssignModalOpen}
          onClose={onAssignModalClose}
          create={!editedCar}
          edit={!!editedCar}
          car={editedCar}
          path={path}
        />
      )}
      <div className={classes.topContainer}>
        <div className={classes.buttonsContainer}>
          <Button create color="white" onClick={() => handleAssignItem()} />
        </div>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTable
            columns={columns}
            data={listData.rows}
            onChange={handleChange}
            pagination={{
              total: listData.count,
              current: currentPage,
              pageSize: itemsPerPage,
              position: ["topRight"],
              pageSizeOptions: [10, 20, 50],
            }}
            loading={isGetLoading}
            highlightedText={highlightedText}
            filterProps={{
              value: filtersState,
              onChange: onFilterChange,
            }}
            searchProps={{
              onChange: onSearchChange,
              value: searchQuery,
              placeholder: "Search",
            }}
            handleSubmit={handleSubmit}
            title="Cars Summary"
            addIndexColumn
            size="middle"
            fixIndexColumn
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

CarsList.propTypes = {
  resource: PropTypes.string,
};
