import * as yup from "yup";
import enums from "enums/index";
import warehousesForm from "./warehousesForm";
import api from "services/api";
export default [
  {
    column: 1,
    fields: [
      {
        name: "truckNumber",
        type: "text",
        label: "Truck Number",
        validation: yup.mixed().required("Required"),
      },
      {
        name: "truckTypeId",
        fetchedName: "truckType",
        type: "autocomplete",
        label: "Truck Type",
        options: {
          getData: api.truckTypes.getAll,
          getOptionLabel: (option) => option.name,
          getSubmitValue: (option) => option?.id,
          loadOnFocus: true,
          blurOnSelect: false,
        },
        validation: yup.mixed().required("Required"),
      },
      {
        name: "status",
        type: "select",
        label: "Status",
        options: {
          enum: "truckStatus",
        },
        validation: yup.mixed().required("Required"),
      },
      {
        name: "licenseDocumentUrl",
        type: "dropzone",
        label: "License Document",
        options: { maxFiles: 1 },
      },
      {
        name: "warehouseCode",
        type: "text",
        label: "Warehouse Code",
      },
      {
        name: "licenseExpirationDate",
        type: "date",
        label: "License Expiry Date",
      },
    ],
  },
];
