import AccountBoxIcon from "@material-ui/icons/AccountBox";
import carsRoutes from "./carsRoutes";
import customersRoutes from "./customersRoutes";
import enums from "enums/index";

export default {
  name: "Clients Management",
  icon: AccountBoxIcon,
  layout: "/admin",
  collapse: true,
  state: "clientsCollapse",
  resource: enums.Resource.CLIENTS_MANAGEMENT,
  superAdmin: true,
  views: [{ ...customersRoutes }, { ...carsRoutes }],
};
