import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CheckPermission from "components/CheckPermission/CheckPermission";
import Button from "components/CustomButtons/Button.js";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import CustomTable from "components/CustomTable/CustomTable";
import enums from "enums/index";
import React from "react";
import styles from "./NonSerializedItems.styles";
import { Controller } from "react-hook-form";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function NonSerializedItems({
  form,
  // onAdd,
  // onEdit,
  order,
}) {
  const classes = useStyles();

  const { control, errors } = form;

  const nonSerializedItems = form.watch("nonSerializedItems");

  const columns = [
    {
      title: "SAP Code",
      dataIndex: ["product", "sapCode"],
    },
    {
      title: "Description",
      dataIndex: ["product", "description"],
    },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      render: (_, record) => {
        if (record.id) return record.unitPrice;
        return record.product.unitPrice;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Total Price",
      dataIndex: "totalPrice",
    },
  ];

  return (
    <Controller
      render={(props) => (
        <div className={classes.root}>
          <div className={classes.section}>
            {nonSerializedItems.length > 0 && (
              <React.Fragment>
                <div className={classes.headerContainer}>
                  <h3 className={classes.header}>
                    Order Items with Non-Serialized Products
                  </h3>
                </div>
                <div className={classes.tableContainer}>
                  <CustomTable
                    columns={columns}
                    data={nonSerializedItems}
                    addIndexColumn
                  />
                </div>
              </React.Fragment>
            )}
            <div className={classes.error}>
              {errors?.nonSerializedItems?.message}
            </div>
          </div>
        </div>
      )}
      name="nonSerializedItems"
      control={control}
    />
  );
}
