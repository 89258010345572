import enums from "enums/index";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import { OrderContext } from "views/Orders/pages/OrderView/Order.context";
import OrderDetailsFormValidation from "./OrderDetailsForm.validation";

//-------------------------------------------------------------------------------------

export const useOrderDetailsForm = ({ edit, setIsEditing, path: pathProp }) => {
  const { path: pathContext, setOrder: setOrderContext } = React.useContext(
    OrderContext
  );

  const [order, setOrder] = React.useState(null);

  const statusesAfterPlanned = [
    enums.OrderStatus.PLANNED,
    enums.OrderStatus.ASSIGNED,
    enums.OrderStatus.IN_PROGRESS,
    enums.OrderStatus.PENDING_STOCK_APPROVAL,
    enums.OrderStatus.COMPLETED,
  ];

  const path = pathProp || pathContext;

  const [sendGetRequest, isGetOrderLoading] = useHttpClient();
  const [sendRequest, isLoading] = useHttpClient(true);

  const history = useHistory();

  const dataResource = React.useMemo(() => api.orders, []);

  React.useEffect(() => {
    (async () => {
      if (edit) {
        const id = window.location.pathname.split("/").pop().trim();
        if (id && !isNaN(id)) {
          try {
            const response = await sendGetRequest(dataResource.getOne(id));
            setOrder(response);
          } catch (err) {
            toast.error("An error has occurred");
            setIsEditing(false);
          }
        } else {
          toast.error("An error has occurred");
          setIsEditing(false);
        }
      }
    })();
  }, [edit, setIsEditing, dataResource]);

  React.useEffect(() => {
    (async () => {
      if (edit && order) {
        const tempOrder = { ...order };
        Object.keys(tempOrder).forEach((key) => {
          if (
            key !== "deletedAt" &&
            key !== "updatedAt" &&
            key !== "createdAt" &&
            key !== "items"
          )
            setValue(key, tempOrder[key]);
        });
        if (tempOrder.car?.owner) {
          setValue("customer", tempOrder.car.owner);
        }
        if (tempOrder.plannedDeliveryDistrict?.city) {
          setValue(
            "plannedDeliveryCity",
            tempOrder.plannedDeliveryDistrict.city
          );
        }
        if (
          tempOrder.plannedDeliveryLongitude &&
          tempOrder.plannedDeliveryLatitude
        ) {
          setValue(
            "plannedDeliveryCoordinates",
            tempOrder.plannedDeliveryLatitude +
              "," +
              tempOrder.plannedDeliveryLongitude
          );
        }
        if (tempOrder.items?.length) {
          setValue(
            "items",
            tempOrder.items.filter(
              (item) => item.type === enums.ProductType.NEW
            )
          );

          setValue(
            "scraps",
            tempOrder.items.filter(
              (item) => item.type === enums.ProductType.SCRAP
            )
          );
        }
        if (tempOrder.services?.length) {
          setValue("services", tempOrder.services);
        }
      }
    })();
  }, [edit, order]);

  const resolver = useYupValidationResolver(OrderDetailsFormValidation());

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
    defaultValues: {
      status: enums.OrderStatus.LEAD,
      items: [],
      scraps: [],
      services: [],
    },
  });

  const defaultOrderItem = useMemo(() => {}, []);

  const { handleSubmit, setValue, getValues, watch } = form;

  const customer = watch("customer");
  //lost lead info
  const leadLossReasonId = watch("leadLossReason")?.id;
  const leadFollowUp = watch("leadFollowUp");
  const car = watch("car");
  //order info
  const deliveryType = watch("deliveryType");
  const paymentMethodId = watch("paymentMethod")?.id;
  const zoneId = watch("zone")?.id;
  const plannedDeliveryTime = watch("plannedDeliveryTime");
  const desiredDeliveryTime = watch("desiredDeliveryTime");
  const plannedDeliveryStreet = watch("plannedDeliveryStreet");
  const plannedDeliveryCoordinates = watch("plannedDeliveryCoordinates");
  const plannedDeliveryDistrictId = watch("plannedDeliveryDistrict")?.id;
  const plannedDeliveryCityId = watch("plannedDeliveryCity")?.id;
  //matching info
  const assignedDriverId = watch("assignedDriver")?.id;
  const assignedTruckId = watch("assignedTruck")?.id;

  const items = watch("items");
  const scraps = watch("scraps");
  const zone = watch("zone");
  const services = watch("services");
  const totalPrice = useMemo(() => {
    let totalPriceOfItems = 0;
    totalPriceOfItems = items?.reduce((total, item) => {
      return total + parseFloat(item.totalPrice);
    }, 0);
    if (zone?.fees) {
      totalPriceOfItems += parseFloat(zone.fees);
    }
    totalPriceOfItems = scraps?.reduce((total, item) => {
      return total - parseFloat(item.totalPrice);
    }, totalPriceOfItems);

    totalPriceOfItems = services?.reduce((total, service) => {
      return total + parseFloat(service.price);
    }, totalPriceOfItems);

    return totalPriceOfItems;
  }, [items, scraps, zone]);

  React.useEffect(() => {
    const orderInfoValues = [
      deliveryType,
      paymentMethodId,
      zoneId,
      plannedDeliveryTime,
      desiredDeliveryTime,
      plannedDeliveryStreet,
      plannedDeliveryCoordinates,
      plannedDeliveryDistrictId,
      plannedDeliveryCityId,
    ];
    const matchingInfoValues = [assignedDriverId, assignedTruckId];
    const lostLeadInfoValues = [leadLossReasonId];
    if (edit && order && statusesAfterPlanned.includes(order.status)) {
      setValue("status", order.status);
    } else if (lostLeadInfoValues.some((value) => value)) {
      setValue("status", enums.OrderStatus.LOST);
    } else if (matchingInfoValues.some((value) => value)) {
      setValue("status", enums.OrderStatus.ASSIGNED);
    } else if (
      orderInfoValues.some((value) => value) ||
      leadFollowUp === enums.LeadFollowUp.ORDERED
    ) {
      setValue("status", enums.OrderStatus.PLANNED);
    } else if (!edit) {
      setValue("status", enums.OrderStatus.LEAD);
    }
  }, [
    deliveryType,
    paymentMethodId,
    zoneId,
    plannedDeliveryTime,
    desiredDeliveryTime,
    plannedDeliveryStreet,
    plannedDeliveryCoordinates,
    plannedDeliveryDistrictId,
    plannedDeliveryCityId,
    assignedDriverId,
    assignedTruckId,
    leadLossReasonId,
    leadFollowUp,
  ]);

  const createHandler = (requestData) => async () => {
    try {
      const response = await sendRequest(
        dataResource.create({
          ...requestData,
        })
      );
      toast.success("Order created successfully");
      history.push({
        pathname: `/admin/${path}/${response.id}`,
      });
    } catch (err) {
      // history.push({
      //   pathname: `/admin/${path}`,
      // });
    }
  };

  const editHandler = (requestData) => async () => {
    try {
      const response = await sendRequest(
        dataResource.patch({
          ...requestData,
        })
      );
      setOrderContext({ ...response });
      toast.success("Order details edited successfully");
      setIsEditing(false);
    } catch (err) {
      // toast.error("An error has occurred");
      // setIsEditing(false);
    }
  };

  const onError = () => {
    toast.error("Please fix the errors above and submit again.");
  };

  const handleAddNewCar = (car) => {
    setValue("car", car);
    setValue("customer", car.owner);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    let requestData = { ...getValues() };

    requestData = {
      ...requestData,
      leadLossReasonId: requestData.leadLossReason?.id ?? null,
      plannedDeliveryLatitude:
        plannedDeliveryCoordinates?.split(",")[0]?.trim() ?? null,
      plannedDeliveryLongitude:
        plannedDeliveryCoordinates?.split(",")[1]?.trim() ?? null,

      leadFollowUp: requestData.leadFollowUp ?? null,
      notes: requestData.notes ?? null,
      followUpDate: requestData.followUpDate
        ? new Date(requestData.followUpDate).toISOString()
        : null,
      cancellationReasonId: requestData.cancellationReason?.id ?? null,
      deliveryType: requestData.deliveryType ? requestData.deliveryType : null,
      paymentMethodId: requestData.paymentMethod?.id ?? null,
      plannedDeliveryTime: requestData.plannedDeliveryTime
        ? new Date(requestData.plannedDeliveryTime).toISOString()
        : null,

      desiredDeliveryTime: requestData.desiredDeliveryTime
        ? new Date(requestData.desiredDeliveryTime).toISOString()
        : null,
      carId: requestData.car?.id,
      plannedDeliveryDistrictId:
        requestData.plannedDeliveryDistrict?.id ?? null,
      zoneId: requestData.zone?.id ?? null,
      assignedDriverId: requestData.assignedDriver?.id ?? null,
      assignedTruckId: requestData.assignedTruck?.id ?? null,
      orderRating: requestData.orderRating ?? null,
      items: [...requestData.items, ...requestData.scraps],
      services: requestData.services,
    };
    delete requestData.car;
    delete requestData.plannedDeliveryCity;
    delete requestData.plannedDeliveryDistrict;
    delete requestData.zone;
    delete requestData.assignedDriver;
    delete requestData.assignedTruck;
    delete requestData.scraps;
    delete requestData.leadLossReason;
    delete requestData.cancellationReason;
    delete requestData.paymentMethod;

    handleSubmit(
      edit ? editHandler(requestData) : createHandler(requestData),
      onError
    )();
  };
  return {
    isLoading,
    isGetOrderLoading,
    form,
    order,
    customer,
    defaultOrderItem,
    plannedDeliveryCityId,
    car,
    totalPrice,
    onSubmit,
    handleAddNewCar,
  };
};
