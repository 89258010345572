import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CheckPermission from "components/CheckPermission/CheckPermission";
import Button from "components/CustomButtons/Button.js";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import CustomTable from "components/CustomTable/CustomTable";
import enums from "enums/index";
import React from "react";
import useCalls from "./Calls.hook";
import styles from "./Calls.styles";
import CallModal from "./components/OrderItemModal/CallModal";
import { useTranslation } from "react-i18next";
import translations from "constants/translations/index";
import { getDateString, isOrderEditable } from "utils/index";
import { AuthContext } from "shared/context/auth-context";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function Calls() {
  const classes = useStyles();

  const {
    call,
    order,
    isAssignModalOpen,
    isGetOrderLoading,
    editedCall,
    calls,
    onAssignModalClose,
    handleAssignCall,
    handleRemoveCall,
    onRemoveModalClose,
  } = useCalls();

  const { i18n } = useTranslation();
  const { userData } = React.useContext(AuthContext);

  const columns = React.useMemo(
    () => [
      {
        title: "Log ID",
        dataIndex: "logId",
      },
      {
        title: "Call Type",
        dataIndex: "callType",
        getData: (data) => translations[i18n.language].callType[data],
      },
      {
        title: "Reason",
        dataIndex: "reason",
        getData: (data) => translations[i18n.language].callReason[data],
      },
      {
        title: "Description",
        dataIndex: "description",
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        render: (data) => getDateString(data, "en-EG", true),
      },
      {
        title: "",
        render: (_, record) => {
          return (
            <div className={classes.actionsContainer}>
              {isOrderEditable(order, userData) && (
                <CheckPermission action={enums.Action.UPDATE}>
                  <Button
                    justIcon
                    edit
                    color="white"
                    textColor="success"
                    onClick={() => handleAssignCall(record)}
                  />
                </CheckPermission>
              )}
              {isOrderEditable(order, userData) && (
                <CheckPermission action={enums.Action.DELETE}>
                  <ConfirmationButton
                    name="this call"
                    entityName="Call"
                    onConfirm={() => handleRemoveCall(record)}
                    onSuccess={() => onRemoveModalClose(record)}
                    buttonProps={{
                      justIcon: true,
                    }}
                  />
                </CheckPermission>
              )}
            </div>
          );
        },
      },
    ],
    [call]
  );

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        {isAssignModalOpen && (
          <CallModal
            isOpen={isAssignModalOpen}
            onClose={onAssignModalClose}
            create={!editedCall}
            edit={!!editedCall}
            call={editedCall}
          />
        )}
        <React.Fragment>
          <div className={classes.headerContainer}>
            <h3 className={classes.header}>Calls</h3>
            {isOrderEditable(order, userData) && (
              <CheckPermission action={enums.Action.CREATE}>
                <Button
                  onClick={() => handleAssignCall()}
                  className={classes.addButton}
                  color="success"
                >
                  <AddIcon className={classes.addIcon} />
                  Add Call
                </Button>
              </CheckPermission>
            )}
          </div>
          <div className={classes.tableContainer}>
            <CustomTable
              columns={columns}
              data={calls}
              loading={isGetOrderLoading}
              addIndexColumn
            />
          </div>
        </React.Fragment>
      </div>
    </div>
  );
}
