import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModal/CustomModal";
import FormField from "components/Forms/FormField/FormField";
import GridContainer from "components/Grid/GridContainer.js";
import React from "react";
import GridItem from "components/Grid/GridItem.js";
import PropTypes from "prop-types";
import api from "services/api";
import styles from "./OrderServiceModal.styles";
import useOrderServiceModal from "./OrderServiceModal.hook";
import enums from "enums/index";
import InfoWithLabel from "components/InfoWithLabel/InfoWithLabel";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function OrderServiceModal(props) {
  const { isOpen, onClose, service: serviceProp, onAdd, onEdit, order } = props;

  const classes = useStyles();

  const { submitHandler, form } = useOrderServiceModal({
    serviceProp,
    onClose,
    onAdd,
    onEdit,
  });
  const service = form.watch("service");
  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ margin: 0 }}>
            <CardBody>
              <div className={classes.formHeader}>
                {serviceProp ? (
                  <React.Fragment>
                    <EditIcon className={classes.icon} /> Edit Order Service
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <AddIcon className={classes.icon} />
                    Add Order Service
                  </React.Fragment>
                )}
              </div>
              <form
                style={{ padding: 20, paddingBottom: 0 }}
                onSubmit={submitHandler}
                autoComplete="off"
              >
                <FormField
                  md={12}
                  name="service"
                  type="autocomplete"
                  label="Service"
                  form={form}
                  options={{
                    getData: () => api.services.getAll(),
                    getOptionLabel: (option) => option.name,
                    loadOnFocus: true,
                  }}
                />

                <InfoWithLabel
                  label="Price"
                  info={service?.price ? service.price : "N/A"}
                />

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    color="primary"
                    type="submit"
                    style={{ margin: "20px 0" }}
                    edit={!!serviceProp}
                    create={!serviceProp}
                    loadFromRight
                  />
                </div>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </CustomModal>
  );
}

OrderServiceModal.defaultProps = {
  onClose: () => {},
};

OrderServiceModal.propTypes = {
  service: PropTypes.object,
  onClose: PropTypes.func,
};
