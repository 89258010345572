import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModal/CustomModal";
import FormField from "components/Forms/FormField/FormField";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import api from "services/api";
import styles from "./ScrapItemModal.styles";
import useScrapItemModal from "./ScrapItemModal.hook";
import InfoWithLabel from "components/InfoWithLabel/InfoWithLabel";
import { displayProductName } from "utils/index";
import enums from "enums/index";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function ScrapItemModal(props) {
  const { isOpen, onClose, item: itemProp } = props;

  const classes = useStyles();

  const { i18n } = useTranslation();

  const { isLoading, submitHandler, form } = useScrapItemModal({
    itemProp,
    onClose,
  });

  const selectedScrap = form.watch("product");

  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ margin: 0 }}>
            <CardBody>
              <div className={classes.formHeader}>
                {itemProp ? (
                  <React.Fragment>
                    <EditIcon className={classes.icon} /> Edit Scrap Item
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <AddIcon className={classes.icon} />
                    Add Scrap Item
                  </React.Fragment>
                )}
              </div>
              <form
                style={{ padding: 20, paddingBottom: 0 }}
                onSubmit={submitHandler}
                autoComplete="off"
              >
                <FormField
                  md={12}
                  name="product"
                  type="autocomplete"
                  label="Scrap"
                  form={form}
                  options={{
                    getData: () =>
                      api.products.getAll(undefined, undefined, undefined, {
                        type: enums.ProductType.SCRAP,
                      }),
                    getOptionLabel: (option) => displayProductName(option),
                    loadOnFocus: true,
                  }}
                />
                <InfoWithLabel
                  label="Unit Price"
                  info={selectedScrap?.unitPrice || "N/A"}
                  formField
                />
                <FormField
                  md={12}
                  name="amount"
                  type="number"
                  label="Amount"
                  form={form}
                />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    color="primary"
                    type="submit"
                    loading={isLoading}
                    style={{ margin: "20px 0" }}
                    edit={!!itemProp}
                    create={!itemProp}
                    loadFromRight
                  />
                </div>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </CustomModal>
  );
}

ScrapItemModal.defaultProps = {
  onClose: () => {},
};

ScrapItemModal.propTypes = {
  item: PropTypes.object,
  onClose: PropTypes.func,
};
