import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import { deepen, flattenObject } from "utils/index";
import { OrderContext } from "views/Orders/pages/OrderView/Order.context";
import ItemValidation from "./ScrapItem.validation";
import enums from "enums/index";

//-------------------------------------------------------------------------------------

export default function useScrapItemModal({
  itemProp,
  onClose,
  onAdd,
  onEdit,
}) {
  const { order } = React.useContext(OrderContext);

  const { i18n } = useTranslation();

  const resolver = useYupValidationResolver(ItemValidation());

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
    defaultValues: {
      amount: 1,
    },
  });

  const { handleSubmit, getValues, setValue } = form;

  React.useEffect(() => {
    if (itemProp) {
      const formData = {
        ...flattenObject(itemProp),
        orderId: order.id,
      };

      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key]);
      });
    } else {
      setValue("orderId", order.id);
    }
  }, [itemProp, order.id]);

  const createHandler = (values) => async () => {
    try {
      onAdd(deepen(values));
      onClose();
    } catch {}
  };

  const editHandler = (values) => async () => {
    try {
      onEdit(deepen(values));
      onClose();
    } catch {}
  };

  const submitHandler = (e) => {
    if (e) {
      if (typeof e.preventDefault === "function") {
        e.preventDefault();
      }
      if (typeof e.stopPropagation === "function") {
        e.stopPropagation();
      }
    }

    let values = getValues();

    values = {
      ...values,
      totalPrice: 0,
      unitPrice: values.product?.unitPrice || 0,
      orderId: order.id,
      type: enums.ProductType.SCRAP,
    };

    if (values.amount && values.product?.unitPrice) {
      values.totalPrice = (values.amount * values.product.unitPrice).toFixed(2);
    }

    handleSubmit(
      !!itemProp ? editHandler(values) : createHandler(values),
      onError
    )();
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    i18n,
    form,
    submitHandler,
    onError,
  };
}
