import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "services/api.js";
import { auth } from "services/firebase";
import { useHttpClient } from "./http-hook";
import enums from "enums/index";
import { intersect } from "utils/index";

export const useAuth = () => {
  const [userData, setUserData] = useState(false);
  const [fetchUserRequest] = useHttpClient();

  const login = useCallback(async () => {
    try {
      const response = await fetchUserRequest(
        api.users.fetchUserData(auth.currentUser.uid)
      );
      setUserData({
        id: response.id,
        email: response.email,
        name: response.name,
        title: response.title,
        phone: response.phone,
        username: response.username,
        role: response.role,
        roleCode: response.roleCode,
        unit: response.unit,
        permissions: response.permissions,
        resources: response.permissions
          ? Object.keys(response.permissions)
          : [],
      });
      api.setAuthToken(auth.currentUser.accessToken);
    } catch (err) {
      console.log("err", err);
      toast.error("Login failed");
      logout();
    }
  }, [logout]);

  const logout = useCallback(async () => {
    setUserData(null);
    try {
      await auth.signOut();
      await api.clearAuthToken();
    } catch (err) {
      console.log("err", err);
      toast.error("Something went wrong");
    }
  }, []);

  const updateUserData = useCallback(
    async (updatedUserData) => {
      setUserData((prevData) => ({ ...prevData, ...updatedUserData }));
    },
    [setUserData]
  );

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        login();
      } else {
        logout();
      }
    });
    return () => unsubscribe();
  }, []);

  const getAccessPermission = useCallback(
    ({ resources, roles }) => {
      roles = roles ? [...roles, enums.RoleCode.SUPER_ADMIN] : null;
      if (
        intersect(userData.resources, resources).length &&
        (!roles || intersect(userData.roleCode, roles).length)
      )
        return true;
      else return false;
    },
    [userData]
  );

  return { userData, login, logout, updateUserData, getAccessPermission };
};
