import DataList from "components/DataList/DataList";
import FormContainer from "components/Forms/FormContainer/FormContainer";
import enums from "enums/index";
import React from "react";
import api from "services/api";
import paymentMethodForm from "views/Settings/paymentMethodForm";

// appear in breadcrumbs and page headers
const listPageName = "Payment Methods";
const createPageName = "Create Payment Method";
const editPageName = "Edit Payment Method";

// appears on alerts; example: Entity created successfully
const entityName = "Payment Method";

const resource = enums.Resource.SETTINGS;

const layout = "/admin";

const path = "payment-methods";

const formStructure = paymentMethodForm;

const dataResource = api.paymentMethods;

const columns = [
  {
    title: "Payment Method",
    dataIndex: "name",
    canBeHighlighted: true,
  },
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: "payments",
  render: () => (
    <DataList
      entityName={entityName}
      path={path}
      columns={columns}
      actions={{ edit: true, delete: true }}
      dataResource={dataResource}
      resource={resource}
      listPageName={listPageName}
      formStructure={formStructure}
      formType={enums.FormContainerType.MODAL}
    />
  ),
  action: "read",
  resource,
  layout: layout,
  // randomKey: true,
  children: true,
  superAdmin: true,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          create
        />
      ),
      layout: layout,
      randomKey: true,
      action: "create",
      superAdmin: true,
      resource,
    },
    {
      path: `/${path}/:id/edit`,
      name: editPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          edit
        />
      ),
      layout: layout,
      superAdmin: true,
      randomKey: true,
      action: "update",
      resource,
    },
  ],
};
