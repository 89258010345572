import DataList from "components/DataList/DataList";
import FormContainer from "components/Forms/FormContainer/FormContainer";
import enums from "enums/index";
import React from "react";
import api from "services/api";
import PromoCodeForm from "views/Settings/promoCodeForm";

// appear in breadcrumbs and page headers
const listPageName = "Promo Codes";
const createPageName = "Create Promo Code";
const editPageName = " Edit Promo Code";

// appears on alerts; example: Entity created successfully
const entityName = "Promo Code";

const resource = enums.Resource.SETTINGS;

const layout = "/admin";

const path = "promo-codes";

const formStructure = PromoCodeForm;

const dataResource = api.promoCodes;

const columns = [
  {
    title: "Promo Code",
    dataIndex: "code",
    canBeHighlighted: true,
  },
  {
    title: "Amount",
    dataIndex: "amount",
  },
  //   {
  //     dataIndex: "type",
  //     title: "Type",
  //     getData: (data) => {
  //       if (data) return translations["en"].promoCodeType[data];
  //       else return "-";
  //     },
  //   },
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: "percent",
  render: () => (
    <DataList
      entityName={entityName}
      path={path}
      columns={columns}
      actions={{ edit: true, delete: true }}
      dataResource={dataResource}
      resource={resource}
      listPageName={listPageName}
      formStructure={formStructure}
      formType={enums.FormContainerType.MODAL}
    />
  ),
  action: "read",
  resource,
  layout: layout,
  // randomKey: true,
  children: true,
  superAdmin: true,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          create
        />
      ),
      layout: layout,
      randomKey: true,
      action: "create",
      superAdmin: true,
      resource,
    },
    {
      path: `/${path}/:id/edit`,
      name: editPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          edit
        />
      ),
      layout: layout,
      randomKey: true,
      action: "update",
      superAdmin: true,
      resource,
    },
  ],
};
