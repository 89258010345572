import enums from "enums/index";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import RateOrderValidation from "./RateOrder.validation";

//-------------------------------------------------------------------------------------

export default function useRateOrderModal({
  orderId,
  onClose,
  successCallback,
}) {
  const [sendRequest, isLoading] = useHttpClient(true);

  const { i18n } = useTranslation();

  const resolver = useYupValidationResolver(RateOrderValidation());

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const { handleSubmit, getValues, setValue } = form;
  const submitHandler = async () => {
    let values = getValues();

    //
    values = {
      ...values,
      id: orderId,
      orderRating: values.orderRating,
    };
    try {
      const updatedOrder = await sendRequest(api.orders.rate(orderId, values));
      toast.success(`Order has been rated successfully`);
      successCallback(updatedOrder);

      onClose();
    } catch {}
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    isLoading,
    i18n,
    form,
    submitHandler,
    onError,
    handleSubmit,
  };
}
