import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import AddStockTransferValidation from "./AddStockTransferModal.validation";
import { AuthContext } from "shared/context/auth-context";

//-------------------------------------------------------------------------------------

export default function useAddStockTransferModal({ onClose, successCallback }) {
  const [sendRequest, isLoading] = useHttpClient(true);

  const { i18n } = useTranslation();

  const dataResource = React.useMemo(() => api.stockItemsTransfers, []);

  const resolver = useYupValidationResolver(AddStockTransferValidation());

  const auth = React.useContext(AuthContext);

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
    defaultValues: {
      amount: 1,
    },
  });

  const { handleSubmit, getValues, setValue } = form;
  const submitHandler = async () => {
    let values = getValues();

    values = {
      ...values,
      fromId: values.from?.id,
      toId: values.to?.id,
      productId: values.product?.id,
      driverShiftId: values.driverShift?.id,
    };
    try {
      const newTransfer = await sendRequest(dataResource.create(values));
      onClose();

      // successCallback(newTransfer);
      toast.success(`Transfer Request added successfully`);
    } catch (err) {
      console.log(err);
    }
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    isLoading,
    i18n,
    form,
    userRole: auth.userData.roleCode,
    submitHandler,
    onError,
    handleSubmit,
  };
}
