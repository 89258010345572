import { makeStyles } from "@material-ui/core/styles";
import CustomTable from "components/CustomTable/CustomTable";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import translations from "constants/translations/index";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./WarrantiesList.styles";
import CheckPermission from "components/CheckPermission/CheckPermission";
import enums from "enums/index";
import Button from "components/CustomButtons/Button.js";
import { Link } from "react-router-dom";
import AddWarrantyModal from "./components/AddWarrantyModal/AddWarrantyModal";
import AddWarrantySheetModal from "./components/AddWarrantySheetModal/AddWarrantySheetModal";
import useWarrantiesList from "./WarrantiesList.hook";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import { getDateString } from "utils/index";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function WarrantiesList(props) {
  const classes = useStyles();

  const { i18n } = useTranslation();
  const [isAddWarrantyModalOpen, setIsAddWarrantyModalOpen] = useState(false);
  const [isAddWarrantySheetOpen, setIsAddWarrantySheetModalOpen] = useState(
    false
  );

  const { resource } = props;

  const filterInputs = useMemo(() => [], []);

  const {
    currentPage,
    listData,
    searchQuery,
    filtersState,
    itemsPerPage,
    highlightedText,
    isGetLoading,
    onSearchChange,
    onFilterChange,
    handleChange,
    handleSubmit,
    updateWarrantiesList,
    handleAssignItem,
    editedWarranty,
    setEditedWarranty,
    handleRemoveItem,
    onAssignModalClose,
    editingWarrantiesList,
  } = useWarrantiesList({
    resource,
    filterInputs,
    setIsAddWarrantyModalOpen,
  });

  const columns = React.useMemo(
    () => [
      {
        dataIndex: ["serialNumber"],
        title: "Serial Number",
        canBeHighlighted: true,
      },

      {
        dataIndex: ["order", "car", "owner", "name"],
        title: "Customer",
        canBeHighlighted: true,
      },
      {
        dataIndex: ["status"],
        title: "Status",
        getData: (data) => {
          if (data) return translations["en"].warrantyStatus[data];
          else return "-";
        },
      },
      {
        dataIndex: ["activationDate"],
        title: "Activation Date",
        getData: (data) => (data ? getDateString(data, "en-EG", true) : "-"),
      },
      {
        dataIndex: ["driver", "name"],
        title: "Driver",
      },
      {
        title: "",
        render: (_, record) => {
          return (
            <div className={classes.actionsContainer}>
              <CheckPermission action={enums.Action.UPDATE}>
                <Button
                  justIcon
                  edit
                  color="white"
                  textColor="success"
                  onClick={() => handleAssignItem(record)}
                />
              </CheckPermission>
              <CheckPermission action={enums.Action.DELETE}>
                <ConfirmationButton
                  name="this item"
                  entityName="Item"
                  onConfirm={() => handleRemoveItem(record)}
                  // onSuccess={() => onRemoveModalClose(record)}
                  buttonProps={{
                    justIcon: true,
                  }}
                />
              </CheckPermission>
            </div>
          );
        },
      },
    ],
    [listData]
  );

  return (
    <div>
      {isAddWarrantyModalOpen && (
        <AddWarrantyModal
          isOpen={isAddWarrantyModalOpen}
          onClose={() => {
            setIsAddWarrantyModalOpen(false);
            setEditedWarranty(null);
          }}
          key={isAddWarrantyModalOpen}
          successCallback={updateWarrantiesList}
          editedWarranty={editedWarranty}
          onAssignModalClose={onAssignModalClose}
          editingWarrantiesList={editingWarrantiesList}
        />
      )}
      {isAddWarrantySheetOpen && (
        <AddWarrantySheetModal
          isOpen={isAddWarrantySheetOpen}
          onClose={() => setIsAddWarrantySheetModalOpen(false)}
          key={isAddWarrantySheetOpen}
          successCallback={updateWarrantiesList}
        />
      )}
      <div className={classes.topContainer}>
        <div className={classes.buttonsContainer}>
          <Button
            create
            color="white"
            onClick={() => setIsAddWarrantyModalOpen(true)}
          >
            Add Serial Number
          </Button>
          <Button
            color="white"
            onClick={() => setIsAddWarrantySheetModalOpen(true)}
          >
            <CloudUploadIcon className={classes.addIcon} />
            Add Serial Number Sheet
          </Button>
        </div>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTable
            columns={columns}
            data={listData.rows}
            onChange={handleChange}
            pagination={{
              total: listData.count,
              current: currentPage,
              pageSize: itemsPerPage,
              position: ["topRight"],
              pageSizeOptions: [10, 20, 50],
            }}
            loading={isGetLoading}
            highlightedText={highlightedText}
            filterProps={{
              value: filtersState,
              onChange: onFilterChange,
            }}
            searchProps={{
              onChange: onSearchChange,
              value: searchQuery,
              placeholder: "Search",
            }}
            handleSubmit={handleSubmit}
            title=" Warranties Summary"
            addIndexColumn
            size="middle"
            fixIndexColumn
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

WarrantiesList.propTypes = {
  resource: PropTypes.string,
};
