import * as yup from "yup";

//----------------------------------------------------------

export default () => {
  return yup.object().shape({
    zone: yup.mixed(),
    plannedDeliveryTime: yup.date().required("Required"),
    desiredDeliveryTime: yup.date().required("Required"),
    plannedDeliveryCity: yup.mixed(),
    plannedDeliveryDistrict: yup.mixed(),
    plannedDeliveryCoordinates: yup
      .mixed()
      .test(
        "isValidCoordinates",
        "Invalid coordinates format it should be in lat,lng format",
        (value) => {
          if (value) {
            const coordinates = value.split(",");

            return (
              (coordinates.length === 2 &&
                /^\d/.test(coordinates[0]) &&
                /\d$/.test(coordinates[1])) ||
              value === ""
            );
          }

          return true;
        }
      ),
    plannedDeliveryStreet: yup.mixed(),
    paymentMethod: yup.mixed().required("Required"),
    deliveryType: yup.mixed().required("Required"),
    items: yup
      .array()
      .of(
        yup.object().shape({
          amount: yup.mixed().required("Required"),
          product: yup.mixed().required("Required"),
        })
      )
      .test(
        "order items validation",
        "Please add at least one item or service",
        (value, context) => {
          const items = context.parent.items;
          const services = context.parent.services;

          // Check if either items or services is present
          return (
            (items && items.length > 0) || (services && services.length > 0)
          );
        }
      ),

    services: yup
      .array()
      .of(
        yup.object().shape({
          service: yup.mixed().required("Required"),
        })
      )
      .test(
        "order items validation",
        "Please add at least one item or service",
        (value, context) => {
          const items = context.parent.items;
          const services = context.parent.services;

          // Check if either items or services is present
          return (
            (items && items.length > 0) || (services && services.length > 0)
          );
        }
      ),

    scraps: yup.array().of(
      yup.object().shape({
        amount: yup.mixed().required("Required"),
        product: yup.mixed().required("Required"),
      })
    ),
  });
};
