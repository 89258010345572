//-------------------------------------------------------------------------------------

const styles = (theme) => ({
  form: {
    padding: 40,
    paddingBottom: 0,
  },
  section: {
    width: "100%",
    marginBottom: 20,
  },
});

export default styles;
