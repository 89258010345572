import enums from "enums/index";
// import DriverShiftPage from "../../../views/DriverShiftManagement/DriverShiftPage";
import CreateDriverShiftPage from "views/DriverShiftManagement/pages/CreateShiftPage/CreateDriverShiftPage";
import DriverShiftsList from "views/DriverShiftManagement/pages/DriverShiftsList/DriverShiftsList";
import React from "react";
import api from "services/api";
import DriverShiftView from "views/DriverShiftManagement/pages/DriverShiftView/DriverShiftView";

// appear in breadcrumbs and page headers
const listPageName = "Driver Shifts";
const createPageName = "Create Driver Shift";

const viewPageName = "View Driver Shift";

// appears on alerts; example: Entity created successfully

const layout = "/admin";

const path = "driver-shifts";

const dataResource = api.driverShifts;

const resource = enums.Resource.DRIVER_SHIFT_MANAGEMENT;

// appear in breadcrumbs and page headers

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: "drive_eta",
  render: () => <DriverShiftsList />,
  action: "read",
  layout: layout,
  children: true,
  resource,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => <CreateDriverShiftPage path={path} />,
      layout: layout,
      randomKey: true,
      action: "create",
      resource,
    },
    {
      path: `/${path}/:id`,
      render: () => <DriverShiftView path={path} />,
      layout: layout,
      // randomKey: true,
      action: "read",
      getName: async (id) => {
        try {
          const data = (await dataResource.getOne(id)).data;
          return `Shift #${data.id}`;
        } catch {
          return viewPageName;
        }
      },
      resource,
    },
  ],
};
