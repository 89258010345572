import { makeStyles } from "@material-ui/core/styles";
import CheckPermission from "components/CheckPermission/CheckPermission";
import ConfirmModal from "components/ConfirmModal/ConfirmModal.js";
import Button from "components/CustomButtons/Button.js";
import CustomTable from "components/CustomTable/CustomTable";
import FormContainer from "components/Forms/FormContainer/FormContainer";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import enums from "enums/index";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { DataListContext } from "./DataList.context";
import useDataList from "./DataList.hook";
import styles from "./DataList.styles";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function DataList(props) {
  const classes = useStyles();

  const {
    dataResource,
    entityName,
    path,
    createUrl,
    columns,
    actions,
    getRowName,
    editable,
    resource: resourceProp,
    hideCreateButton,
    hasRowSelection,
    getDisabledRows,
    filterInputs,
    listPageName,
    searchPlaceholder,
    addIndexColumn,
    submitSelectedRows,
    isSubmitLoading,
    hasExport,
    formType,
    formStructure,
    size,
    defaultValues,
  } = props;

  const resources =
    typeof resourceProp === "string" ? [resourceProp] : resourceProp;

  const {
    currentPage,
    listData,
    searchQuery,
    filtersState,
    modalOpen,
    deleteItemId,
    activeScreen,
    itemsPerPage,
    highlightedText,
    columnsData,
    isGetLoading,
    isDeleteLoading,
    isEditLoading,
    isExportLoading,
    rowSelectionObject,
    isEditable,
    isCreateModalOpen,
    isEditModalOpen,
    editItemId,
    setModalOpen,
    setDeleteItemId,
    setActiveScreen,
    handleSubmit,
    onSearchChange,
    onFilterChange,
    handleChange,
    deleteItem,
    submitHandler,
    exportToCsv,
    getData,
    handleCreateModalOpen,
    handleEditModalOpen,
    handleCreateModalClose,
    handleEditModalClose,
  } = useDataList({
    dataResource,
    entityName,
    columns,
    editable,
    resources,
    hasRowSelection,
    getDisabledRows,
    filterInputs,
    submitSelectedRows,
  });

  const mergedColumns = React.useMemo(
    () => [
      // ...columns,
      ...columnsData,

      ...(actions?.view || actions?.edit || actions?.delete
        ? [
            {
              title: "",
              render: (_, record) => {
                return (
                  <div style={{ display: "flex", width: "fit-content" }}>
                    {actions.view ? (
                      <CheckPermission
                        resources={resources}
                        action={enums.Action.READ}
                      >
                        <Link to={`/admin/${path}/${record?.id}`}>
                          <Button
                            justIcon
                            next
                            color="white"
                            textColor="primary"
                          />
                        </Link>
                      </CheckPermission>
                    ) : null}
                    {actions.edit ? (
                      <CheckPermission
                        resources={resources}
                        action={enums.Action.UPDATE}
                      >
                        {formType === enums.FormContainerType.MODAL ? (
                          <Button
                            justIcon
                            edit
                            color="white"
                            textColor="success"
                            onClick={() => handleEditModalOpen(record?.id)}
                          />
                        ) : (
                          <Link to={`/admin/${path}/${record?.id}/edit`}>
                            <Button
                              justIcon
                              edit
                              color="white"
                              textColor="success"
                            />
                          </Link>
                        )}
                      </CheckPermission>
                    ) : null}
                    {actions.delete ? (
                      <CheckPermission
                        resources={resources}
                        action={enums.Action.DELETE}
                      >
                        <Button
                          justIcon
                          deleteButton
                          onClick={() => {
                            setDeleteItemId(record?.id);
                            setModalOpen(true);
                          }}
                          color="white"
                          textColor="danger"
                        />
                      </CheckPermission>
                    ) : null}
                  </div>
                );
              },
            },
          ]
        : []),
    ],
    [columnsData, actions, resources, path]
  );

  return (
    <DataListContext.Provider
      value={{
        data: listData,
        getData,
      }}
    >
      <div>
        <ConfirmModal
          isOpen={modalOpen}
          onClose={() => {
            setModalOpen(false);
            setDeleteItemId(null);
            setTimeout(() => {
              setActiveScreen("question");
            }, 300);
          }}
          headerMessage={`Delete ${entityName}`}
          successHeaderText="Deleted successfully"
          confirmMessage={`Are you sure you want to delete ${
            getRowName(
              listData?.rows?.find((row) => row.id === deleteItemId)
            ) || entityName
          }?`}
          errorMessage="An error has occurred"
          successMessage={`${entityName} has been deleted successfully.`}
          confirmButtonText="delete"
          confirmButtonColor="danger"
          cancelButtonText="cancel"
          onConfirm={deleteItem}
          loading={isDeleteLoading}
          activeScreen={activeScreen}
          refreshOnSuccess
        />
        <div className={classes.topContainer}>
          <div className={classes.buttonsContainer}>
            {hideCreateButton ? null : (
              <CheckPermission resources={resources} action="create">
                {formType === enums.FormContainerType.MODAL ? (
                  <Button
                    create
                    color="white"
                    onClick={handleCreateModalOpen}
                  />
                ) : (
                  <Link to={createUrl || `/admin/${path}/create`}>
                    <Button create color="white" />
                  </Link>
                )}
              </CheckPermission>
            )}
            {hasExport ? (
              <Button
                className={classes.exportButton}
                color="primary"
                onClick={exportToCsv}
                loading={isExportLoading}
              >
                Export
              </Button>
            ) : null}
          </div>
        </div>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTable
              columns={mergedColumns}
              data={listData.rows}
              onChange={handleChange}
              pagination={{
                total: listData.count,
                current: currentPage,
                pageSize: itemsPerPage,
                position: ["topRight"],
                pageSizeOptions: [10, 20, 50],
              }}
              loading={isGetLoading}
              editHandler={submitHandler}
              isEditLoading={isEditLoading || isSubmitLoading}
              editable={isEditable}
              highlightedText={highlightedText}
              rowSelection={rowSelectionObject}
              filterProps={{
                value: filtersState,
                onChange: onFilterChange,
                inputs: filterInputs,
              }}
              searchProps={{
                onChange: onSearchChange,
                value: searchQuery,
                placeholder: searchPlaceholder,
              }}
              title={`${listPageName} Summary`}
              handleSubmit={handleSubmit}
              searchPlaceholder={searchPlaceholder}
              addIndexColumn={addIndexColumn}
              size={size}
            />
          </GridItem>
        </GridContainer>
        {formType === enums.FormContainerType.MODAL && isCreateModalOpen && (
          <FormContainer
            entityName={entityName}
            dataResource={dataResource}
            formStructure={formStructure?.create || formStructure}
            path={path}
            type={enums.FormContainerType.MODAL}
            create
            isOpen={isCreateModalOpen}
            onClose={handleCreateModalClose}
            defaultValues={defaultValues}
          />
        )}
        {formType === enums.FormContainerType.MODAL && isEditModalOpen && (
          <FormContainer
            entityName={entityName}
            dataResource={dataResource}
            formStructure={formStructure?.edit || formStructure}
            path={path}
            type={enums.FormContainerType.MODAL}
            edit
            isOpen={isEditModalOpen}
            onClose={handleEditModalClose}
            editItemId={editItemId}
            getName={getRowName}
            defaultValues={defaultValues}
          />
        )}
      </div>
    </DataListContext.Provider>
  );
}

DataList.defaultProps = {
  getRowName: (row) => (row?.name ? row.name : ""),
  hasRowSelection: false,
  getDisabledRows: (record) => {},
  addIndexColumn: true,
  submitSelectedRows: (selectedRows) => {},
  isSubmitLoading: false,
  formType: enums.FormContainerType.PAGE,
};

DataList.propTypes = {
  dataResource: PropTypes.object,
  getRowName: PropTypes.func,
  entityName: PropTypes.string,
  path: PropTypes.string,
  createUrl: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object),
  actions: PropTypes.object,
  editable: PropTypes.bool,
  resource: PropTypes.string,
  hasRowSelection: PropTypes.bool,
  getDisabledRows: PropTypes.func,
  filterInputs: PropTypes.array,
  listPageName: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  addIndexColumn: PropTypes.bool,
  submitSelectedRows: PropTypes.func,
  isSubmitLoading: PropTypes.bool,
  hasExport: PropTypes.bool,
  formType: PropTypes.oneOf([
    enums.FormContainerType.PAGE,
    enums.FormContainerType.MODAL,
  ]),
  formStructure: PropTypes.any,
  size: PropTypes.string,
  defaultValues: PropTypes.object,
};
