import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModal/CustomModal";
import FormField from "components/Forms/FormField/FormField";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import api from "services/api";
import styles from "./AddStockTransferModal.styles";
import translations from "constants/translations/index";
import productsForm from "views/ProductsManagement/productsForm";
import { displayTransferParty } from "utils/index";
import useAddStockTransferModal from "./AddStockTransferModal.hook";
import enums from "enums/index";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function AddStockTransferModal(props) {
  const {
    isOpen,
    onClose,
    successCallback,
    selectedTruck,
    driverShiftId,
    getData,
  } = props;

  const classes = useStyles();

  const { i18n } = useTranslation();

  const {
    isLoading,
    submitHandler,
    form,
    onError,
    handleSubmit,
  } = useAddStockTransferModal({
    onClose,
    successCallback,
    selectedTruck,
    driverShiftId,
    getData,
  });
  const transferType = form.watch("transferType");

  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ margin: 0 }}>
            <CardBody>
              <div className={classes.formHeader}>
                <React.Fragment>
                  <EditIcon className={classes.icon} /> Add Transfer
                </React.Fragment>
              </div>
              <form
                style={{ padding: 20, paddingBottom: 0 }}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(submitHandler, onError)();
                }}
                autoComplete="off"
              >
                <FormField
                  md={12}
                  name="product"
                  type="autocomplete"
                  label="Product"
                  options={{
                    getData: api.products.getAll,
                    getOptionLabel: (option) =>
                      option?.sapCode + " - " + option?.description,
                    getSubmitValue: (option) => option?.id,
                    addNew: true,
                    formStructure: productsForm,
                    dataResource: api.products,
                    freeSolo: true,
                    blurOnSelect: false,
                    loadOnFocus: true,
                  }}
                  form={form}
                />
                <FormField
                  md={12}
                  name="transferType"
                  type="autocomplete"
                  label="Transfer Type"
                  options={{
                    enum: "validStartOfShiftStockTransfersTypes",
                  }}
                  form={form}
                  onChange={(e) => {
                    form.setValue("from", null);
                    form.setValue("to", null);
                  }}
                />
                {(!transferType ||
                  (transferType &&
                    transferType.id !==
                      enums.ValidStartOfShiftStockTransfersTypes
                        .FROM_TRUCK_TO_WAREHOUSE)) && (
                  <FormField
                    md={12}
                    name="from"
                    type="autocomplete"
                    label="From Warehouse"
                    options={{
                      getData: api.warehouses.getAll,
                      getOptionLabel: (option) =>
                        option?.name ? option?.name : "",
                      getSubmitValue: (option) => option?.id,
                      filters: { type: enums.TransferPartyType.STORAGE },
                      blurOnSelect: false,
                      shouldThrottle: true,
                      disabled: !transferType,

                      disabledTooltipText: "Please select a type first",
                    }}
                    form={form}
                  />
                )}

                {(!transferType ||
                  (transferType &&
                    transferType.id !==
                      enums.ValidStartOfShiftStockTransfersTypes
                        .FROM_WAREHOUSE_TO_TRUCK)) && (
                  <FormField
                    md={12}
                    name="to"
                    type="autocomplete"
                    label="To Warehouse"
                    options={{
                      getData: api.warehouses.getAll,
                      getOptionLabel: (option) =>
                        option?.name ? option?.name : "",
                      getSubmitValue: (option) => option?.id,
                      filters: { type: enums.TransferPartyType.STORAGE },
                      blurOnSelect: false,
                      shouldThrottle: true,
                      disabled: !transferType,
                      loadOnFocus: true,
                      disabledTooltipText: "Please select a type first",
                    }}
                    form={form}
                  />
                )}

                <FormField
                  md={12}
                  name="amount"
                  type="number"
                  label="Amount"
                  form={form}
                />

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    color="primary"
                    type="submit"
                    loading={isLoading}
                    style={{ margin: "20px 0" }}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </CustomModal>
  );
}

AddStockTransferModal.defaultProps = {
  onClose: () => {},
};

AddStockTransferModal.propTypes = {
  onClose: PropTypes.func,
};
