import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import { toast } from "react-toastify";
import { firebaseConfig } from "../config/firebase.config";

const config = process.env.REACT_APP_ENV === "staging" ?
  firebaseConfig.staging :
  process.env.REACT_APP_ENV === "production" ?
    firebaseConfig.production :
    firebaseConfig.local;

const app = initializeApp(config);
const auth = getAuth(app);

const logInWithEmailAndPassword = async (
  email,
  password,
  rememberMe = true
) => {
  try {
    const persistence = rememberMe
      ? browserLocalPersistence
      : browserSessionPersistence;

    await setPersistence(auth, persistence);

    const response = await signInWithEmailAndPassword(auth, email, password);

    return { data: response.user };
  } catch (err) {
    toast.error(authErrors[err.code] || err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export { auth, logInWithEmailAndPassword, logout };

const authErrors = {
  "auth/invalid-email": "Invalid email address",
  "auth/user-disabled": "User disabled",
  "auth/user-not-found": "User not found",
  "auth/wrong-password": "Wrong password",
};
