import MapIcon from "@material-ui/icons/Map";
import citiesRoutes from "./citiesRoutes";
import countriesRoutes from "./countriesRoutes";
import districtsRoutes from "./districtsRoutes";
import zonesRoutes from "./zonesRoutes";
import enums from "enums/index";

export default {
  name: "Zoning",
  icon: MapIcon,
  layout: "/admin",
  collapse: true,
  state: "zoningCollapse",
  resource: enums.Resource.ZONING,
  superAdmin: true,
  views: [
    { ...countriesRoutes },
    { ...citiesRoutes },
    { ...districtsRoutes },
    { ...zonesRoutes }
  ],
};
