import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CheckPermission from "components/CheckPermission/CheckPermission";
import Button from "components/CustomButtons/Button.js";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import CustomTable from "components/CustomTable/CustomTable";
import enums from "enums/index";
import React from "react";
import OrderServiceModal from "./components/OrderServiceModal/OrderServiceModal";
import useOrderServices from "./OrderServices.hook";
import styles from "./OrderServices.styles";
import { Controller } from "react-hook-form";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function OrderServices({ form, order: orderProp }) {
  const classes = useStyles();

  const { control, errors } = form;

  const {
    order,
    isAssignModalOpen,
    isGetOrderLoading,
    editedService,
    orderServices,
    onAssignModalClose,
    handleAssignService,
    onRemoveModalClose,
    onAdd,
    onEdit,
  } = useOrderServices({ form, orderProp });

  const columns = React.useMemo(
    () => [
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Price",
        dataIndex: "price",
      },

      {
        title: "",
        render: (_, record) => {
          return (
            <div className={classes.actionsContainer}>
              <CheckPermission action={enums.Action.UPDATE}>
                <Button
                  justIcon
                  edit
                  color="white"
                  textColor="success"
                  onClick={() => handleAssignService(record)}
                />
              </CheckPermission>
              <CheckPermission action={enums.Action.DELETE}>
                <ConfirmationButton
                  name="this service"
                  entityName="Service"
                  onConfirm={() => {}}
                  onSuccess={() => onRemoveModalClose(record)}
                  buttonProps={{
                    justIcon: true,
                  }}
                  skipSuccessScreen
                />
              </CheckPermission>
            </div>
          );
        },
      },
    ],
    [order]
  );

  return (
    <Controller
      render={(props) => (
        <div className={classes.root}>
          <div className={classes.section}>
            {isAssignModalOpen && (
              <OrderServiceModal
                isOpen={isAssignModalOpen}
                onClose={onAssignModalClose}
                create={!editedService}
                edit={!!editedService}
                service={editedService}
                onAdd={onAdd}
                onEdit={onEdit}
                order={order}
              />
            )}
            <React.Fragment>
              <div className={classes.headerContainer}>
                <h3 className={classes.header}>Order Services</h3>
                <CheckPermission action={enums.Action.CREATE}>
                  <Button
                    onClick={() => handleAssignService()}
                    className={classes.addButton}
                    color="success"
                  >
                    <AddIcon className={classes.addIcon} />
                    Add Order Service
                  </Button>
                </CheckPermission>
              </div>
              <div className={classes.tableContainer}>
                <CustomTable
                  columns={columns}
                  data={orderServices}
                  loading={isGetOrderLoading}
                  addIndexColumn
                />
              </div>
            </React.Fragment>
            <div className={classes.error}>{errors?.services?.message}</div>
          </div>
        </div>
      )}
      name="services"
      control={control}
    />
  );
}
