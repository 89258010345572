import { makeStyles } from "@material-ui/core/styles";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import enums from "enums/index";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import styles from "./OrderStatusButtons.styles";
import React from "react";
import PlanOrderModal from "./PlanOrder/PlanOrderModal";
import Button from "components/CustomButtons/Button";
import CompleteOrderModal from "./CompleteOrder/CompleteOrderModal";
import AssignOrderModal from "./AssignOrder/AssignOrderModal";
import CancelOrderModal from "./CancelOrder/CancelOrderModal";
import LoseOrderModal from "./LoseOrder/LoseOrderModal";
import RateOrderModal from "./RateOrder/RateOrderModal";
import LeadOrderModal from "./LeadOrder/LeadOrderModal";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function OrderStatusButtons(props) {
  const classes = useStyles();

  const { i18n } = useTranslation();

  const { orderId, status, successCallback, order } = props;

  const [updateStatusRequest, isUpdateStatusLoading] = useHttpClient(true);
  const [cancelOrderRequest, isCancelOrderLoading] = useHttpClient(true);

  const [isPlanOrderModalOpen, setIsPlanOrderModalOpen] = useState(false);
  const [isCompleteOrderModalOpen, setIsCompleteOrderModalOpen] = useState(
    false
  );
  const [isAssignOrderModalOpen, setIsAssignOrderModalOpen] = useState(false);
  const [isCancelOrderModalOpen, setIsCancelOrderModalOpen] = useState(false);
  const [isLoseOrderModalOpen, setIsLoseOrderModalOpen] = useState(false);
  const [isRateOrderModalOpen, setIsRateOrderModalOpen] = useState(false);
  const [isLeadOrderModalOpen, setIsLeadOrderModalOpen] = useState(false);

  const updateOrderStatus = useCallback(
    async (orderId, newStatus, successCallback) => {
      let response;
      if (newStatus === enums.OrderStatus.CANCELLED)
        response = await cancelOrderRequest(
          api.orders.updateStatus(orderId, newStatus)
        );
      else
        response = await updateStatusRequest(
          api.orders.updateStatus(orderId, newStatus)
        );
      successCallback(response);
      toast.success("Order updated successfully");
    },
    [successCallback]
  );

  const getOrderStatusButtons = useCallback(
    (orderId, status) => {
      const cancelPlaceHolder = <div className={classes.cancelButton} />;
      const statusPlaceHolder = <div className={classes.statusButton} />;
      const cancelOrderButton = (
        <Button
          onClick={() => {
            setIsCancelOrderModalOpen(true);
          }}
          confirmButtonColor="primary"
          className={classes.cancelButton}
          color="danger"
          textColor="white"
        >
          Cancel
        </Button>
      );
      const rateOrderButton = (
        <Button
          onClick={() => {
            setIsRateOrderModalOpen(true);
          }}
          className={classes.cancelButton}
          color="info"
          textColor="white"
        >
          Rate
        </Button>
      );
      const planOrderButton = (
        <Button
          onClick={() => {
            setIsPlanOrderModalOpen(true);
          }}
          className={classes.statusButton}
          color="primary"
          textColor="white"
        >
          Plan
        </Button>
      );
      const leadOrderButton = (
        <Button
          onClick={() => {
            setIsLeadOrderModalOpen(true);
          }}
          className={classes.cancelButton}
          color="info"
          textColor="white"
        >
          Lead
        </Button>
      );
      return status === enums.OrderStatus.LEAD ? (
        <>
          {planOrderButton}
          <Button
            onClick={() => {
              setIsLoseOrderModalOpen(true);
            }}
            confirmButtonColor="primary"
            className={classes.cancelButton}
            color="black"
            textColor="white"
          >
            Lose
          </Button>
        </>
      ) : status === enums.OrderStatus.LOST ? (
        <>
          {planOrderButton}
          {leadOrderButton}
        </>
      ) : status === enums.OrderStatus.PLANNED ? (
        <>
          <Button
            onClick={() => {
              setIsAssignOrderModalOpen(true);
            }}
            className={classes.statusButton}
            color="secondary"
            textColor="white"
          >
            Assign
          </Button>
          {cancelOrderButton}
        </>
      ) : status === enums.OrderStatus.ASSIGNED ? (
        <>
          <ConfirmationButton
            key={Math.random()}
            confirmMessage="Are you sure you want to set order status as in progress?"
            successHeaderText="Submitted"
            successMessage="Order status has been updated to in progress successfully."
            headerMessage="Set as in progress"
            confirmButtonText="Confirm"
            confirmButtonColor="primary"
            onConfirm={() =>
              updateOrderStatus(
                orderId,
                enums.OrderStatus.IN_PROGRESS,
                successCallback
              )
            }
            loading={isUpdateStatusLoading}
            buttonProps={{
              className: classes.statusButton,
              color: "success",
              textColor: "white",
            }}
          >
            Set as in progress
          </ConfirmationButton>
          {cancelOrderButton}
        </>
      ) : status === enums.OrderStatus.IN_PROGRESS ? (
        <>
          <Button
            onClick={() => {
              setIsCompleteOrderModalOpen(true);
            }}
            className={classes.statusButton}
            color="primary"
            textColor="white"
          >
            Complete
          </Button>
          {cancelOrderButton}
        </>
      ) : status === enums.OrderStatus.COMPLETED ? (
        <>
          {statusPlaceHolder}
          {order?.orderRating === "No Rating"
            ? rateOrderButton
            : cancelPlaceHolder}
        </>
      ) : (
        <>
          {statusPlaceHolder}
          {cancelPlaceHolder}
        </>
      );
    },
    [order]
  );

  return (
    <React.Fragment>
      {isPlanOrderModalOpen && (
        <PlanOrderModal
          isOpen={isPlanOrderModalOpen}
          onClose={() => setIsPlanOrderModalOpen(false)}
          orderId={orderId}
          key={isPlanOrderModalOpen}
          successCallback={successCallback}
          order={order}
        />
      )}
      {isCompleteOrderModalOpen && (
        <CompleteOrderModal
          isOpen={isCompleteOrderModalOpen}
          onClose={() => setIsCompleteOrderModalOpen(false)}
          orderId={orderId}
          order={order}
          key={isCompleteOrderModalOpen}
          successCallback={successCallback}
        />
      )}
      {isAssignOrderModalOpen && (
        <AssignOrderModal
          isOpen={isAssignOrderModalOpen}
          onClose={() => setIsAssignOrderModalOpen(false)}
          orderId={orderId}
          order={order}
          key={isAssignOrderModalOpen}
          successCallback={successCallback}
        />
      )}
      {isCancelOrderModalOpen && (
        <CancelOrderModal
          isOpen={isCancelOrderModalOpen}
          onClose={() => setIsCancelOrderModalOpen(false)}
          key={isCancelOrderModalOpen}
          orderId={orderId}
          successCallback={successCallback}
        />
      )}
      {isLoseOrderModalOpen && (
        <LoseOrderModal
          isOpen={isLoseOrderModalOpen}
          onClose={() => setIsLoseOrderModalOpen(false)}
          key={isLoseOrderModalOpen}
          orderId={orderId}
          successCallback={successCallback}
        />
      )}
      {isRateOrderModalOpen && (
        <RateOrderModal
          isOpen={isRateOrderModalOpen}
          onClose={() => setIsRateOrderModalOpen(false)}
          key={isRateOrderModalOpen}
          orderId={orderId}
          successCallback={successCallback}
        />
      )}
      {isLeadOrderModalOpen && (
        <LeadOrderModal
          isOpen={isLeadOrderModalOpen}
          onClose={() => setIsLeadOrderModalOpen(false)}
          key={isLeadOrderModalOpen}
          orderId={orderId}
          successCallback={successCallback}
        />
      )}
      {getOrderStatusButtons(orderId, status)}
    </React.Fragment>
  );
}

OrderStatusButtons.propTypes = {
  orderId: PropTypes.string,
  status: PropTypes.string,
  successCallback: PropTypes.func,
};
