import * as yup from "yup";

export default (amount) => {
  return yup.object().shape({
    serialNumbers: yup
      .string()
      .required("Required")
      .test(
        "isValidSerialNumbers",
        `Please enter ${amount} serial number(s) `,
        (value) => {
          if (!value) return false; // Empty string is not valid

          const serialNumbers = value.split(",").map((num) => num.trim());

          return serialNumbers.length === amount;
        }
      ),
    // amount: yup.string().required("Required"),
  });
};
