import { makeStyles } from "@material-ui/core/styles";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import enums from "enums/index";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import styles from "./StartOfShiftReportButtons.styles";
import React from "react";
import Button from "components/CustomButtons/Button";
//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function StartOfShiftReportButtons(props) {
  const classes = useStyles();

  const { successCallback, driverShiftId } = props;

  const [
    sendCreateShiftReportRequest,
    isCreateShiftReportLoading,
  ] = useHttpClient(true);

  const createStartOfShiftReport = async (driverShiftId) => {
    let updatedInvoice;
    // setLoading(true);
    updatedInvoice = await sendCreateShiftReportRequest(
      api.driverShifts.generateStartOfShiftReport(driverShiftId)
    );
    // setLoading(true);
    successCallback();
    // setLoading(false);
    toast.success("Start of shift report has been generated successfully");
  };
  const getStartOfShiftReportButtons = useCallback(() => {
    const cancelPlaceHolder = <div className={classes.cancelButton} />;
    const statusPlaceHolder = <div className={classes.statusButton} />;

    const approveButton = (
      // Button component
      <Button
        onClick={async () => {
          await createStartOfShiftReport(driverShiftId);
        }}
        confirmButtonColor="primary"
        className={classes.statusButton}
        color="primary"
        textColor="white"
        loading={isCreateShiftReportLoading}
      >
        Regenerate Report
      </Button>
    );
    return (
      <>
        {approveButton}
        {/* {cancelButton} */}
      </>
    );
  }, [createStartOfShiftReport, driverShiftId, isCreateShiftReportLoading]);

  return <React.Fragment>{getStartOfShiftReportButtons()}</React.Fragment>;
}

StartOfShiftReportButtons.propTypes = {
  // successCallback: PropTypes.func,
};
