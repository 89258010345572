import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LoginPage from "views/LoginPage/LoginPage";
import Profile from "views/Profile/Profile";
import AccessManagement from "./entities/AccessManagement";
import CarTypes from "./entities/CarTypes/index";
import ClientsManagement from "./entities/ClientsManagement/index";
import Orders from "./entities/Orders/index";
import ProductsManagement from "./entities/ProductsManagement/index";
import Zoning from "./entities/Zoning/index";
import Settings from "./entities/Settings/index";
import TrucksManagement from "./entities/StockManagement/index";
import DriverShiftManagement from "./entities/DriverShiftManagement/index";

var routes = [
  {
    hidden: true,
    path: "/login",
    name: "Login Page",
    rtlName: "هعذاتسجيل الدخول",
    rtlMini: "هعذا",
    component: LoginPage,
    layout: "/auth",
  },
  {
    path: `/profile`,
    name: "Profile",
    sidebarName: "Profile",
    icon: AccountCircleIcon,
    component: Profile,
    action: "read",
    layout: "/admin",
  },
  { ...AccessManagement },
  { ...Settings },
  { ...CarTypes },
  { ...ProductsManagement },
  { ...ClientsManagement },
  { ...Zoning },
  { ...TrucksManagement },
  { ...Orders },
  { ...DriverShiftManagement },
];

export default routes;
