import enums from "enums/index";
import qs from "qs";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";

//------------------------------------------------------------------------------

export default function useInvoicesAndReportsList(props) {
  const { filterInputs, driverShift } = props;
  const history = useHistory();
  const match = useRouteMatch();

  const [currentReportsPage, setCurrentReportsPage] = React.useState(1);
  const [reportsList, setReportsList] = React.useState({
    count: 0,
    rows: [],
  });

  const [listData, setListData] = React.useState({ count: 0, rows: [] });

  const [itemsPerPage, setItemsPerPage] = React.useState(10);
  const [highlightedText, setHighlightedText] = React.useState("");
  const [sendGetReportsRequest, isGetReportsRequestLoading] = useHttpClient();

  const getReports = React.useCallback(
    async (page = currentReportsPage, rowsPerPage = itemsPerPage) => {
      try {
        const response = await sendGetReportsRequest(
          api.driverShifts.getReports(driverShift?.id)
        );
        setReportsList({
          count: response.count,
          rows: response.data,
        });
      } catch (err) {
        toast.error("An error has occurred");
      }
    },
    [sendGetReportsRequest, setReportsList, currentReportsPage, itemsPerPage]
  );

  React.useEffect(() => {
    (async () => {
      try {
        const settings = JSON.parse(localStorage.getItem("settings"));
        const rowsPerPage =
          settings && settings[match.path] && settings[match.path].rowsPerPage
            ? settings[match.path].rowsPerPage
            : 10;

        setItemsPerPage(rowsPerPage);

        let { page, search, filters } = qs.parse(
          window.location.search?.split("?")[1]
        );
        setHighlightedText(search ? search : "");

        setCurrentReportsPage(1);
        await getReports(1, rowsPerPage, search);
      } catch (err) {
        toast.error("An error has occurred");
      }
    })();
  }, []);
  const handleChangeReports = async (pagination) => {
    setCurrentReportsPage(pagination.current);
    setItemsPerPage(pagination.pageSize);

    await getReports(
      pagination.current,
      pagination.pageSize,
      undefined,
      undefined
    );
  };
  const updateInvoiceState = React.useCallback(
    (updatedInvoice) => {
      setReportsList((prevState) => ({
        rows: prevState.rows.map((item) =>
          item.id === updatedInvoice.id
            ? {
                ...item,
                ...updatedInvoice,
              }
            : item
        ),
        count: prevState.count,
      }));
    },
    [setReportsList]
  );

  return {
    currentReportsPage,
    reportsList,
    setReportsList,
    itemsPerPage,
    highlightedText,
    isGetReportsRequestLoading,
    getReports,
    handleChangeReports,
    updateInvoiceState,
    listData,
  };
}
