import enums from "enums/index";
import React, { useMemo, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import DriverShiftDetailsFormValidation from "./DriverShiftDetailsForm.validation";

//-------------------------------------------------------------------------------------

export const useDriverDetailsForm = ({ path: pathProp, setActiveStep }) => {
  const [selectedTruck, setSelectedTruck] = React.useState(null);
  const [driverShift, setDriverShift] = React.useState(null);
  const dataResource = React.useMemo(() => api.driverShifts, []);
  const [
    sendCreateShiftReportRequest,
    isCreateShiftReportLoading,
  ] = useHttpClient();

  const [sendGetRequest, isGetOrderLoading] = useHttpClient();
  const [sendRequest, isLoading] = useHttpClient(true);
  const history = useHistory();

  const resolver = useYupValidationResolver(DriverShiftDetailsFormValidation());
  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const { setValue, getValues, watch, handleSubmit } = form;
  const chosenTruck = watch("assignedTruck");

  const createStartOfShiftReport = async (driverShiftId) => {
    try {
      await sendCreateShiftReportRequest(
        api.driverShifts.generateStartOfShiftReport(driverShiftId)
      );
      toast.success("Start of shift report has been generated successfully");
    } catch (error) {
      console.log(error);
    }
  };

  const submitHandler = async () => {
    let values = getValues();

    values = {
      ...values,
      assignedTruckId: values.assignedTruck?.id,
      assignedDriverId: values.assignedDriver?.id,
      startedAt: new Date().toISOString(),
    };
    setSelectedTruck(values.assignedTruck);
    delete values.assignedTruck;
    delete values.assignedDriver;
    try {
      const createdShift = await sendRequest(api.driverShifts.create(values));
      setDriverShift(createdShift);
      toast.success(`Shift has been created successfully`);
      setActiveStep(1);
    } catch (error) {
      console.log(error);
    }
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    form,
    submitHandler,
    isLoading,
    onError,
    handleSubmit,
    selectedTruck,
    driverShift,
    createStartOfShiftReport,
    isCreateShiftReportLoading,
  };
};
