import { makeStyles } from "@material-ui/core/styles";
import CustomTable from "components/CustomTable/CustomTable";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import translations from "constants/translations/index";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./OrderTransfersApprovalList.styles";
import CheckPermission from "components/CheckPermission/CheckPermission";
import useOrderTransferApprovalList from "./OrderTransfersApprovalList.hook";
import { displayTransferParty } from "utils/index";
import Highlighter from "react-highlight-words";
import { getDateString } from "utils/index";
import CustomerInfoModal from "views/Orders/pages/OrdersList/CustomerInfoModal/CustomerInfoModal";
import enums from "enums/index";
import ApproveOrderTransfersModal from "./components/OrderTransferApprovalModal/ApproveOrderTransferModal";
import Button from "components/CustomButtons/Button.js";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function OrderTransfersApprovalList(props) {
  const classes = useStyles();

  const { i18n } = useTranslation();

  const { resource, orderId } = props;

  const filterInputs = useMemo(
    () => [
      {
        name: "status",
        label: "Status",
        type: "select",
        options: {
          data: Object.keys(translations[i18n.language].orderStatus).map(
            (key) => ({
              value: key,
              name: translations["en"].orderStatus[key],
            })
          ),
          addAllChoice: true,
        },
      },
    ],
    []
  );

  const {
    currentPage,
    listData,
    searchQuery,
    filtersState,
    itemsPerPage,
    highlightedText,
    isGetLoading,
    onSearchChange,
    onFilterChange,
    handleChange,
    handleSubmit,
    setIsApproveOrderTransferModalOpen,
    isApproveOrderTransferModalOpen,
    setSelectedOrder,
    selectedOrder,
    updateOrderState,
  } = useOrderTransferApprovalList({
    resource,
    filterInputs,
  });

  const columns = React.useMemo(
    () => [
      {
        title: "Order No.",
        dataIndex: "orderNo",
        width: 110,
        fixed: "left",
        canBeHighlighted: true,
        render: (data) => `#${data}`,
      },
      {
        title: "Customer",
        dataIndex: ["car", "owner", "name"],
        width: 150,
        render: (_, record) =>
          record.car?.owner ? (
            <Highlighter
              highlightClassName={classes.highlightedText}
              searchWords={[highlightedText]}
              autoEscape={true}
              textToHighlight={
                record.car?.owner?.name +
                " - " +
                record.car?.owner?.primaryPhoneNumber
              }
            />
          ) : (
            "-"
          ),
      },
      {
        title: "Driver",
        dataIndex: "assignedDriver",
        render: (_, record) =>
          record.assignedDriver ? record.assignedDriver?.name : "-",
        width: 150,
      },
      {
        title: "Truck",
        dataIndex: "assignedTruck",
        render: (_, record) =>
          record.assignedTruck ? record.assignedTruck?.truckNumber : "-",
        width: 150,
      },
      {
        title: "Status",
        dataIndex: "status",
        getData: (data) => {
          if (data) return translations["en"].orderStatus[data];
          else return "-";
        },
        width: 120,
      },
      {
        title: "",
        render: (_, record) => {
          return record.status === enums.OrderStatus.PENDING_STOCK_APPROVAL ? (
            <Button
              color="success"
              onClick={() => {
                setSelectedOrder(record);
                setIsApproveOrderTransferModalOpen(true);
              }}
              className={classes.actionButton}
            >
              Take action
            </Button>
          ) : (
            <Button
              color="primary"
              onClick={() => {
                setSelectedOrder(record);
                setIsApproveOrderTransferModalOpen(true);
              }}
              className={classes.actionButton}
            >
              View
            </Button>
          );
        },
        width: 120,
      },
    ],
    [listData]
  );

  return (
    <div>
      {isApproveOrderTransferModalOpen && (
        <ApproveOrderTransfersModal
          isOpen={isApproveOrderTransferModalOpen}
          onClose={() => setIsApproveOrderTransferModalOpen(false)}
          orderId={selectedOrder?.id}
          successCallback={updateOrderState}
          orderStatus={selectedOrder?.status}
        />
      )}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTable
            columns={columns}
            data={listData.rows}
            onChange={handleChange}
            pagination={{
              total: listData.count,
              current: currentPage,
              pageSize: itemsPerPage,
              position: ["topRight"],
              pageSizeOptions: [10, 20, 50],
            }}
            loading={isGetLoading}
            highlightedText={highlightedText}
            filterProps={{
              value: filtersState,
              onChange: onFilterChange,
              inputs: filterInputs,
            }}
            searchProps={{
              onChange: onSearchChange,
              value: searchQuery,
              placeholder: "Search",
            }}
            handleSubmit={handleSubmit}
            title="Order Transfers Requests Summary"
            addIndexColumn
            size="middle"
            fixIndexColumn
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

OrderTransfersApprovalList.propTypes = {
  resource: PropTypes.string,
};
