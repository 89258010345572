import api from "services/api";
import * as yup from "yup";
import countriesForm from "./countriesForm";

export default [
  {
    column: 1,
    fields: [
      {
        name: "nameEn",
        type: "text",
        label: "Name (English)",
        validation: yup.mixed().required("Required"),
      },
      {
        name: "nameAr",
        type: "text",
        label: "Name (Arabic)",
        validation: yup.mixed().required("Required"),
      },
      {
        name: "countryId",
        fetchedName: "country",
        type: "autocomplete",
        label: "Country",
        options: {
          getData: api.countries.getAll,
          getOptionLabel: (option) => option.name,
          getSubmitValue: (option) => option?.id,
          addNew: true,
          formStructure: countriesForm,
          freeSolo: true,
          loadOnFocus: true,
          dataResource: api.countries,
          blurOnSelect: false,
        },
        validation: yup.mixed().required("Required"),
      },
    ],
  },
];
