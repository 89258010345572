import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import driversRoutes from "./driversRoutes";
import trucksRoutes from "./trucksRoutes";
import warehousesRoutes from "./warehousesRoutes";
import stockTransfersApprovalsRoutes from "./stockTransfersApprovalsRoutes";
import orderTransferApprovalsRoutes from "./orderTransferApprovalsRoutes";
import warrantiesRoutes from "./warrantiesRoutes";
import invoicesRoutes from "./invoicesRoutes";
import stockStatsRoutes from "./stockStatsRoutes";
import stockTransfersSheetsRoutes from "./stockTransfersSheetsRoutes";
import warrantiesSheetsRoutes from "./warrantiesSheetsRoutes";
import enums from "enums/index";

export default {
  name: "Stock Management",
  icon: LocalShippingIcon,
  layout: "/admin",
  collapse: true,
  state: "trucksManagementCollapse",
  superAdmin: true,
  resource: [
    enums.Resource.STOCK_MANAGEMENT,
    enums.Resource.STOCK_MANAGEMENT_DRIVERS,
    enums.Resource.STOCK_MANAGEMENT_TRUCKS,
    enums.Resource.STOCK_MANAGEMENT_WAREHOUSES,
    enums.Resource.STOCK_MANAGEMENT_STOCK_STATISTICS,
    enums.Resource.STOCK_MANAGEMENT_STOCK_TRANSFERS_APPROVAL,
    enums.Resource.STOCK_MANAGEMENT_ORDER_TRANSFERS_APPROVAL,
    enums.Resource.STOCK_MANAGEMENT_WARRANTIES,
    enums.Resource.STOCK_MANAGEMENT_INVOICES,
    enums.Resource.STOCK_MANAGEMENT_STOCK_TRANSFERS_SHEETS,
    enums.Resource.STOCK_MANAGEMENT_WARRANTIES_SHEETS,
  ],
  views: [
    driversRoutes,
    trucksRoutes,
    warehousesRoutes,
    stockStatsRoutes,
    stockTransfersApprovalsRoutes,
    orderTransferApprovalsRoutes,
    warrantiesRoutes,
    invoicesRoutes,
    stockTransfersSheetsRoutes,
    warrantiesSheetsRoutes,
  ],
};
