import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import { setPageSettingsProp } from "utils";
import style from "./collapseContainerStyle";

const useStyles = makeStyles(style);

export default function CollapseContainer(props) {
  const {
    name,
    titleComponent,
    children,
    startCollapsed,
    headerClassName,
    maxHeight,
    onCollapse,
    onExpand,
    ignoreLocalStorage,
  } = props;
  const classes = useStyles();
  const [collapse, setCollapse] = React.useState({
    value: true,
    touched: false,
  });

  const match = useRouteMatch();

  const expandIconClasses = classNames({
    [classes.expandIcon]: true,
    [classes.iconMore]: collapse.value,
    [classes.iconLess]: !collapse.value,
  });

  const headerClasses = classNames({
    [classes.header]: true,
    [headerClassName]: true,
  });

  React.useEffect(() => {
    if (name && !ignoreLocalStorage) {
      const settings = JSON.parse(localStorage.getItem("settings"));
      const isCollapsed =
        settings &&
        settings[match.path] &&
        settings[match.path][name] &&
        settings[match.path][name].isCollapsed;
      setCollapse(
        isCollapsed === undefined || isCollapsed === null
          ? { value: startCollapsed, touched: false }
          : { value: isCollapsed, touched: false }
      );
    } else {
      setCollapse({ value: startCollapsed, touched: false });
    }
  }, [startCollapsed]);

  React.useEffect(() => {
    if (name && collapse.touched && !ignoreLocalStorage) {
      setPageSettingsProp(`${match.path}.${name}.isCollapsed`, collapse.value);
    }
  }, [collapse.value]);

  return (
    <div
      className={classes.root}
      style={maxHeight ? { maxHeight: maxHeight, overflowY: "scroll" } : {}}
    >
      <div
        onClick={() => {
          setCollapse({ value: !collapse.value, touched: true });
          if (collapse.value) {
            if (onExpand) onExpand();
          } else {
            if (onCollapse) onCollapse();
          }
        }}
        className={headerClasses}
      >
        {titleComponent}
        <ExpandMoreIcon className={expandIconClasses} />
      </div>
      <div className={classes.collapseContainer}>
        <Collapse in={!collapse.value}>{children}</Collapse>
      </div>
    </div>
  );
}

CollapseContainer.defaultProps = {
  startCollapsed: true,
  ignoreLocalStorage: false,
};

CollapseContainer.propTypes = {
  name: PropTypes.string,
  titleComponent: PropTypes.node,
  startCollapsed: PropTypes.bool,
  ignoreLocalStorage: PropTypes.bool,
};
