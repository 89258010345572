import * as yup from "yup";

//----------------------------------------------------------

export default () => {
  return yup.object().shape({
    name: yup.mixed().required("Required"),
    address: yup.mixed().required("Required"),
    warehouseType: yup.mixed().required("Required"),
  });
};
