import enums from "enums/index";
import React from "react";
import CarsList from "views/ClientsManagement/Cars/CarsList/CarsList";

// appear in breadcrumbs and page headers
const listPageName = "Cars";

const layout = "/admin";

const path = "cars";

const resource = enums.Resource.CLIENTS_MANAGEMENT;

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: "drive_eta",
  render: () => <CarsList />,
  action: "read",
  layout: layout,
  superAdmin: true,
  resource,
};
