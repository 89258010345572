import React from "react";
import OrderTransfersApprovalList from "views/StockManagement/OrderTransferApproval/pages/OrderTransferApprovalList/OrderTransfersApprovalList";
import enums from "enums/index";

// appear in breadcrumbs and page headers
const listPageName = "Order Transfers Approval";

const layout = "/admin";

const path = "order-transfers";

const resource = [
  enums.Resource.STOCK_MANAGEMENT,
  enums.Resource.STOCK_MANAGEMENT_ORDER_TRANSFERS_APPROVAL,
];
export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: "grading",
  render: () => <OrderTransfersApprovalList />,
  action: "read",
  resource,
  layout: layout,
  randomKey: true,
};
