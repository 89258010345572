import { makeStyles } from "@material-ui/core/styles";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CheckPermission from "components/CheckPermission/CheckPermission";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Loading from "components/Loading/Loading";
import Tabs from "components/Tabs/Tabs";
import { driverShiftStatusColors, orderStatusColors } from "constants/colors";
import translations from "constants/translations";
import enums from "enums/index";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDriverShiftView } from "./DriverShiftView.hook";
import styles from "./DriverShiftView.styles";
import DriverShiftButtons from "../DriverShiftsList/components/DriverShiftButtons/DriverShiftButtons";
import DriverShiftDetails from "views/DriverShiftManagement/pages/DriverShiftView/components/Tabs/DriverShiftDetails/DriverShiftDetails";
import StockTransfers from "views/DriverShiftManagement/pages/DriverShiftView/components/Tabs/StockTransfers/StockTransfers";
import { getDateString } from "utils/index";
import TruckInventory from "views/DriverShiftManagement/pages/DriverShiftView/components/Tabs/TruckInventory/TruckInventory";
import Reports from "./components/Tabs/ReportsAndInvoices/ReportsAndInvoices";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function DriverShiftView({ path }) {
  const classes = useStyles();

  const {
    driverShift,
    isGetShiftLoading,
    currentTab,
    setCurrentTab,
    getLatestShift,
    updateShiftState,
  } = useDriverShiftView({ path });

  const { i18n } = useTranslation();

  const tabs = useMemo(
    () => [
      {
        name: "Details",
        component: <DriverShiftDetails driverShift={driverShift} />,
      },
      {
        name: "Transfers",
        component: (
          <StockTransfers
            assignedTruck={driverShift?.assignedTruck}
            title={`Stock transfers for ${driverShift?.assignedTruck?.truckNumber}`}
            driverShift={driverShift}
          />
        ),
      },
      {
        name: "Truck Inventory",
        component: (
          <TruckInventory
            driverShift={driverShift}
            assignedTruck={driverShift?.assignedTruck}
          />
        ),
      },
      {
        name: "Reports",
        component: (
          <Reports
            driverShift={driverShift}
            assignedTruck={driverShift?.assignedTruck}
          />
        ),
      },
    ],
    [driverShift]
  );

  return (
    <div>
      <div className={classes.upperContainer}>
        <Breadcrumbs />
        <CheckPermission action={enums.Action.CREATE}>
          <Link to={`/admin/${path}/create`}>
            <Button create color="white" />
          </Link>
        </CheckPermission>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card raised>
            <CardBody>
              <Loading loading={isGetShiftLoading} style={{ height: "70vh" }}>
                {driverShift ? (
                  <React.Fragment>
                    <div className={classes.pageHeader}>
                      <div className={classes.infoContainer}>
                        <div className={classes.infoWithIcon}>
                          <AccessAlarmIcon className={classes.personIcon} />
                          <div className={classes.headerInfoContainer}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {`Shift #${driverShift?.id}`} - &nbsp;
                              <div
                                style={{
                                  color:
                                    driverShiftStatusColors[
                                      driverShift?.status
                                    ],
                                }}
                              >
                                {
                                  translations[i18n.language].driverShiftStatus[
                                    driverShift?.status
                                  ]
                                }
                              </div>
                            </div>

                            <div className={classes.subtitle}>
                              {driverShift?.assignedDriver?.name
                                ? `Shift: ${driverShift?.assignedDriver?.name} - ${driverShift?.assignedTruck?.truckNumber} `
                                : ""}
                            </div>
                            <div className={classes.subtitle}>
                              {driverShift?.startedAt
                                ? `Date: ${getDateString(
                                    driverShift?.startedAt
                                  )}`
                                : "Date: N/A"}
                            </div>

                            <div className={classes.subtitle}></div>
                          </div>
                        </div>
                        <div className={classes.buttonsContainer}>
                          <CheckPermission>
                            <DriverShiftButtons
                              driverShiftId={driverShift?.id}
                              driverShiftStatus={driverShift?.status}
                              driverShift={driverShift}
                              successCallback={updateShiftState}
                            />
                          </CheckPermission>
                        </div>
                      </div>
                    </div>
                    <Tabs
                      buttonsContainerClasses={classes.tabsContainer}
                      tabs={tabs}
                      value={currentTab}
                      onChange={setCurrentTab}
                    />
                  </React.Fragment>
                ) : (
                  <div>No available data</div>
                )}
              </Loading>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
