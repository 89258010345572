import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import driverShiftRoutes from "./driverShiftRoutes";
import enums from "enums/index";

export default {
  name: "Shift Management",
  icon: AccessAlarmIcon,
  layout: "/admin",
  collapse: true,
  state: "driverShiftManagementCollapse",
  resource: enums.Resource.DRIVER_SHIFT_MANAGEMENT,
  views: [{ ...driverShiftRoutes }],
};
