import DataList from "components/DataList/DataList";
import FormContainer from "components/Forms/FormContainer/FormContainer";
import React from "react";
import api from "services/api";
import enums from "enums/index";
import customersForm from "views/ClientsManagement/customersForm";
import { getDateString } from "utils/index";
import translations from "constants/translations/index";

// appear in breadcrumbs and page headers
const listPageName = "Customers";
const createPageName = "Create Customer";
const editPageName = "Edit Customer";

// appears on alerts; example: Entity created successfully
const entityName = "Customer";

const layout = "/admin";

const path = "customers";

const formStructure = customersForm;

const dataResource = api.customers;

const resource = enums.Resource.CLIENTS_MANAGEMENT;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    canBeHighlighted: true,
  },
  {
    title: "Email",
    dataIndex: "email",
    canBeHighlighted: true,
  },
  {
    title: "Primary Phone",
    dataIndex: "primaryPhoneNumber",
    canBeHighlighted: true,
  },
  {
    title: "Secondary Phone",
    dataIndex: "secondaryPhoneNumber",
    canBeHighlighted: true,
  },
  {
    title: "Card Code",
    dataIndex: "cardCode",
  },
  {
    title: "Date Joined",
    dataIndex: "dateJoined",
    getData: (data) => {
      return data ? getDateString(data) : "-";
    },
  },
  {
    dataIndex: "gender",
    title: "Gender",
    getData: (data) => {
      if (data) return translations["en"].gender[data];
      else return "-";
    },
  },
  {
    dataIndex: ["defaultDistrict", "name"],
    title: "Default District",
  },
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: "group",
  render: () => (
    <DataList
      entityName={entityName}
      path={path}
      columns={columns}
      actions={{ edit: true, delete: true }}
      dataResource={dataResource}
      resource={resource}
      listPageName={listPageName}
      formStructure={formStructure}
      formType={enums.FormContainerType.MODAL}
      defaultValues={{
        dateJoined: new Date().toISOString().split("T")[0],
      }}
    />
  ),
  action: "read",
  layout: layout,
  // randomKey: true,
  children: true,
  superAdmin: true,
  resource,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          create
        />
      ),
      layout: layout,
      randomKey: true,
      action: "create",
      superAdmin: true,
      resource,
    },
    {
      path: `/${path}/:id/edit`,
      name: editPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          edit
        />
      ),
      layout: layout,
      randomKey: true,
      action: "update",
      superAdmin: true,
      resource,
    },
  ],
};
