import { makeStyles } from "@material-ui/core/styles";
import CustomTable from "components/CustomTable/CustomTable";
import translations from "constants/translations/index";
import enums from "enums/index";
import React from "react";
import { useTranslation } from "react-i18next";
import { displayTransferParty } from "utils/index";
import styles from "./OrderTransfers.styles";
import Loading from "components/Loading/Loading";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function OrderTransfers({
  orderTransfers,
  orderWarranties,
  isLoading,
}) {
  const classes = useStyles();

  const { i18n } = useTranslation();

  const [newOrderTransfers, setNewOrderTransfers] = React.useState([]);
  const [scrapOrderTransfers, setScrapOrderTransfers] = React.useState([]);

  React.useEffect(() => {
    if (orderTransfers && orderTransfers.length > 0) {
      setNewOrderTransfers(
        orderTransfers.filter(
          (transfer) => transfer?.product?.type === enums.ProductType.NEW
        )
      );
      setScrapOrderTransfers(
        orderTransfers.filter(
          (transfer) => transfer?.product?.type === enums.ProductType.SCRAP
        )
      );
    }
  }, [orderTransfers]);

  const columns = [
    {
      dataIndex: ["product", "sapCode"],
      title: "SAP Code",
      canBeHighlighted: true,
    },
    {
      title: "From",
      dataIndex: ["from"],
      render: (data) => displayTransferParty(data),
    },
    {
      title: "To",
      dataIndex: ["to"],
      render: (data) => displayTransferParty(data),
    },
    {
      title: "Status",
      dataIndex: ["status"],
      getData: (data) =>
        translations[i18n.language].stockItemTransferStatus[data],
    },

    {
      dataIndex: ["product", "size", "name"],
      title: "Battery Size",
    },
    {
      dataIndex: ["product", "type"],
      title: "Product Type",
    },
    {
      dataIndex: ["amount"],
      title: "Amount",
    },
  ];

  return (
    <Loading
      loading={isLoading}
      style={{
        minHeight: "220px",
      }}
    >
      <div className={classes.root}>
        {newOrderTransfers && newOrderTransfers.length > 0 && (
          <React.Fragment>
            <h3 className={classes.header}>Items delivered to Customer</h3>
            <div className={classes.tableContainer}>
              <CustomTable
                columns={columns}
                data={newOrderTransfers}
                loading={isLoading}
              />
            </div>

            {orderWarranties?.length ? (
              <React.Fragment>
                <h3 className={classes.header}>Warranties to be activated:</h3>
                <ul>
                  {orderWarranties.map((orderWarranty) => (
                    <li style={{ fontSize: "16px", marginBottom: "8px" }}>
                      {orderWarranty.serialNumber}
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            ) : null}
          </React.Fragment>
        )}

        {scrapOrderTransfers && scrapOrderTransfers.length > 0 && (
          <React.Fragment>
            <h3 className={classes.header}>Items Purchased From Customer</h3>
            <div className={classes.tableContainer}>
              <CustomTable
                columns={columns}
                data={scrapOrderTransfers}
                loading={isLoading}
              />
            </div>
          </React.Fragment>
        )}

        {newOrderTransfers &&
          newOrderTransfers.length <= 0 &&
          scrapOrderTransfers &&
          scrapOrderTransfers.length <= 0 && (
            <p className={classes.noTransfersMessage}>
              No transfer requests to display
            </p>
          )}
      </div>
    </Loading>
  );
}
