import enums from "enums/index";
import * as yup from "yup";
import api from "services/api";
import trucksForm from "views/TrucksManagement/trucksForm";

export default [
  {
    column: 1,
    fields: [
      {
        name: "name",
        type: "text",
        label: "Name",
        validation: yup.mixed().required("Required"),
      },
      {
        name: "phone",
        type: "text",
        label: "Phone",
        validation: yup.mixed().required("Required"),
      },
      {
        name: "nationalId",
        type: "text",
        label: "National ID",
        validation: yup.mixed().required("Required"),
      },
      {
        name: "drivingLicenseDocumentUrl",
        type: "dropzone",
        label: "License Document",
        options: { maxFiles: 1 },
      },

      {
        name: "drivingLicenseExpirationDate",
        type: "date",
        label: "License Expiration Date",
      },
      {
        name: "joiningDate",
        type: "date",
        label: "Joining Date",
      },
      {
        name: "leaveDate",
        type: "date",
        label: "Leave Date",
      },
      {
        name: "status",
        type: "select",
        label: "Status",
        options: {
          enum: "driverStatus",
        },
        validation: yup.mixed().required("Required"),
      },
      {
        name: "defaultTruckId",
        fetchedName: "defaultTruck",
        type: "autocomplete",
        label: "Default Truck",
        options: {
          getData: api.trucks.getAll,
          getOptionLabel: (option) => option?.truckNumber,
          getSubmitValue: (option) => option?.id,
          addNew: true,
          formStructure: trucksForm,
          freeSolo: true,
          dataResource: api.trucks,
          blurOnSelect: false,
          loadOnFocus: true,
        },
      },
    ],
  },
];
