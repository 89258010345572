import { makeStyles } from "@material-ui/core/styles";
import CustomTable from "components/CustomTable/CustomTable";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import translations from "constants/translations/index";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./StockTransfers.styles";
import CheckPermission from "components/CheckPermission/CheckPermission";
import useStockTransfers from "./StockTransfers.hook";
import { displayTransferParty } from "utils/index";
import Button from "components/CustomButtons/Button";
import AddStockTransferModal from "../../../../CreateShiftPage/components/DriverShiftDetailsForm/components/ViewingTruckInventoryAndAddingStock/components/AddStockTransferModal/AddStockTransferModal";
import enums from "enums/index";
import { is } from "immutable";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function StockTransfers(props) {
  const classes = useStyles();

  const { i18n } = useTranslation();

  const { resource, assignedTruck, title, driverShift } = props;

  const [
    isAddStockTransferModalOpen,
    setIsAddStockTransferModalOpen,
  ] = React.useState(false);

  const {
    currentEndOfShiftTransfersPage,
    currentStartOfShiftTransfersPage,
    startOfShiftTransfersList,
    endOfShiftTransfersList,
    setStartOfShiftTransfersList,
    setEndOfShiftTransfersList,
    itemsPerPage,
    highlightedText,
    isGetStartOfShiftTransfersLoading,
    isGetEndOfShiftTransfersLoading,
    getEndOfShiftStockTransfers,
    getStartOfShiftTransfers,
    handleChangeEndOfShiftTransfers,
    handleChangeStartOfShiftTransfers,
  } = useStockTransfers({
    resource,
    assignedTruck,
    driverShift,
  });

  const columns = React.useMemo(
    () => [
      {
        dataIndex: ["product", "sapCode"],
        title: "SAP Code",
        canBeHighlighted: true,
      },

      {
        dataIndex: ["product", "description"],
        title: "Item Description",
      },

      {
        title: "From",
        dataIndex: ["from"],
        render: (data) => displayTransferParty(data),
      },

      {
        title: "To",
        dataIndex: ["to"],
        render: (data) => displayTransferParty(data),
      },
      {
        title: "Status",
        dataIndex: ["status"],
        getData: (data) =>
          translations[i18n.language].stockItemTransferStatus[data],
      },

      {
        dataIndex: ["product", "size", "name"],
        title: "Battery Size",
      },
      {
        dataIndex: ["product", "type"],
        title: "Battery Type",
      },
      {
        dataIndex: ["amount"],
        title: "Amount",
      },
    ],
    [endOfShiftTransfersList, startOfShiftTransfersList]
  );

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        {isAddStockTransferModalOpen && (
          <AddStockTransferModal
            selectedTruck={assignedTruck}
            driverShiftId={driverShift?.id}
            isOpen={isAddStockTransferModalOpen}
            onClose={() => {
              setIsAddStockTransferModalOpen(false);
              getEndOfShiftStockTransfers();
              getStartOfShiftTransfers();
            }}
            key={isAddStockTransferModalOpen}
          />
        )}

        <React.Fragment>
          <div className={classes.headerContainer}>
            <h3 className={classes.header}>Stock Transfers</h3>
            {driverShift &&
              driverShift.status === enums.DriverShiftStatus.ACTIVE && (
                <Button
                  color="success"
                  className={classes.addButton}
                  onClick={() => setIsAddStockTransferModalOpen(true)}
                  create
                  disabled={
                    driverShift.status !== enums.DriverShiftStatus.ACTIVE
                  }
                >
                  Add Stock transfer
                </Button>
              )}
          </div>

          <div className={classes.tableContainer}>
            <h4 className={classes.title}>Start of shift stock transfers</h4>
            <CustomTable
              columns={columns}
              data={startOfShiftTransfersList.rows}
              loading={isGetStartOfShiftTransfersLoading}
              addIndexColumn
              onChange={handleChangeStartOfShiftTransfers}
            />
          </div>
          <div className={classes.tableContainer}>
            <h4 className={classes.title}>End of shift stock transfers</h4>
            <CustomTable
              columns={columns}
              data={endOfShiftTransfersList.rows}
              loading={isGetEndOfShiftTransfersLoading}
              addIndexColumn
              onChange={handleChangeEndOfShiftTransfers}
            />
          </div>
        </React.Fragment>
      </div>
    </div>
  );
}

StockTransfers.propTypes = {
  resource: PropTypes.string,
};
