import SettingsIcon from "@material-ui/icons/Settings";
import manufacturersRoutes from "./manufacturersRoutes";
import productsRoutes from "./productsRoutes";
import sizeRoutes from "./sizeRoutes";
import servicesRoutes from "./servicesRoutes";
import enums from "enums/index";
import ShopIcon from "@material-ui/icons/Shop";

export default {
  name: "Products Management",
  icon: ShopIcon,
  layout: "/admin",
  collapse: true,
  state: "productsManagementCollapse",
  resource: enums.Resource.PRODUCTS_MANAGEMENT,
  superAdmin: true,
  views: [productsRoutes, manufacturersRoutes, sizeRoutes, servicesRoutes],
};
