import { makeStyles } from "@material-ui/core/styles";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import OrderDetailsForm from "views/Orders/components/OrderDetailsForm/OrderDetailsForm";
import styles from "./CreateDriverShiftPage.styles";
import DriverShiftDetailsForm from "views/DriverShiftManagement/pages/CreateShiftPage/components/DriverShiftDetailsForm/DriverShiftDetailsForm";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function CreateDriverShiftPage(props) {
  const { path } = props;
  const classes = useStyles();

  return (
    <div>
      <Breadcrumbs />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <div className={classes.formHeader}>
                <React.Fragment>
                  <AssignmentIcon className={classes.icon} />
                  Create New Driver Shift
                </React.Fragment>
              </div>
              <DriverShiftDetailsForm path={path} create={true} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
