import * as yup from "yup";
import api from "services/api";
import carMakersForm from "./carMakersForm";
import sizeForm from "views/ProductsManagement/sizeForm";
import enums from "enums/index";

export default [
  {
    column: 1,
    fields: [
      {
        name: "name",
        type: "text",
        label: "Name",
        validation: yup.mixed().required("Required"),
      },
      {
        name: "stockBatterySizeId",
        fetchedName: "stockBatterySize",
        type: "autocomplete",
        label: "Stock Battery Size",
        options: {
          getData: api.sizes.getAll,
          getOptionLabel: (option) => option?.name,
          filters: { type: enums.SizeType.BATTERIES },
          getSubmitValue: (option) => option?.id,
          addNew: true,
          formStructure: sizeForm,
          freeSolo: true,
          dataResource: api.sizes,
          blurOnSelect: false,
          loadOnFocus: true,
        },
      },

      {
        name: "stockWiperBladeSizeId",
        fetchedName: "stockWiperBladeSize",
        type: "autocomplete",
        label: "Stock Wiper Blade Size",
        options: {
          getData: api.sizes.getAll,
          getOptionLabel: (option) => option?.name,
          filters: { type: enums.SizeType.WB },
          getSubmitValue: (option) => option?.id,
          addNew: true,
          formStructure: sizeForm,
          freeSolo: true,
          dataResource: api.sizes,
          blurOnSelect: false,
          loadOnFocus: true,
        },
      },
      {
        name: "year",
        type: "number",
        label: "Year",
        validation: yup.mixed().required("Required"),
      },
    ],
  },
];
