import * as yup from "yup";

//----------------------------------------------------------

export default () => {
  return yup.object().shape({
    assignedTruck: yup.mixed().required("Required"),
    assignedDriver: yup.mixed().required("Required"),
    zone: yup.mixed().required("Required"),
    plannedDeliveryCity: yup.mixed().required("Required"),
    plannedDeliveryDistrict: yup.mixed().required("Required"),
    plannedDeliveryCoordinates: yup
      .mixed()
      .test(
        "isValidCoordinates",
        "Invalid coordinates format it should be in lat,lng format",
        (value) => {
          if (value) {
            const coordinates = value.split(",");

            return (
              (coordinates.length === 2 &&
                /^\d/.test(coordinates[0]) &&
                /\d$/.test(coordinates[1])) ||
              value === ""
            );
          }

          return true;
        }
      )
      .required("Required"),
    plannedDeliveryStreet: yup.mixed().required("Required"),
  });
};
