import translations from "constants/translations/index";
import styles from "./OrderTransfersApprovalList.styles";
import useOrderTransferApprovalList from "./OrderTransfersApprovalList.hook";
import Highlighter from "react-highlight-words";
import enums from "enums/index";
import ApproveOrderTransfersModal from "./components/OrderTransferApprovalModal/ApproveOrderTransferModal";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import CustomTable from "components/CustomTable/CustomTable";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TooltipWithIcon from "components/TooltipWithIcon/TooltipWithIcon";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CachedIcon from "@material-ui/icons/Cached";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function OrderTransfersApprovalList(props) {
  const classes = useStyles();

  const { i18n } = useTranslation();

  const {
    resource,
    orderId,
    driverShift,
    checkOnProcessedOrders,
    setCheckOnProcessedOrders,
    setActiveStep,
  } = props;

  const {
    currentPage,
    listData,
    itemsPerPage,
    highlightedText,
    isGetLoading,
    setIsApproveOrderTransferModalOpen,
    isApproveOrderTransferModalOpen,
    setSelectedOrder,
    selectedOrder,
    updateOrderState,
    getData,
    handleChange,
  } = useOrderTransferApprovalList({
    resource,
    driverShift,
    checkOnProcessedOrders,
    setActiveStep,
    setCheckOnProcessedOrders,
  });

  const columns = React.useMemo(
    () => [
      {
        title: "Order No.",
        dataIndex: "orderNo",
        width: 110,
        fixed: "left",
        canBeHighlighted: true,
        render: (data) => `#${data}`,
      },
      {
        title: "Customer",
        dataIndex: ["car", "owner", "name"],
        width: 150,
        render: (_, record) =>
          record.car?.owner ? (
            <Highlighter
              highlightClassName={classes.highlightedText}
              searchWords={[highlightedText]}
              autoEscape={true}
              textToHighlight={
                record.car?.owner?.name +
                " - " +
                record.car?.owner?.primaryPhoneNumber
              }
            />
          ) : (
            "-"
          ),
      },

      {
        title: "Status",
        dataIndex: "status",
        getData: (data) => {
          if (data) return translations["en"].orderStatus[data];
          else return "-";
        },
        width: 120,
      },
      {
        title: "",
        render: (_, record) => {
          return record.status === enums.OrderStatus.PENDING_STOCK_APPROVAL ? (
            <Button
              color="success"
              onClick={() => {
                setSelectedOrder(record);
                setIsApproveOrderTransferModalOpen(true);
              }}
              className={classes.actionButton}
            >
              Take action
            </Button>
          ) : (
            <Button
              color="primary"
              onClick={() => {
                setSelectedOrder(record);
                setIsApproveOrderTransferModalOpen(true);
              }}
              className={classes.actionButton}
            >
              View
            </Button>
          );
        },
        width: 120,
      },
    ],
    [listData]
  );

  return (
    <div>
      {isApproveOrderTransferModalOpen && (
        <ApproveOrderTransfersModal
          isOpen={isApproveOrderTransferModalOpen}
          onClose={() => setIsApproveOrderTransferModalOpen(false)}
          orderId={selectedOrder?.id}
          successCallback={updateOrderState}
          orderStatus={selectedOrder?.status}
        />
      )}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTable
            columns={columns}
            data={listData.rows}
            pagination={{
              total: listData.count,
              current: currentPage,
              pageSize: itemsPerPage,
              position: ["topRight"],
              pageSizeOptions: [10, 20, 50],
            }}
            loading={isGetLoading}
            highlightedText={highlightedText}
            title={`Order for driver shift by truck ${driverShift?.assignedTruck?.truckNumber} `}
            addIndexColumn
            fixIndexColumn
            onChange={handleChange}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

OrderTransfersApprovalList.propTypes = {
  resource: PropTypes.string,
};
