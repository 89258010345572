import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";

import ItemValidation from "./SerializedItem.validation";

import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";

//-------------------------------------------------------------------------------------

export default function useSerializedItemModal({
  itemProp,
  onClose,
  onAdd,
  onEdit,
  order,
  completeOrderForm,
}) {
  const { i18n } = useTranslation();
  const [sendRequest, isLoading] = useHttpClient(true);
  const resolver = useYupValidationResolver(ItemValidation(itemProp.amount));

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const { handleSubmit, getValues, setValue, watch } = form;

  // React.useEffect(() => {
  //   if (itemProp) {
  //     const formData = {
  //       ...flattenObject(itemProp),
  //     };

  //     Object.keys(formData).forEach((key) => {
  //       if (key === "serialNumbers") setValue(key, formData[key].join(","));
  //       else setValue(key, formData[key]);
  //     });
  //   }
  // }, [itemProp]);

  React.useEffect(() => {
    const serializedItems = completeOrderForm.watch("serializedItems");

    if (itemProp) {
      if (serializedItems && serializedItems.length > 0) {
        const item = serializedItems.find((item) => item.id === itemProp.id);
        if (item) {
          setValue("serialNumbers", item.serialNumbers?.join(","));
        }
      }
    }
  }, [itemProp]);

  const submitHandler = async () => {
    try {
      let values = getValues();
      const serializedItems = completeOrderForm.watch("serializedItems");

      let serialNumbersArray;

      if (values.serialNumbers && values.serialNumbers.length > 0) {
        serialNumbersArray = getValues("serialNumbers")
          ?.split(",")
          .map((serial) => serial.trim());
      }

      const duplicates = serialNumbersArray.filter(
        (value, index) => serialNumbersArray.indexOf(value) !== index
      );

      if (duplicates.length > 0) {
        const duplicateMessage = `Duplicate serial number(s): ${duplicates.join(
          ", "
        )}`;
        toast.error(duplicateMessage);
        return;
      }

      if (serializedItems && serializedItems.length > 0) {
        const allSerialNumbers = serializedItems
          .map((item) => {
            if (item.id !== itemProp.id) {
              return item.serialNumbers;
            }
          })
          .flat();

        let duplicateSerialNumbers = [];

        duplicateSerialNumbers = allSerialNumbers.filter((serial) =>
          serialNumbersArray.includes(serial)
        );

        if (duplicateSerialNumbers.length > 0) {
          const duplicateMessage = `Duplicate serial number(s): ${duplicateSerialNumbers.join(
            ", "
          )}`;
          toast.error(duplicateMessage);
          return;
        }
      }

      const serialNumbersCheck = await sendRequest(
        api.warranties.checkOnSerialNumber(serialNumbersArray)
      );

      onEdit(serialNumbersArray, itemProp.id);
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    i18n,
    form,
    isLoading,
    submitHandler,
    onError,
    handleSubmit,
  };
}
