import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import InfoWithLabelGridItem from "components/InfoWithLabel/InfoWithLabelGridItem";
import { orderStatusColors } from "constants/colors";
import translations from "constants/translations/index";
import enums from "enums/index";
import React from "react";
import { useTranslation } from "react-i18next";
import { getDateString, isOrderEditable } from "utils/index";
import styles from "./DriverShiftDetails.styles";
import { AuthContext } from "shared/context/auth-context";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function DriverShiftDetails(props) {
  const classes = useStyles();

  const { driverShift } = props;

  const { i18n } = useTranslation();

  // const { userData } = React.useContext(AuthContext);

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <h3 className={classes.header}>Driver Shift details</h3>
      </div>
      <div className={classes.section}>
        <GridContainer>
          <InfoWithLabelGridItem
            label="Driver Shift Status"
            info={
              translations[i18n.language].driverShiftStatus[driverShift?.status]
            }
          />

          <InfoWithLabelGridItem
            label="Driver"
            info={driverShift?.assignedDriver?.name}
          />
          <InfoWithLabelGridItem
            label="Truck Number"
            info={driverShift?.assignedTruck?.truckNumber}
          />
          <InfoWithLabelGridItem
            label="Started At"
            type={enums.InfoType.DATETIME}
            info={driverShift?.startedAt}
          />
          <InfoWithLabelGridItem
            label="Ended At"
            type={enums.InfoType.DATETIME}
            info={driverShift?.endedAt}
          />
        </GridContainer>
      </div>
    </div>
  );
}
