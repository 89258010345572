export default {
  FormContainerType: {
    PAGE: "PAGE",
    MODAL: "MODAL",
  },
  Action: {
    CREATE: "create",
    READ: "read",
    UPDATE: "update",
    DELETE: "delete",
  },
  Resource: {
    ACCESS_MANAGEMENT: "ACCESS_MANAGEMENT",
    SETTINGS: "SETTINGS",
    CAR_TYPES: "CAR_TYPES",
    PRODUCTS_MANAGEMENT: "PRODUCTS_MANAGEMENT",
    CLIENTS_MANAGEMENT: "CLIENTS_MANAGEMENT",
    ZONING: "ZONING",
    ORDERS_MANAGEMENT: "ORDERS_MANAGEMENT",
    STOCK_MANAGEMENT: "STOCK_MANAGEMENT",
    STOCK_MANAGEMENT_DRIVERS: "STOCK_MANAGEMENT_DRIVERS",
    STOCK_MANAGEMENT_TRUCKS: "STOCK_MANAGEMENT_TRUCKS",
    STOCK_MANAGEMENT_WAREHOUSES: "STOCK_MANAGEMENT_WAREHOUSES",
    STOCK_MANAGEMENT_STOCK_STATISTICS: "STOCK_MANAGEMENT_STOCK_STATISTICS",
    STOCK_MANAGEMENT_STOCK_TRANSFERS_APPROVAL:
      "STOCK_MANAGEMENT_STOCK_TRANSFERS_APPROVAL",
    STOCK_MANAGEMENT_ORDER_TRANSFERS_APPROVAL:
      "STOCK_MANAGEMENT_ORDER_TRANSFERS_APPROVAL",
    STOCK_MANAGEMENT_WARRANTIES: "STOCK_MANAGEMENT_WARRANTIES",
    STOCK_MANAGEMENT_INVOICES: "STOCK_MANAGEMENT_INVOICES",
    STOCK_MANAGEMENT_STOCK_TRANSFERS_SHEETS:
      "STOCK_MANAGEMENT_STOCK_TRANSFERS_SHEETS",
    STOCK_MANAGEMENT_WARRANTIES_SHEETS: "STOCK_MANAGEMENT_WARRANTIES_SHEETS",
    DRIVER_SHIFT_MANAGEMENT: "DRIVER_SHIFT_MANAGEMENT",
  },
  ProductCategory: {
    BATTERIES: "BATTERIES",
    WB: "WB",
    TOOLS_AND_ACCESSORIES: "TOOLS_AND_ACCESSORIES",
  },
  ValidStockTransfersTypes: {
    INTERNAL_TRANSFER: "INTERNAL_TRANSFER",
    ADDING_STOCK_TO_STORAGE: "ADDING_STOCK_TO_STORAGE",
    LOSING_STOCK_FROM_STORAGE: "LOSING_STOCK_FROM_STORAGE",
  },
  ValidStartOfShiftStockTransfersTypes: {
    FROM_WAREHOUSE_TO_TRUCK: "FROM_WAREHOUSE_TO_TRUCK",
    FROM_TRUCK_TO_WAREHOUSE: "FROM_TRUCK_TO_WAREHOUSE",
  },

  SizeType: {
    BATTERIES: "BATTERIES",
    WB: "WB",
  },

  DriverShiftReportType: {
    START_OF_SHIFT: "START_OF_SHIFT",
    END_OF_SHIFT: "END_OF_SHIFT",
    INVOICE: "INVOICE",
  },

  DriverShiftStockTransferType: {
    START_OF_SHIFT: "START_OF_SHIFT",
    END_OF_SHIFT: "END_OF_SHIFT",
  },

  StockItemTransferStatus: {
    PENDING: "PENDING",
    APPROVED: "APPROVED",
    CANCELLED: "CANCELLED",
  },

  DriverShiftStatus: {
    ACTIVE: "ACTIVE",
    COMPLETED: "COMPLETED",
  },
  WarrantyStatus: {
    PENDING_ACTIVATION: "PENDING_ACTIVATION",
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
  },
  ProductType: {
    NEW: "NEW",
    SCRAP: "SCRAP",
  },
  TransferPartyType: {
    CUSTOMER: "CUSTOMER",
    STORAGE: "STORAGE",
  },
  OrderStatus: {
    LEAD: "LEAD",
    PLANNED: "PLANNED",
    ASSIGNED: "ASSIGNED",
    IN_PROGRESS: "IN_PROGRESS",
    COMPLETED: "COMPLETED",
    CANCELLED: "CANCELLED",
    LOST: "LOST",
    PENDING_STOCK_APPROVAL: "PENDING_STOCK_APPROVAL",
    STOCK_REJECTED: "STOCK_REJECTED",
  },
  TruckStatus: {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
  },
  DriverStatus: {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
  },
  StorageType: {
    WAREHOUSE: "WAREHOUSE",
    TRUCK: "TRUCK",
  },
  TruckType: {
    HEAVY: "HEAVY",
    LIGHT: "LIGHT",
  },
  WarehouseType: {
    SHOP: "SHOP",
    WAREHOUSE: "WAREHOUSE",
    VIRTUAL: "VIRTUAL",
  },

  PromoCodeType: {
    PERCENTAGE: "PERCENTAGE",
    FIXED: "FIXED",
  },
  DeliveryType: {
    ON_DEMAND: "ON_DEMAND",
    SCHEDULED: "SCHEDULED",
  },
  LeadFollowUp: {
    TRUE: "TRUE",
    FALSE: "FALSE",
    ORDERED: "ORDERED",
  },
  MarketingSpendChannel: {
    FACEBOOK: "FACEBOOK",
  },
  InfoType: {
    TEXT: "TEXT",
    DATE: "DATE",
    DATETIME: "DATETIME",
    BOOLEAN: "BOOLEAN",
    LIST: "LIST",
    LINK: "LINK",
    EXTERNAL_LINK: "EXTERNAL_LINK",
    FILE: "FILE",
    TAGS: "TAGS",
  },
  CallType: {
    INBOUND: "INBOUND",
    OUTBOUND_ANSWERED: "OUTBOUND_ANSWERED",
    OUTBOUND_UNANSWERED: "OUTBOUND_UNANSWERED",
  },
  CallReason: {
    NEW_ORDER: "NEW_ORDER",
    ORDER_FOLLOW_UP: "ORDER_FOLLOW_UP",
    ORDER_ISSUE: "ORDER_ISSUE",
    GENERAL_ENQUIRY: "GENERAL_ENQUIRY",
  },
  CustomerSource: {
    FACEBOOK: "FACEBOOK",
    FACEBOOK_GROUP: "FACEBOOK_GROUP",
    INSTAGRAM: "INSTAGRAM",
    SHOPIFY: "SHOPIFY",
    REFERRAL: "REFERRAL",
    SMS_CAMPAIGN: "SMS_CAMPAIGN",
    VALUE: "VALUE",
    EVENTS: "EVENTS",
    MAINTENANCE_CENTERS: "MAINTENANCE_CENTERS",
    WHATSAPP: "WHATSAPP",
  },
  CustomerChannel: {
    WHATSAPP: "WHATSAPP",
    FACEBOOK: "FACEBOOK",
    INSTAGRAM: "INSTAGRAM",
    HOTLINE: "HOTLINE",
    SHOPIFY: "SHOPIFY",
  },
  Gender: {
    MALE: "MALE",
    FEMALE: "FEMALE",
  },
  RoleCode: {
    ORDERING_ADMIN: "ORDERING_ADMIN",
    STOCK_ADMIN: "STOCK_ADMIN",
    SUPER_ADMIN: "SUPER_ADMIN",
    OPS_MANAGER: "OPS_MANAGER",
    WAREHOUSE_OFFICER: "WAREHOUSE_OFFICER",
  },
};
