import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import TrucksFormValidation from "./TrucksForm.validation";
import { useHistory } from "react-router";

//------------------------------------------------------------------

export const useTrucksForm = ({
  edit,
  create,
  typeId,
  handleAddNew,
  handleModalClose,
  truck,
}) => {
  const [data, setData] = React.useState(null);

  const [sendRequest, isLoading] = useHttpClient();

  const [sendGetRequest, isGetLoading] = useHttpClient();

  const dataResource = React.useMemo(() => api.trucks, []);
  const history = useHistory();

  const resolver = useYupValidationResolver(TrucksFormValidation());

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { isDirty },
  } = form;

  React.useEffect(() => {
    if (truck) {
      Object.keys(truck).forEach((key) => setValue(key, truck[key]));
      setValue("truckTypeId", truck.truckTypeId);
      const values = getValues();
    }
  }, [truck]);

  const createHandler = (values) => async () => {
    try {
      const response = await sendRequest(dataResource.create(values));
      toast.success(`Truck has been added successfully`);
      if (handleModalClose) handleModalClose();
      if (handleAddNew) handleAddNew(response);
    } catch (err) {
      console.log(err);
      toast.error("An error has occurred");
    }
  };

  const editHandler = (values) => async () => {
    try {
      const response = await sendRequest(dataResource.patch(values));
      toast.success(`Truck has been updated successfully`);
      if (handleModalClose) handleModalClose();
    } catch (err) {
      toast.error("An error has occurred");
    }
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let values = getValues();
    values = {
      ...values,
      truckTypeId: values.truckType?.id,
    };

    handleSubmit(
      create ? createHandler(values) : editHandler(values),
      onError
    )();
  };

  return {
    isLoading,
    isGetLoading,
    form,
    data,
    watch,
    typeId,
    onSubmit,
  };
};
