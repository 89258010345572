import enums from "enums/index";
import * as yup from "yup";

//----------------------------------------------------------

export default () => {
  return yup.object().shape({
    followUpDate: yup.mixed().required("Required"),
  });
};
