import DataList from "components/DataList/DataList";
import FormContainer from "components/Forms/FormContainer/FormContainer";
import enums from "enums";
import React from "react";
import api from "services/api";
import countriesForm from "views/Zoning/countriesForm";

// appear in breadcrumbs and page headers
const listPageName = "Countries";
const createPageName = "Create Country";
const editPageName = "Edit Country";

// appears on alerts; example: Entity created successfully
const entityName = "Country";

const resource = enums.Resource.ZONING;

const layout = "/admin";

const path = "countries";

const formStructure = countriesForm;

const dataResource = api.countries;

const columns = [
  {
    title: "Name (English)",
    dataIndex: "nameEn",
    canBeHighlighted: true,
  },
  {
    title: "Name (Arabic)",
    dataIndex: "nameAr",
    canBeHighlighted: true,
  },
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: "public",
  render: () => (
    <DataList
      entityName={entityName}
      path={path}
      columns={columns}
      actions={{ edit: true, delete: true }}
      dataResource={dataResource}
      resource={resource}
      listPageName={listPageName}
      formStructure={formStructure}
      formType={enums.FormContainerType.MODAL}
    />
  ),
  action: "read",
  resource,
  layout: layout,
  // randomKey: true,
  children: true,
  superAdmin: true,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          create
        />
      ),
      layout: layout,
      randomKey: true,
      action: "create",
      superAdmin: true,
      resource,
    },
    {
      path: `/${path}/:id/edit`,
      name: editPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          edit
        />
      ),
      layout: layout,
      randomKey: true,
      action: "update",
      superAdmin: true,
      resource,
    },
  ],
};
