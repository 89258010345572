import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CheckPermission from "components/CheckPermission/CheckPermission";
import Button from "components/CustomButtons/Button.js";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import CustomTable from "components/CustomTable/CustomTable";
import enums from "enums/index";
import React from "react";
import ScrapItemModal from "./components/ScrapItemModal/ScrapItemModal";
import useScrapItems from "./ScrapItems.hook";
import styles from "./ScrapItems.styles";
import { Controller } from "react-hook-form";
import translations from "constants/translations/index";
import { useTranslation } from "react-i18next";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function ScrapItems({
  form,
  order: orderProp,
  editable = true,
}) {
  const classes = useStyles();

  const { i18n } = useTranslation();

  const { control, errors } = form;

  const {
    order,
    isAssignModalOpen,
    isGetOrderLoading,
    editedItem,
    scrapItems,
    onAssignModalClose,
    handleAssignItem,
    onRemoveModalClose,
    onAdd,
    onEdit,
  } = useScrapItems({ form, orderProp });

  const columns = React.useMemo(
    () => [
      {
        title: "Battery Size",
        dataIndex: ["product", "size", "name"],
      },
      {
        title: "Unit Price",
        dataIndex: "unitPrice",
      },
      {
        title: "Amount",
        dataIndex: "amount",
      },
      {
        title: "Total Price",
        dataIndex: "totalPrice",
      },
      {
        title: "",
        render: (_, record) => {
          return (
            <div className={classes.actionsContainer}>
              {editable && (
                <>
                  <CheckPermission action={enums.Action.UPDATE}>
                    <Button
                      justIcon
                      edit
                      color="white"
                      textColor="success"
                      onClick={() => handleAssignItem(record)}
                    />
                  </CheckPermission>
                  <CheckPermission action={enums.Action.DELETE}>
                    <ConfirmationButton
                      name="scrap item"
                      entityName="Scrap item"
                      onConfirm={() => {}}
                      onSuccess={() => onRemoveModalClose(record)}
                      buttonProps={{
                        justIcon: true,
                      }}
                      skipSuccessScreen
                    />
                  </CheckPermission>
                </>
              )}
            </div>
          );
        },
      },
    ],
    [order, editable]
  );

  return (
    <Controller
      render={(props) => (
        <div className={classes.root}>
          <div className={classes.section}>
            {isAssignModalOpen && (
              <ScrapItemModal
                isOpen={isAssignModalOpen}
                onClose={onAssignModalClose}
                create={!editedItem}
                edit={!!editedItem}
                item={editedItem}
                onAdd={onAdd}
                onEdit={onEdit}
              />
            )}

            <React.Fragment>
              <div className={classes.headerContainer}>
                <h3 className={classes.header}>Scrap Items</h3>
                {editable && (
                  <CheckPermission action={enums.Action.CREATE}>
                    <Button
                      onClick={() => handleAssignItem()}
                      className={classes.addButton}
                      color="success"
                    >
                      <AddIcon className={classes.addIcon} />
                      Add Scrap Item
                    </Button>
                  </CheckPermission>
                )}
              </div>
              <div className={classes.tableContainer}>
                <CustomTable
                  columns={columns}
                  data={scrapItems}
                  loading={isGetOrderLoading}
                  addIndexColumn
                />
              </div>
            </React.Fragment>
            <div className={classes.error}>{errors?.scraps?.message}</div>
          </div>
        </div>
      )}
      name="scraps"
      control={control}
    />
  );
}
