import { makeStyles } from "@material-ui/core/styles";
import { Person } from "@material-ui/icons";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomModal from "components/CustomModal/CustomModal";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoWithLabel from "components/InfoWithLabel/InfoWithLabel";
import PropTypes from "prop-types";
import React from "react";
import styles from "./CustomerInfoModal.styles";
import Button from "components/CustomButtons/Button.js";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function CustomerInfoModal(props) {
  const { isOpen, onClose, customer } = props;

  const classes = useStyles();

  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ margin: 0 }}>
            <CardBody>
              <div className={classes.header}>
                <React.Fragment>
                  <Person className={classes.icon} /> Customer Info
                </React.Fragment>
              </div>
              <InfoWithLabel label="Name" info={customer?.name} />
              <InfoWithLabel label="Email" info={customer?.email} />
              <InfoWithLabel
                label="Primary Phone"
                info={customer?.primaryPhoneNumber}
              />
              <InfoWithLabel
                label="Secondary Phone"
                info={customer?.secondaryPhoneNumber}
              />
              <div className={classes.footer}>
                <Button color="primary" onClick={onClose}>
                  Close
                </Button>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </CustomModal>
  );
}

CustomerInfoModal.defaultProps = {
  onClose: () => {},
  isOpen: false,
};

CustomerInfoModal.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  customer: PropTypes.object,
};
