import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModal/CustomModal";
import FormField from "components/Forms/FormField/FormField";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import enums from "enums/index";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import api from "services/api";
import {
  displayDriverShift,
  displayTransferParty,
  getDateStringDashSeparated,
} from "utils/index";
import productsForm from "views/ProductsManagement/productsForm";
import useAddStockTransferModal from "./AddStockTransferModal.hook";
import styles from "./AddStockTransferModal.styles";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function AddStockTransferModal(props) {
  const { isOpen, onClose, successCallback } = props;

  const classes = useStyles();

  const { i18n } = useTranslation();

  const {
    isLoading,
    submitHandler,
    form,
    onError,
    handleSubmit,
    userRole,
  } = useAddStockTransferModal({
    onClose,
    successCallback,
  });

  const transferType = form.watch("transferType");

  const driverShift = form.watch("driverShift");

  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ margin: 0 }}>
            <CardBody>
              <div className={classes.formHeader}>
                <React.Fragment>
                  <EditIcon className={classes.icon} /> Add Transfer
                </React.Fragment>
              </div>
              <form
                style={{ padding: 20, paddingBottom: 0 }}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(submitHandler, onError)();
                }}
                autoComplete="off"
              >
                <FormField
                  md={12}
                  name="product"
                  type="autocomplete"
                  label="Product"
                  options={{
                    getData: api.products.getAll,
                    getOptionLabel: (option) =>
                      option?.sapCode + " - " + option?.description,
                    getSubmitValue: (option) => option?.id,
                    addNew: true,
                    formStructure: productsForm,
                    dataResource: api.products,
                    freeSolo: true,
                    blurOnSelect: false,
                    loadOnFocus: true,
                  }}
                  form={form}
                />
                <FormField
                  md={12}
                  name="transferType"
                  type="autocomplete"
                  label="Transfer Type"
                  options={{
                    enum:
                      userRole === enums.RoleCode.WAREHOUSE_OFFICER
                        ? "validStockTransfersTypesForWarehouseOfficer"
                        : "validStockTransfersTypesForSuperAdmin",
                  }}
                  form={form}
                  onChange={(e) => {
                    form.setValue("from", null);
                    form.setValue("to", null);
                  }}
                />
                {(!transferType ||
                  (transferType &&
                    transferType.id !==
                      enums.ValidStockTransfersTypes
                        .ADDING_STOCK_TO_STORAGE)) && (
                  <FormField
                    md={12}
                    name="from"
                    type="autocomplete"
                    label="From Warehouse"
                    options={{
                      getData: api.transferParty.getAll,
                      getOptionLabel: (option) => displayTransferParty(option),
                      getSubmitValue: (option) => option?.id,
                      filters: { type: enums.TransferPartyType.STORAGE },
                      blurOnSelect: false,
                      shouldThrottle: true,
                      disabled: !transferType,

                      disabledTooltipText: "Please select a type first",
                    }}
                    form={form}
                  />
                )}

                {(!transferType ||
                  (transferType &&
                    transferType.id !==
                      enums.ValidStockTransfersTypes
                        .LOSING_STOCK_FROM_STORAGE)) && (
                  <FormField
                    md={12}
                    name="to"
                    type="autocomplete"
                    label="To Warehouse"
                    options={{
                      getData: api.transferParty.getAll,
                      getOptionLabel: (option) => displayTransferParty(option),
                      getSubmitValue: (option) => option?.id,
                      filters: { type: enums.TransferPartyType.STORAGE },
                      blurOnSelect: false,
                      shouldThrottle: true,
                      disabled: !transferType,
                      disabledTooltipText: "Please select a type first",
                    }}
                    form={form}
                  />
                )}

                {(!transferType ||
                  (transferType &&
                    transferType.id !==
                      enums.ValidStockTransfersTypes
                        .LOSING_STOCK_FROM_STORAGE)) && (
                  <FormField
                    md={12}
                    name="driverShift"
                    type="autocomplete"
                    label="Driver Shift"
                    options={{
                      getData: api.driverShifts.getAll,
                      getOptionLabel: (option) => displayDriverShift(option),
                      getSubmitValue: (option) => option?.id,
                      filters: {
                        status: enums.DriverShiftStatus.ACTIVE,
                      },
                      blurOnSelect: false,
                      disabled: !transferType,
                      disabledTooltipText: "Please select a type first",
                      loadOnFocus: true,
                    }}
                    form={form}
                  />
                )}

                <FormField
                  md={12}
                  name="amount"
                  type="number"
                  label="Amount"
                  form={form}
                />

                <FormField
                  md={12}
                  name="apInvoiceNumber"
                  type="text"
                  label="AP Invoice Number"
                  form={form}
                />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    color="primary"
                    type="submit"
                    loading={isLoading}
                    style={{ margin: "20px 0" }}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </CustomModal>
  );
}

AddStockTransferModal.defaultProps = {
  onClose: () => {},
};

AddStockTransferModal.propTypes = {
  onClose: PropTypes.func,
};
