import enums from "enums/index";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import AssignOrderValidation from "./AssignOrder.validation";

//-------------------------------------------------------------------------------------

export default function useAssignOrderModal({
  orderId,
  onClose,
  successCallback,
  order,
}) {
  const [sendRequest, isLoading] = useHttpClient(true);

  const { i18n } = useTranslation();

  const resolver = useYupValidationResolver(AssignOrderValidation());

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });
  const { handleSubmit, getValues, setValue, watch } = form;

  const assignedDriverId = watch("assignedDriver")?.id;
  const plannedDeliveryCity = watch("plannedDeliveryCity");

  useEffect(() => {
    if (assignedDriverId) {
      const defaultTruck = watch("assignedDriver").defaultTruck;
      setValue("assignedTruck", defaultTruck);
    }
  }, [assignedDriverId]);

  useEffect(() => {
    if (order) {
      const tempOrder = { ...order };
      if (
        tempOrder?.plannedDeliveryLatitude &&
        tempOrder?.plannedDeliveryLongitude
      ) {
        setValue(
          "plannedDeliveryCoordinates",
          `${tempOrder?.plannedDeliveryLatitude},${tempOrder?.plannedDeliveryLongitude}`
        );
      }
      setValue("plannedDeliveryDistrict", tempOrder?.plannedDeliveryDistrict);
      setValue("plannedDeliveryStreet", tempOrder?.plannedDeliveryStreet);
      setValue("plannedDeliveryCity", tempOrder?.plannedDeliveryDistrict?.city);
      setValue("zone", tempOrder?.zone);
    }
  }, [order]);

  const submitHandler = async () => {
    let values = getValues();

    values = {
      ...values,
      id: orderId,
      assignedTruckId: values.assignedTruck?.id,
      assignedDriverId: values.assignedDriver?.id,

      plannedDeliveryLatitude:
        values.plannedDeliveryCoordinates?.split(",")[0]?.trim() ?? null,
      plannedDeliveryLongitude:
        values.plannedDeliveryCoordinates?.split(",")[1]?.trim() ?? null,
      plannedDeliveryDistrictId: values.plannedDeliveryDistrict?.id,
      zoneId: values.zone?.id,

      status: enums.OrderStatus.ASSIGNED,
    };
    delete values.assignedTruck;
    delete values.assignedDriver;
    try {
      const updatedOrder = await sendRequest(
        api.orders.assign(orderId, values)
      );
      toast.success(`Order has been assigned successfully`);
      successCallback(updatedOrder);

      onClose();
    } catch {}
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    isLoading,
    i18n,
    form,
    submitHandler,
    onError,
    handleSubmit,
    plannedDeliveryCity,
  };
}
