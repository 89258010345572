import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import CustomTable from "components/CustomTable/CustomTable";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TooltipWithIcon from "components/TooltipWithIcon/TooltipWithIcon";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import api from "services/api";
import useStockStats from "./StockStats.hook";
import styles from "./StockStats.styles";
import AddStockTransferModal from "./components/AddStockTransferModal/AddStockTransferModal";
import AddStockTransferSheetModal from "./components/AddStockTransferSheetModal/AddStockTransferSheetModal";
import enums from "enums/index";
import { displayStorageName } from "utils/index";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CheckPermission from "components/CheckPermission/CheckPermission";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function StockStats(props) {
  const classes = useStyles();

  const { i18n } = useTranslation();
  const [
    isAddStockTransferModalOpen,
    setIsAddStockTransferModalOpen,
  ] = useState(false);

  const [
    isAddStockTransferSheetModalOpen,
    setIsAddStockTransferSheetModalOpen,
  ] = useState(false);

  const { resource } = props;

  const filterInputs = useMemo(
    () => [
      {
        name: "productId",
        label: "Product",
        type: "autocomplete",
        options: {
          getData: api.products.getAll,
          getOptionLabel: (option) =>
            option?.sapCode ? option?.sapCode : option?.size.name,
          getSubmitValue: (option) => option?.id,
          blurOnSelect: false,
          loadOnFocus: true,
          multiple: true,
        },
      },
      {
        name: "storagesIds",
        label: "Storage",
        type: "autocomplete",
        options: {
          getData: api.storages.getAll,
          getOptionLabel: (option) => displayStorageName(option),
          getSubmitValue: (option) => option?.id,
          blurOnSelect: false,
          loadOnFocus: true,
          multiple: true,
        },
      },
    ],
    []
  );

  const {
    currentPage,
    listData,
    searchQuery,
    filtersState,
    itemsPerPage,
    highlightedText,
    isGetLoading,
    onSearchChange,
    onFilterChange,
    handleChange,
    handleSubmit,
    getData,
  } = useStockStats({
    resource,
    filterInputs,
  });

  const columns = React.useMemo(
    () => [
      {
        dataIndex: ["sapCode"],
        title: "SAP Code",
        canBeHighlighted: true,
      },
      {
        dataIndex: ["manufacturerName"],
        title: "Manufacturer",
      },
      {
        dataIndex: ["size"],
        title: "Size",
      },
      { dataIndex: ["stockItemsCount"], title: "All Stock Count" },
      {
        dataIndex: ["stockItemsOnHoldCount"],
        width: 120,
        title: () => {
          return (
            <div className={classes.tableHeaderContainer}>
              <span>Stock on Hold</span>
              <TooltipWithIcon
                title="Total stock on hold by a pending transfer 'FROM' a storage"
                marginLeft={5}
              />
            </div>
          );
        },
      },
      {
        dataIndex: ["totalOrderItems"],
        width: 110,
        title: () => {
          return (
            <div className={classes.tableHeaderContainer}>
              <span>Open orders</span>
              <TooltipWithIcon
                title="Stock on hold across the system by open orders (orders with status 'planned', 'assigned', or 'in progress')"
                marginLeft={5}
              />
            </div>
          );
        },
      },
      {
        dataIndex: ["availableStock"],
        width: 120,
        title: () => {
          return (
            <div className={classes.tableHeaderContainer}>
              <span>Available stock</span>
              <TooltipWithIcon
                title="All stock - stock on hold - open orders"
                marginLeft={5}
              />
            </div>
          );
        },
      },
    ],
    [listData]
  );

  return (
    <div>
      {isAddStockTransferModalOpen && (
        <AddStockTransferModal
          isOpen={isAddStockTransferModalOpen}
          onClose={() => {
            setIsAddStockTransferModalOpen(false);
            getData();
          }}
          key={isAddStockTransferModalOpen}
        />
      )}
      {isAddStockTransferSheetModalOpen && (
        <AddStockTransferSheetModal
          isOpen={isAddStockTransferSheetModalOpen}
          onClose={() => {
            setIsAddStockTransferSheetModalOpen(false);
            getData();
          }}
          key={isAddStockTransferSheetModalOpen}
        />
      )}
      <div className={classes.topContainer}>
        <div className={classes.buttonsContainer}>
          <Button
            create
            color="white"
            onClick={() => setIsAddStockTransferModalOpen(true)}
          >
            Stock transfer
          </Button>
          <CheckPermission
            resources={[enums.Resource.STOCK_MANAGEMENT_STOCK_STATISTICS]}
            roles={[enums.RoleCode.SUPER_ADMIN]}
          >
            <Button
              color="white"
              onClick={() => setIsAddStockTransferSheetModalOpen(true)}
            >
              <CloudUploadIcon className={classes.addIcon} />
              Add Stock Transfer Sheet
            </Button>
          </CheckPermission>
        </div>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTable
            columns={columns}
            data={listData.rows}
            onChange={handleChange}
            pagination={{
              total: listData.count,
              current: currentPage,
              pageSize: itemsPerPage,
              position: ["topRight"],
              pageSizeOptions: [10, 20, 50],
            }}
            loading={isGetLoading}
            highlightedText={highlightedText}
            filterProps={{
              value: filtersState,
              onChange: onFilterChange,
              inputs: filterInputs,
            }}
            searchProps={{
              onChange: onSearchChange,
              value: searchQuery,
              placeholder: "Search",
            }}
            handleSubmit={handleSubmit}
            title="Stock Management Summary"
            addIndexColumn
            size="middle"
            fixIndexColumn
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

StockStats.propTypes = {
  resource: PropTypes.string,
};
