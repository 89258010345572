import enums from "enums/index";
import qs from "qs";
import React from "react";
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";

//------------------------------------------------------------------------------

export default function useOrderTransferApprovalList(props) {
  const {
    driverShift,
    setCheckOnProcessedOrders,
    checkOnProcessedOrders,
    setActiveStep,
  } = props;

  const history = useHistory();
  const match = useRouteMatch();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [listData, setListData] = React.useState({ count: 0, rows: [] });
  const [itemsPerPage, setItemsPerPage] = React.useState(10);
  const [highlightedText, setHighlightedText] = React.useState("");

  const [
    isApproveOrderTransferModalOpen,
    setIsApproveOrderTransferModalOpen,
  ] = React.useState(false);

  const [selectedOrder, setSelectedOrder] = React.useState(null);
  const [sendGetRequest, isGetLoading] = useHttpClient();

  const getData = React.useCallback(
    async (page = currentPage, rowsPerPage = itemsPerPage) => {
      try {
        const response = await sendGetRequest(
          api.orders.getAllStockRelatedOrders(
            page,
            1000,
            undefined,
            { driverShiftId: driverShift?.id },
            undefined
          )
        );

        setListData({
          count: response.count,
          rows: response.data,
        });

        ///Already processed orders are not counted as they are the orders
        // that an action has been taken on them before
        const alreadyProcessedOrders = response.data.filter(
          (order) =>
            order.status === enums.OrderStatus.STOCK_REJECTED ||
            order.status === enums.OrderStatus.COMPLETED
        );
      } catch (err) {
        toast.error("An error has occurred");
      }
    },
    [sendGetRequest, setListData, currentPage, itemsPerPage]
  );

  ///Loop over the orders and if all orders are processed then set to the next step else toasts an error
  const checkOverOrdersProcessing = () => {
    if (checkOnProcessedOrders) {
      const allOrdersAreProcessed = listData.rows.every(
        (order) =>
          order.status === enums.OrderStatus.STOCK_REJECTED ||
          order.status === enums.OrderStatus.COMPLETED
      );
      if (allOrdersAreProcessed) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        toast.error("Please process all orders before proceeding");
      }
      setCheckOnProcessedOrders(false);
    }
  };

  React.useEffect(() => {
    if (checkOnProcessedOrders) {
      checkOverOrdersProcessing();
      setCheckOnProcessedOrders(false);
    }
  }, [checkOnProcessedOrders]);

  React.useEffect(() => {
    (async () => {
      try {
        await getData(1, itemsPerPage);
      } catch (err) {
        toast.error("An error has occurred");
      }
    })();
  }, []);
  const handleChange = async (pagination) => {
    setCurrentPage(pagination.current);
    setItemsPerPage(pagination.pageSize);

    // await getData(
    //   pagination.current,
    //   pagination.pageSize,
    //   undefined,
    //   undefined
    // );
  };
  const updateOrderState = React.useCallback(
    (updatedOrder) => {
      setListData((prevState) => ({
        rows: prevState.rows.map((item) =>
          item.id === updatedOrder.id
            ? {
                ...item,
                status: updatedOrder.status,
              }
            : item
        ),
        count: prevState.count,
      }));
    },

    [setListData]
  );

  return {
    currentPage,
    listData,
    itemsPerPage,
    highlightedText,
    isGetLoading,
    setListData,
    getData,
    isApproveOrderTransferModalOpen,
    setIsApproveOrderTransferModalOpen,
    selectedOrder,
    setSelectedOrder,
    updateOrderState,
    handleChange,
  };
}
