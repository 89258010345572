import React from "react";
import api from "services/api";
import { OrderContext } from "views/Orders/pages/OrderView/Order.context";

//-------------------------------------------------------------------------------------

export default function useCalls() {
  const [isAssignModalOpen, setIsAssignModalOpen] = React.useState(false);
  const [editedCall, setEditedCall] = React.useState(null);

  const { order, setOrder, isGetOrderLoading } = React.useContext(OrderContext);

  const calls = React.useMemo(
    () => (order.calls?.length ? order.calls : []),
    [order]
  );

  const handleAssignCall = React.useCallback(
    (data) => {
      setEditedCall(data);
      setIsAssignModalOpen(true);
    },
    [setIsAssignModalOpen, setEditedCall]
  );
  
  const onAssignModalClose = React.useCallback(() => {
    setIsAssignModalOpen(false);
    setEditedCall(null);
  }, [setIsAssignModalOpen, setEditedCall]);

  const handleRemoveCall = React.useCallback(
    async (record) => {
      const response = await api.calls.delete(record.id);
    },
    [order]
  );

  const onRemoveModalClose = React.useCallback(
    (record) => {
      const newCalls = order.calls.filter(
        (call) => record.id !== call.id
      );
      setOrder({ ...order, calls: newCalls });
    },
    [order]
  );

  return {
    order,
    isAssignModalOpen,
    isGetOrderLoading,
    editedCall,
    calls,
    onAssignModalClose,
    handleAssignCall,
    handleRemoveCall,
    onRemoveModalClose,
  };
}
