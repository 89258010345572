import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import { deepen, flattenObject } from "utils/index";
import { OrderContext } from "views/Orders/pages/OrderView/Order.context";
import CallValidation from "./Call.validation";

//-------------------------------------------------------------------------------------

export default function useCallModal({ callProp, onClose }) {
  const [sendRequest, isLoading] = useHttpClient(true);

  const { order, getLatestOrder } = React.useContext(OrderContext);

  const { i18n } = useTranslation();

  const resolver = useYupValidationResolver(CallValidation());

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const { handleSubmit, getValues, setValue } = form;

  React.useEffect(() => {
    if (callProp) {
      const formData = {
        ...flattenObject(callProp),
        orderId: order.id,
      };

      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key]);
      });
    } else {
      setValue("orderId", order.id);
    }
  }, [callProp, order.id]);

  const createHandler = (values) => async () => {
    try {
      await sendRequest(api.calls.create(deepen(values)));
      toast.success(`Call has been added successfully`);

      getLatestOrder();
      onClose();
    } catch {}
  };

  const editHandler = (values) => async () => {
    try {
      await sendRequest(api.calls.patch(deepen(values)));
      toast.success(`Call has been updated successfully`);

      getLatestOrder();
      onClose();
    } catch {}
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let values = getValues();
    values = {
      ...values,
      orderIssueTypeId: values.orderIssueType?.id,
      orderId: order.id,
    };

    handleSubmit(
      !!callProp ? editHandler(values) : createHandler(values),
      onError
    )();
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    isLoading,
    i18n,
    form,
    submitHandler,
    onError,
  };
}
