import qs from "qs";
import React from "react";
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "shared/context/auth-context";
import { useHttpClient } from "shared/hooks/http-hook";
import {
  flattenObject,
  injectFilterOperators,
  setPageSettingsProp,
} from "utils";

//------------------------------------------------------------------------------

export default function useDataList(props) {
  const {
    dataResource,
    entityName,
    columns,
    editable,
    resources,
    hasRowSelection,
    getDisabledRows,
    filterInputs,
    submitSelectedRows,
  } = props;

  const history = useHistory();
  const match = useRouteMatch();
  const auth = React.useContext(AuthContext);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [listData, setListData] = React.useState({ count: 0, rows: [] });
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filtersState, setFiltersState] = React.useState({});
  const [modalOpen, setModalOpen] = React.useState(false);
  const [deleteItemId, setDeleteItemId] = React.useState(null);
  const [activeScreen, setActiveScreen] = React.useState("question");
  const [itemsPerPage, setItemsPerPage] = React.useState(10);
  const [highlightedText, setHighlightedText] = React.useState("");
  const [columnsData, setColumnsData] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [editItemId, setEditItemId] = React.useState(null);

  const [sendGetRequest, isGetLoading] = useHttpClient();
  const [sendDeleteRequest, isDeleteLoading] = useHttpClient();
  const [sendEditRequest, isEditLoading] = useHttpClient();
  const [sendExportRequest, isExportLoading] = useHttpClient();

  const getAll = React.useMemo(() => dataResource.getAll, [dataResource]);

  const getData = React.useCallback(
    async (
      page = currentPage,
      rowsPerPage = rowsPerPage,
      search = searchQuery,
      filters = filtersState
    ) => {
      const processedFilters = filterInputs
        ? injectFilterOperators(filters, filterInputs)
        : undefined;
      try {
        const response = await sendGetRequest(
          getAll(page, rowsPerPage, search, processedFilters)
        );
        setListData({
          count: response.count,
          rows: response.data,
        });
      } catch (err) {
        toast.error("An error has occurred");
      }
    },
    [filterInputs, sendGetRequest, setListData, getAll]
  );

  React.useEffect(() => {
    (async () => {
      try {
        const settings = JSON.parse(localStorage.getItem("settings"));
        const rowsPerPage =
          settings && settings[match.path] && settings[match.path].rowsPerPage
            ? settings[match.path].rowsPerPage
            : 10;

        setItemsPerPage(rowsPerPage);

        let { page, search, filters } = qs.parse(
          window.location.search?.split("?")[1]
        );

        setSearchQuery(search ? search : "");
        const tempFilters = {};
        if (filterInputs) {
          filters = filters ? flattenObject(filters) : {};
          filterInputs.forEach(
            (input, index) =>
              (tempFilters[input.name] = filters[input.name]
                ? filters[input.name]
                : filterInputs[index].defaultValue !== undefined
                ? filterInputs[index].defaultValue
                : null)
          );
          setFiltersState(tempFilters);
        }

        setHighlightedText(search ? search : "");

        if (page) {
          setCurrentPage(parseInt(page));
          await getData(page, rowsPerPage, search, tempFilters);
        } else {
          page = 1;
          history.push({
            pathname: window.location.pathname,
            search: `?page=${page}`,
          });
          await getData(page, rowsPerPage, search, tempFilters);
        }
      } catch (err) {
        toast.error("An error has occurred");
      }
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      try {
        const columnChoicesPromises = columns.map((column) => {
          if (column?.inputProps?.getData) {
            return column.inputProps.getData();
          } else {
            return null;
          }
        });

        const response = await Promise.all(columnChoicesPromises);

        const updatedColumns = response.map((columnChoices, columnIndex) => {
          if (columnChoices) {
            const rawDataConvertor =
              columns[columnIndex]?.inputProps?.rawDataConvertor;

            let temp = [...columnChoices?.data?.data];

            if (rawDataConvertor) {
              temp = [...rawDataConvertor(temp)];
            }

            return {
              ...columns[columnIndex],
              inputProps: {
                ...columns[columnIndex].inputProps,
                data: temp,
                getData: undefined,
              },
            };
          } else {
            return columns[columnIndex];
          }
        });

        setColumnsData(updatedColumns);
      } catch (err) {
        toast.error("An error has occurred");
      }
    })();
  }, []);

  React.useEffect(() => {
    if (listData.count > 0 && !listData.rows.length) {
      const lastPage = Math.ceil(listData.count / itemsPerPage);
      const query = qs.stringify({
        search: searchQuery,
        filters: filtersState,
        page: lastPage,
      });
      history.push({
        pathname: window.location.pathname,
        search: `?${query}`,
      });
      (async () =>
        await getData(lastPage, itemsPerPage, searchQuery, filtersState))();
    }
  }, [listData]);

  React.useEffect(() => {
    if (listData.rows.length) {
      const tempSelectedRows = listData.rows.filter(
        (row) => row.isSelected === true
      );
      setSelectedRows(tempSelectedRows);
    }
  }, [listData, setSelectedRows]);

  const handleSubmit = async (search, filters) => {
    setHighlightedText(search);
    const query = qs.stringify({
      search,
      filters,
      page: 1,
    });
    history.push({
      pathname: window.location.pathname,
      search: `?${query}`,
    });
    await getData(1, itemsPerPage, search, filters);
    setCurrentPage(1);
  };

  const onSearchChange = (updatedSearch) => {
    setSearchQuery(updatedSearch);
  };

  const onFilterChange = (updatedFilters) => {
    setFiltersState(updatedFilters);
  };

  const handleChange = async (pagination, _, sorter) => {
    setPageSettingsProp(`${match.path}.rowsPerPage`, pagination.pageSize);
    setCurrentPage(pagination.current);
    setItemsPerPage(pagination.pageSize);
    const query = qs.stringify({
      search: searchQuery,
      filters: filtersState,
      page: pagination.current,
    });
    history.push({
      pathname: window.location.pathname,
      search: `?${query}`,
    });
    await getData(
      pagination.current,
      pagination.pageSize,
      searchQuery,
      filtersState
    );
  };

  const deleteItem = async () => {
    try {
      await sendDeleteRequest(dataResource.delete(deleteItemId));
      setActiveScreen("success");
    } catch (err) {
      setActiveScreen("error");
    }
  };

  const submitHandler = async (form) => {
    if (hasRowSelection) {
      await submitSelectedRows(selectedRows, listData.rows);
      return;
    }
    try {
      const validatedFields = await form.validateFields();
      let updatedData = [];
      listData.rows.forEach((el, index) => {
        updatedData[index] = { ...el, ...validatedFields[index] };
      });
      try {
        const response = await sendEditRequest(
          dataResource.updateMany(updatedData)
        );
        toast.success(`${entityName} edited successfully`);
      } catch (err) {
        toast.error("An error has occurred");
      }
    } catch (validationError) {
      toast.error("Please fix the errors and submit again");
    }
  };

  const exportToCsv = async () => {
    try {
      const processedFilters = filterInputs
        ? injectFilterOperators(filtersState, filterInputs)
        : undefined;

      await sendExportRequest(dataResource.export(processedFilters));
    } catch (err) {}
  };

  const rowSelectionObject = hasRowSelection
    ? {
        selectedRowKeys: selectedRows.map((row) => row.id),
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedRows(selectedRows);
        },
        getCheckboxProps: getDisabledRows,
      }
    : undefined;

  const isEditable = React.useMemo(
    () => auth.getAccessPermission({ resources, action: "update" }) && editable,
    [auth]
  );

  const handleCreateModalOpen = () => {
    setIsCreateModalOpen(true);
  };

  const handleEditModalOpen = (id) => {
    setIsEditModalOpen(true);
    setEditItemId(id);
  };

  const handleCreateModalClose = () => {
    setIsCreateModalOpen(false);
    getData(currentPage, itemsPerPage, searchQuery, filtersState);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
    getData(currentPage, itemsPerPage, searchQuery, filtersState);
  };

  return {
    currentPage,
    listData,
    searchQuery,
    filtersState,
    modalOpen,
    deleteItemId,
    activeScreen,
    itemsPerPage,
    highlightedText,
    columnsData,
    isGetLoading,
    isDeleteLoading,
    isEditLoading,
    isExportLoading,
    rowSelectionObject,
    isEditable,
    isCreateModalOpen,
    isEditModalOpen,
    editItemId,
    setListData,
    setModalOpen,
    setDeleteItemId,
    setActiveScreen,
    handleSubmit,
    onSearchChange,
    onFilterChange,
    handleChange,
    deleteItem,
    submitHandler,
    exportToCsv,
    getData,
    handleCreateModalOpen,
    handleEditModalOpen,
    handleCreateModalClose,
    handleEditModalClose,
  };
}
