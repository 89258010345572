import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import CustomTable from "components/CustomTable/CustomTable";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useTrucksList from "./TrucksList.hook";
import styles from "./TrucksList.styles";
import CheckPermission from "components/CheckPermission/CheckPermission";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import enums from "enums/index";
import api from "services/api";
import TrucksFormModal from "../TrucksFormModal/TrucksFormModal";
import translations from "constants/translations/index";
import { getDateString } from "utils/index";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function TrucksList(props) {
  const classes = useStyles();

  const { i18n } = useTranslation();

  const { resource } = props;
  const path = "trucks";

  const {
    currentPage,
    listData,
    searchQuery,
    filtersState,
    itemsPerPage,
    highlightedText,
    isGetLoading,
    isAssignModalOpen,
    editedTruck,
    onAssignModalClose,
    onSearchChange,
    onFilterChange,
    handleChange,
    handleSubmit,
    handleAssignItem,
    handleRemoveItem,
  } = useTrucksList({
    resource,
  });

  const columns = React.useMemo(
    () => [
      {
        dataIndex: "truckNumber",
        title: "Truck Number",
        canBeHighlighted: true,
      },
      {
        dataIndex: ["truckType", "name"],
        title: "Truck Type",
      },
      {
        dataIndex: ["storage", "warehouseCode"],
        title: "Warehouse Code",
      },
      {
        dataIndex: "status",
        title: "Status",
        getData: (data) => {
          if (data) return translations["en"].truckStatus[data];
          else return "-";
        },
      },
      {
        dataIndex: "licenseExpirationDate",
        title: "License Expiry Date",
        render: (data) => (data ? getDateString(data) : "-"),
      },

      {
        title: "",
        render: (_, record) => {
          return (
            <div className={classes.actionsContainer}>
              <CheckPermission action={enums.Action.UPDATE}>
                <Button
                  justIcon
                  edit
                  color="white"
                  textColor="success"
                  onClick={() => handleAssignItem(record)}
                />
              </CheckPermission>
              {/* <CheckPermission action={enums.Action.DELETE}>
                <ConfirmationButton
                  name="this item"
                  entityName="Item"
                  onConfirm={() => handleRemoveItem(record)}
                  // onSuccess={() => onRemoveModalClose(record)}
                  buttonProps={{
                    justIcon: true,
                  }}
                />
              </CheckPermission> */}
            </div>
          );
        },
      },
    ],
    [listData]
  );

  return (
    <div>
      {isAssignModalOpen && (
        <TrucksFormModal
          isOpen={isAssignModalOpen}
          onClose={onAssignModalClose}
          create={!editedTruck}
          edit={!!editedTruck}
          truck={editedTruck}
          path={path}
        />
      )}
      <div className={classes.topContainer}>
        <div className={classes.buttonsContainer}>
          <Button create color="white" onClick={() => handleAssignItem()} />
        </div>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTable
            columns={columns}
            data={listData.rows}
            onChange={handleChange}
            pagination={{
              total: listData.count,
              current: currentPage,
              pageSize: itemsPerPage,
              position: ["topRight"],
              pageSizeOptions: [10, 20, 50],
            }}
            loading={isGetLoading}
            highlightedText={highlightedText}
            filterProps={{
              value: filtersState,
              onChange: onFilterChange,
            }}
            searchProps={{
              onChange: onSearchChange,
              value: searchQuery,
              placeholder: "Search",
            }}
            handleSubmit={handleSubmit}
            title="Trucks Summary"
            addIndexColumn
            size="middle"
            fixIndexColumn
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

TrucksList.propTypes = {
  resource: PropTypes.string,
};
