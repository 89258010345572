import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModal/CustomModal";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import useCompleteDriverShiftModal from "./CompleteDriverShiftModal.hook";
import styles from "./CompleteDriverShiftModal.styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router";
import OrderTransfersApprovalList from "./components/OrderTransferApprovalList/OrderTransfersApprovalList";
import { toast } from "react-toastify";
import ReviewTruckInventoryAndEndOfShiftTransfers from "./components/ReviewTruckInventoryAndEndOfShiftTransfers/ReviewTruckInventoryAndEndOfShiftTransfers";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import InvoicesAndReportsList from "./components/ReviewingInvoicesAndReports/InvoicesAndReportsList";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function CompleteDriverShiftModal(props) {
  const {
    isOpen,
    onClose,
    successCallback,
    driverShift,
    updateDriverShiftStatus,
  } = props;

  const classes = useStyles();

  const { i18n } = useTranslation();

  const {
    isLoading,
    handleGeneratingReportAndInvoice,
    isLoadingGenerateInvoiceRequest,
    isLoadingGenerateReportRequest,
    setCheckOnProcessedOrders,
    checkOnProcessedOrders,
  } = useCompleteDriverShiftModal({
    onClose,
    successCallback,
    driverShift,
  });
  const steps = [
    "Process Orders",
    "View Truck Inventory",
    "Review Shift Details",
  ];
  const [activeStep, setActiveStep] = React.useState(0);
  const history = useHistory();

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <OrderTransfersApprovalList
            driverShift={driverShift}
            setActiveStep={setActiveStep}
            checkOnProcessedOrders={checkOnProcessedOrders}
            setCheckOnProcessedOrders={setCheckOnProcessedOrders}
          />
        );
        break;
      case 1:
        return (
          <ReviewTruckInventoryAndEndOfShiftTransfers
            selectedTruck={driverShift?.assignedTruck}
            driverShift={driverShift}
          />
        );
        break;
      case 2:
        return <InvoicesAndReportsList driverShift={driverShift} />;
        break;
      default:
        return "Unknown step";
    }
  }

  const handleNext = async () => {
    if (activeStep === 0) {
      setCheckOnProcessedOrders(true);
    } else if (activeStep === 1) {
      try {
        await handleGeneratingReportAndInvoice();
        updateDriverShiftStatus(driverShift?.id, successCallback);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } catch (error) {
        toast.error("An error has occurred");
      }
    } else if (activeStep === steps.length - 1) {
      onClose();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ margin: 0 }}>
            <CardBody>
              <div className={classes.formHeader}>
                <React.Fragment>
                  <EditIcon className={classes.icon} /> Complete Driver Shift
                </React.Fragment>
              </div>

              <div className={classes.root}>
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                      <StepContent>
                        <Typography>{getStepContent(index)}</Typography>
                        <div className={classes.actionsContainer}>
                          <div>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleNext}
                              className={classes.button}
                              type="submit"
                              loading={
                                isLoading ||
                                isLoadingGenerateReportRequest ||
                                isLoadingGenerateInvoiceRequest
                              }
                            >
                              {activeStep === steps.length - 1
                                ? "Finish"
                                : "Next"}
                            </Button>
                          </div>
                        </div>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
                {activeStep === steps.length && (
                  <Paper
                    square
                    elevation={0}
                    className={classes.resetContainer}
                  >
                    <Typography>
                      All steps completed - you&apos;re finished
                    </Typography>
                    <Button onClick={handleReset} className={classes.button}>
                      Reset
                    </Button>
                  </Paper>
                )}
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </CustomModal>
  );
}

CompleteDriverShiftModal.defaultProps = {
  onClose: () => {},
};

CompleteDriverShiftModal.propTypes = {
  onClose: PropTypes.func,
};
