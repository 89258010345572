import { makeStyles } from "@material-ui/core/styles";
import CheckPermission from "components/CheckPermission/CheckPermission";
import Button from "components/CustomButtons/Button.js";
import enums from "enums/index";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { replaceUrlSearchParam } from "utils";
import styles from "./tabsStyle";
import { AuthContext } from "shared/context/auth-context";

const useStyles = makeStyles(styles);

export default function Tabs(props) {
  const {
    tabs,
    defaultTab,
    uriParamName,
    buttonsContainerClasses,
    value,
    onChange,
  } = props;
  const classes = useStyles();

  const [activeTab, setActiveTab] = React.useState(0);

  const auth = React.useContext(AuthContext);

  const authorizedTabs = React.useMemo(() => {
    return tabs.map((el) => {
      if (el.resource) {
        return auth.getAccessPermission({
          resources: [el.resource],
          action: el.action || enums.Action.READ,
        })
          ? el
          : { name: null, component: null };
      }
      return el;
    });
  }, [tabs, auth]);

  const location = useLocation();

  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const tabQuery = searchParams.get(uriParamName ? uriParamName : "tab");

    let tabIndex = 0;

    if (
      !isNaN(parseInt(tabQuery)) &&
      parseInt(tabQuery) >= 0 &&
      parseInt(tabQuery) < tabs.length
    ) {
      tabIndex = parseInt(tabQuery);
    } else {
      tabIndex = defaultTab ? defaultTab : 0;
    }

    setActiveTab(tabIndex);
    if (onChange) onChange(tabIndex);
  }, []);

  React.useEffect(() => {
    if (value !== undefined) {
      setActiveTab(value);
    }
  }, [value]);

  return (
    <div>
      <div className={classes.tabsContainer + " " + buttonsContainerClasses}>
        {authorizedTabs.map(
          (el, index) =>
            el.component && (
              <Link
                key={index}
                onClick={() => {
                  if (onChange) {
                    onChange(index);
                    setActiveTab(index);
                  }
                }}
                to={{
                  pathname: location.pathname,
                  search: `?${replaceUrlSearchParam(
                    window.location.search,
                    uriParamName ? uriParamName : "tab",
                    index
                  )}`,
                }}
              >
                <Button
                  className={classes.tabButton}
                  color="transparent"
                  textColor={activeTab === index ? "primary" : "lightGray"}
                  style={{
                    borderBottom: activeTab === index ? "2px solid" : "none",
                    marginBottom: activeTab === index ? -1 : 1,
                  }}
                >
                  {el.name}
                </Button>
              </Link>
            )
        )}
      </div>
      <React.Fragment>
        {authorizedTabs.map((el, index) =>
          activeTab === index ? (
            <React.Fragment key={index}>{el.component}</React.Fragment>
          ) : null
        )}
      </React.Fragment>
    </div>
  );
}
