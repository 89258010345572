const styles = (theme) => ({
  upperContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  cardRoot: {
    borderRadius: 10,
    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
    border: "1px solid #d7d7d7",
  },
  pageHeader: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 30px",
    paddingBottom: 0,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      padding: 10,
    },
  },
  infoContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    fontSize: 20,
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      margin: 0,
    },
  },
  tabsContainer: {
    borderBottom: "1px solid #ddd",
    margin: "30px -20px",
    marginBottom: 0,
    width: "calc(100% + 40px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 0,
      marginTop: 20,
      padding: 0,
    },
  },
  infoWithIcon: {
    display: "flex",
    alignItems: "center",
  },
  personIcon: {
    marginRight: 8,
    fontSize: 80,
    color: "#6f7f88",
    [theme.breakpoints.down("sm")]: {
      fontSize: 60,
    },
  },
  orderStatusButton: {
    marginLeft: 10,
  },
  headerInfoContainer: {
    fontSize: 20,
    display: "flex",
    flexDirection: "column",
    fontWeight: 500,
    // textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  subtitle: {
    fontSize: 16,
    marginTop: 5,
    fontWeight: 400,
    color: "#888",
  },
  //green color: #4caf50

  buttonsContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",

    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      marginTop: 15,
    },
  },
});

export default styles;
