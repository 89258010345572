import { makeStyles } from "@material-ui/core/styles";
import CustomTable from "components/CustomTable/CustomTable";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useInvoicesList from "./InvoicesList.hook";
import styles from "./InvoicesList.styles";
import InvoicesStatusButtons from "./components/InvoicesStatusButtons";
import FileCard from "components/InfoWithLabel/FileCard/FileCard";
import { displayDriverShift, getDateString } from "utils/index";
import { Tag } from "antd";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function InvoicesList(props) {
  const classes = useStyles();

  const { i18n } = useTranslation();

  const { resource } = props;

  const {
    currentPage,
    listData,
    searchQuery,
    filtersState,
    itemsPerPage,
    highlightedText,
    isGetLoading,
    onSearchChange,
    onFilterChange,
    handleChange,
    handleSubmit,
    updateInvoiceState,
  } = useInvoicesList({
    resource,
  });

  const columns = React.useMemo(
    () => [
      {
        title: "Invoice Date",
        dataIndex: "createdAt",
        getData: (data) => (data ? getDateString(data, "en-EG", true) : "-"),
        width: 100,
      },
      {
        render: (_, record) => {
          return record?.invoiceUrl ? (
            <div>
              <FileCard
                name={`${getDateString(record.createdAt, "en-EG", true)}.xlsx`}
                url={record?.invoiceUrl}
              />
            </div>
          ) : (
            <div>
              <Tag color="red">No completed orders in this date</Tag>
            </div>
          );
        },
        title: "Invoice File",
        width: 200,
      },

      {
        title: "Driver Shift",
        render: (_, record) => {
          return displayDriverShift(record?.driverShift);
        },
        width: 170,
      },
      {
        title: "",
        render: (_, record) => {
          return (
            <div style={{ display: "flex", width: "fit-content" }}>
              <InvoicesStatusButtons
                invoiceId={record?.id}
                isUploaded={record?.isUploaded}
                successCallback={updateInvoiceState}
              />
            </div>
          );
        },
        width: 100,
      },
    ],
    [listData]
  );

  return (
    <div>
      <div className={classes.topContainer}>
        <div className={classes.buttonsContainer}>
          {/* <CheckPermission action={enums.Action.CREATE}>
            <Link to={`/admin/orders/create`}>
              <Button create color="white" />
            </Link>
          </CheckPermission> */}
        </div>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTable
            columns={columns}
            data={listData.rows}
            onChange={handleChange}
            pagination={{
              total: listData.count,
              current: currentPage,
              pageSize: itemsPerPage,
              position: ["topRight"],
              pageSizeOptions: [10, 20, 50],
            }}
            loading={isGetLoading}
            highlightedText={highlightedText}
            handleSubmit={handleSubmit}
            title="Invoices Summary"
            addIndexColumn
            size="middle"
            fixIndexColumn
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

InvoicesList.propTypes = {
  resource: PropTypes.string,
};
