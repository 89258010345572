import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Skeleton from "@material-ui/lab/Skeleton";

import Button from "components/CustomButtons/Button.js";
import FormFieldGridItem from "components/Forms/FormField/FormFieldGridItem";
import GridContainer from "components/Grid/GridContainer.js";
import Loading from "components/Loading/Loading";
import PropTypes from "prop-types";
import api from "services/api";
import { useTrucksForm } from "./TrucksForm.hook";
import styles from "./TrucksForm.styles";
import React from "react";
import GridItem from "components/Grid/GridItem";
import FormField from "components/Forms/FormField/FormField";
import Info from "components/Typography/Info";
import InfoWithLabel from "components/InfoWithLabel/InfoWithLabel";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function TrucksForm(props) {
  const { edit, create, truck } = props;

  const {
    isLoading,
    isGetLoading,
    form,
    data,
    watch,
    onSubmit,
  } = useTrucksForm(props);

  const classes = useStyles();

  return (
    <div className={classes.formRoot}>
      <div className={classes.formHeader}>
        {create ? (
          <AddIcon className={classes.icon} />
        ) : (
          <EditIcon className={classes.icon} />
        )}
        {edit ? (
          isGetLoading ? (
            <Skeleton variant="rect" height={25} width={250} />
          ) : (
            `Edit ${truck?.truckNumber}'s Truck`
          )
        ) : (
          `Create Truck`
        )}
      </div>

      <Loading loading={isGetLoading} style={{ height: "60vh" }}>
        <form
          style={{ padding: 40, paddingBottom: 0, paddingTop: 0 }}
          onSubmit={onSubmit}
          autoComplete="off"
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <FormField
                name="truckNumber"
                md={12}
                fetchedName="truckNumber"
                type="text"
                label="Truck Number"
                form={form}
              />
              <FormField
                name="truckType"
                fetchedName="truckTypeId"
                type="autocomplete"
                label="Truck Type"
                form={form}
                options={{
                  getData: api.truckTypes.getAll,
                  getOptionLabel: (option) => option.name,
                  getSubmitValue: (option) => option?.id,
                  loadOnFocus: true,
                  blurOnSelect: false,
                }}
              />
              <FormField
                md={12}
                name="licenseDocumentUrl"
                type="dropzone"
                label="License Document"
                options={{ maxFiles: 1 }}
                form={form}
              />
              <FormField
                name="storage.warehouseCode"
                md={12}
                fetchedName="storage.warehouseCode"
                type="text"
                label="Warehouse Code"
                form={form}
              />
              <FormField
                name="licenseExpirationDate"
                type="date"
                label="License Expiry Date"
                form={form}
              />
              <FormField
                name="status"
                type="select"
                label="Status"
                form={form}
                options={{
                  enum: "truckStatus",
                }}
              />
            </GridItem>
          </GridContainer>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="primary"
              type="submit"
              loading={isLoading}
              style={{ margin: "20px 0" }}
              create={create}
              edit={edit}
              loadFromRight
            />
          </div>
        </form>
      </Loading>
    </div>
  );
}

TrucksForm.propTypes = {
  edit: PropTypes.bool,
  create: PropTypes.bool,
};
