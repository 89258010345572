import { makeStyles } from "@material-ui/core/styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import CheckPermission from "components/CheckPermission/CheckPermission";
import Button from "components/CustomButtons/Button.js";
import CustomTable from "components/CustomTable/CustomTable";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import translations from "constants/translations/index";
import enums from "enums/index";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getDateString } from "utils/index";
import useDriversShiftsListPage from "./DriverShiftsList.hook";
import styles from "./DriverShiftsList.styles";
import api from "services/api";
import DriverShiftButtons from "./components/DriverShiftButtons/DriverShiftButtons";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function DriverShiftsList(props) {
  const classes = useStyles();

  const { i18n } = useTranslation();

  const { resource } = props;

  const filterInputs = useMemo(
    () => [
      {
        name: "status",
        label: "Status",
        type: "select",
        options: {
          data: Object.keys(translations[i18n.language].driverShiftStatus).map(
            (key) => ({
              value: key,
              name: translations["en"].driverShiftStatus[key],
            })
          ),
          addAllChoice: true,
        },
      },
      {
        name: "createdAt",
        label: "Created At",
        type: "date",
      },

      {
        name: "truckIds",
        label: "Truck",
        type: "autocomplete",
        options: {
          getData: api.trucks.getAll,
          getOptionLabel: (option) => option?.truckNumber,
          getSubmitValue: (option) => option?.id,
          blurOnSelect: false,
          loadOnFocus: true,
          multiple: true,
        },
      },
      {
        name: "driverIds",
        label: "Driver",
        type: "autocomplete",
        options: {
          getData: api.drivers.getAll,
          getOptionLabel: (option) => option?.name,
          getSubmitValue: (option) => option?.id,
          blurOnSelect: false,
          loadOnFocus: true,
          multiple: true,
        },
      },

      {
        name: "createdByIds",
        label: "Created By",
        type: "autocomplete",
        options: {
          getData: api.users.getAll,
          getOptionLabel: (option) => option?.name,
          getSubmitValue: (option) => option?.id,
          blurOnSelect: false,
          loadOnFocus: true,
          multiple: true,
        },
      },
    ],
    []
  );

  const {
    currentPage,
    listData,
    searchQuery,
    filtersState,
    itemsPerPage,
    highlightedText,
    isGetLoading,

    onSearchChange,
    onFilterChange,
    handleChange,
    handleSubmit,
    updateShiftState,
  } = useDriversShiftsListPage({
    resource,
    filterInputs,
  });

  const columns = React.useMemo(
    () => [
      {
        title: "Created At",
        dataIndex: "startedAt",
        getData: (data) => (data ? getDateString(data, "en-EG", true) : "-"),
        width: 200,
      },
      {
        title: "Created By",
        dataIndex: ["createdBy", "name"],
      },

      {
        title: "Driver Name / Phone",
        dataIndex: "assignedDriver",
        render: (_, record) => {
          let driver = "";
          if (record.assignedDriver) {
            driver += record.assignedDriver.name;
            driver += ` / ${record.assignedDriver.phone}`;
          }
          return driver ? driver : "-";
        },

        width: 200,
        canBeHighlighted: true,
      },

      {
        title: "Truck Number",
        dataIndex: "assignedTruck",
        render: (_, record) => {
          let truck = "";
          if (record.assignedTruck) {
            truck += record?.assignedTruck?.truckNumber;
          }
          return truck ? truck : "-";
        },

        width: 200,
      },
      {
        title: "Status",
        dataIndex: "status",
        getData: (data) => {
          if (data) return translations["en"].driverShiftStatus[data];
          else return "-";
        },
        width: 100,
      },
      {
        title: "Ended At",
        dataIndex: "endedAt",
        getData: (data) => (data ? getDateString(data, "en-EG", true) : "-"),
        width: 200,
      },
      {
        title: "",
        render: (_, record) => {
          return (
            <div style={{ display: "flex", width: "fit-content" }}>
              <DriverShiftButtons
                driverShiftId={record?.id}
                driverShiftStatus={record?.status}
                driverShift={record}
                successCallback={updateShiftState}
              />
              <CheckPermission action={enums.Action.READ}>
                <Link to={`/admin/driver-shifts/${record?.id}`}>
                  <Button
                    color="white"
                    textColor="primary"
                    className={classes.viewButton}
                  >
                    <NavigateNextIcon className={classes.nextIcon} />
                  </Button>
                </Link>
              </CheckPermission>
            </div>
          );
        },
        width: 200,
        fixed: "right",
      },
    ],
    [listData, updateShiftState]
  );

  return (
    <div>
      <div className={classes.topContainer}>
        <div className={classes.buttonsContainer}>
          <CheckPermission action={enums.Action.CREATE}>
            <Link to={`/admin/driver-shifts/create`}>
              <Button create color="white" />
            </Link>
          </CheckPermission>
        </div>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTable
            columns={columns}
            data={listData.rows}
            onChange={handleChange}
            pagination={{
              total: listData.count,
              current: currentPage,
              pageSize: itemsPerPage,
              position: ["topRight"],
              pageSizeOptions: [10, 20, 50],
            }}
            loading={isGetLoading}
            highlightedText={highlightedText}
            filterProps={{
              value: filtersState,
              onChange: onFilterChange,
              inputs: filterInputs,
            }}
            searchProps={{
              onChange: onSearchChange,
              value: searchQuery,
              placeholder: "Search",
            }}
            handleSubmit={handleSubmit}
            title="Driver Shifts Summary"
            addIndexColumn
            size="middle"
            fixIndexColumn
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

DriverShiftsList.propTypes = {
  resource: PropTypes.string,
};
