import enums from "enums/index";
import React from "react";
import StockStats from "views/StockManagement/StockStats/StockStats";

// appear in breadcrumbs and page headers
const listPageName = "Stock Statistics";

const layout = "/admin";

const path = "stock-stats";

const resource = [
  enums.Resource.STOCK_MANAGEMENT,
  enums.Resource.STOCK_MANAGEMENT_STOCK_STATISTICS,
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: "equalizer",
  render: () => <StockStats />,
  action: "read",
  layout: layout,
  resource,
};
