import DataList from "components/DataList/DataList";
import FormContainer from "components/Forms/FormContainer/FormContainer";
import enums from "enums/index";
import api from "services/api";
import React from "react";

import WarehousesList from "views/TrucksManagement/Warehouses/WarehousesList/WarehousesList";

// appear in breadcrumbs and page headers
const listPageName = "Warehouses";
const createPageName = "Create Warehouse";
const editPageName = "Edit Warehouse";

// appears on alerts; example: Entity created successfully
const entityName = "Warehouse";

const layout = "/admin";

const path = "warehouses";

// const formStructure = warehouseForm;

const dataResource = api.warehouses;

const resource = [
  enums.Resource.STOCK_MANAGEMENT,
  enums.Resource.STOCK_MANAGEMENT_WAREHOUSES,
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: "warehouse",
  render: () => <WarehousesList />,
  action: "read",
  layout: layout,
  superAdmin: true,
  resource,
};
