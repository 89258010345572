import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModal/CustomModal";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import api from "services/api";
import styles from "./CompleteOrderModal.styles";
import useCompleteOrderModal from "./CompleteOrderModal.hook";
import FormFieldGridItem from "components/Forms/FormField/FormFieldGridItem";
import SerializedItems from "./components/SerializedItems/SerializedItems";
import NonSerializedItems from "./components/NonSerializedItems/NonSerializedItems";
import ScrapItems from "../../OrderDetailsForm/components/ScrapItems/ScrapItems";
import { displayDriverShift, getDateStringDashSeparated } from "utils/index";
import enums from "enums/index";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function CompleteOrderModal(props) {
  const { isOpen, onClose, orderId, successCallback, order } = props;

  const classes = useStyles();

  const { i18n } = useTranslation();

  const {
    isLoading,
    submitHandler,
    form,
    actualDeliveryCity,
    onError,
    handleSubmit,
  } = useCompleteOrderModal({
    onClose,
    orderId,
    successCallback,
    order,
  });
  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ margin: 0 }}>
            <CardBody>
              <div className={classes.formHeader}>
                <React.Fragment>
                  <EditIcon className={classes.icon} /> Complete Order
                </React.Fragment>
              </div>
              <form
                style={{ padding: 20, paddingBottom: 0, marginRight: -60 }}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(submitHandler, onError)();
                }}
                autoComplete="off"
              >
                <GridContainer style={{ marginBottom: 60 }}>
                  <FormFieldGridItem
                    name="orderRating"
                    type="select"
                    label="Order Rating"
                    form={form}
                    options={{
                      data: ["No Rating", "1", "2", "3", "4", "5"],
                    }}
                  />
                  <FormFieldGridItem
                    name="actualDeliveryTime"
                    type="datetime-local"
                    label="Actual Delivery Date"
                    form={form}
                  />
                  <FormFieldGridItem
                    name="actualDeliveryCoordinates"
                    type="text"
                    label="Actual Delivery Coordinates (lat,lng)"
                    form={form}
                  />
                  <FormFieldGridItem
                    name="actualDeliveryStreet"
                    type="text"
                    label="Actual Delivery Street"
                    form={form}
                  />
                  <FormFieldGridItem
                    name="actualDeliveryCity"
                    type="autocomplete"
                    label="Actual Delivery City"
                    form={form}
                    options={{
                      getData: api.cities.getAll,
                      getOptionLabel: (option) => option.name,
                      getSubmitValue: (option) => option?.id,
                      loadOnFocus: true,
                      blurOnSelect: false,
                    }}
                  />
                  <FormFieldGridItem
                    name="actualDeliveryDistrict"
                    type="autocomplete"
                    label="Actual Delivery District"
                    form={form}
                    options={{
                      getData: api.districts.getAll,
                      getOptionLabel: (option) => option.name,
                      getSubmitValue: (option) => option?.id,
                      filters: { cityId: actualDeliveryCity?.id },
                      loadOnFocus: true,
                      blurOnSelect: false,
                      disabled: !actualDeliveryCity?.id,
                      disabledToolTipText: "Please select a city first",
                    }}
                  />

                  <FormFieldGridItem
                    name="driverShift"
                    type="autocomplete"
                    label="Driver Shift"
                    options={{
                      getData: api.driverShifts.getAll,
                      getOptionLabel: (option) => displayDriverShift(option),
                      getSubmitValue: (option) => option?.id,
                      filters: {
                        status: enums.DriverShiftStatus.ACTIVE,
                      },
                      blurOnSelect: false,
                      loadOnFocus: true,
                    }}
                    form={form}
                  />
                </GridContainer>
                <SerializedItems form={form} order={order} />
                <NonSerializedItems form={form} order={order} />
                <ScrapItems form={form} order={order} editable={false} />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    color="primary"
                    type="submit"
                    loading={isLoading}
                    style={{ margin: "20px 0", marginRight: 70 }}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </CustomModal>
  );
}

CompleteOrderModal.defaultProps = {
  onClose: () => {},
};

CompleteOrderModal.propTypes = {
  onClose: PropTypes.func,
};
