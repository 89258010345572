import * as yup from "yup";

//----------------------------------------------------------

export default () => {
  return yup.object().shape({
    serialNumbersSheet: yup
      .mixed()
      .required("Serial Numbers Sheet is required"),
  });
};
