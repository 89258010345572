import DriveEtaIcon from '@material-ui/icons/DriveEta';
import carMakersRoutes from './carMakersRoutes';
import carModelsRoutes from './carModelsRoutes';
import enums from 'enums/index';

export default {
  name: "Car Types",
  icon: DriveEtaIcon,
  layout: "/admin",
  collapse: true,
  state: "carTypesCollapse",
  resource: enums.Resource.CAR_TYPES,
  superAdmin: true,
  views: [carMakersRoutes, carModelsRoutes],
};
