import enums from "enums/index";
import * as yup from "yup";

//----------------------------------------------------------

export default () => {
  return yup.object({
    product: yup.mixed().required(),
    from: yup.mixed().when("transferType", {
      is: (transferParty) =>
        transferParty?.id ===
          enums.ValidStockTransfersTypes.INTERNAL_TRANSFER ||
        transferParty?.id ===
          enums.ValidStockTransfersTypes.LOSING_STOCK_FROM_STORAGE,
      then: yup.mixed().required("Required"),
    }),
    to: yup.mixed().when("transferType", {
      is: (transferParty) =>
        transferParty?.id ===
          enums.ValidStockTransfersTypes.INTERNAL_TRANSFER ||
        transferParty?.id ===
          enums.ValidStockTransfersTypes.ADDING_STOCK_TO_STORAGE,
      then: yup.mixed().required("Required"),
    }),
    amount: yup.number().required(),

    apInvoiceNumber: yup.mixed().when("transferType", {
      is: (transferParty) =>
        transferParty?.id ===
        enums.ValidStockTransfersTypes.ADDING_STOCK_TO_STORAGE,
      then: yup.mixed().required("Required"),
    }),
    transferType: yup.mixed().required("Required"),
  });
  // .test(
  //   "at-least-one-of-from-or-to",
  //   'At least one of "from" or "to" must be present',
  //   function (value) {
  //     const { from, to } = value;

  //     if (!from && !to) {
  //       return this.createError({
  //         path: "from",
  //         message: 'At least one of "from" or "to" must be present',
  //       });
  //     }

  //     return true;
  //   }
  // );
};
