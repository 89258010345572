import enums from "enums/index";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import EditMatchingInfoValidation from "./EditMatchingInfo.validation";
import { useEffect } from "react";

//-------------------------------------------------------------------------------------

export default function useEditMatchingInfoModal({
  onClose,
  successCallback,
  order,
}) {
  const [sendRequest, isLoading] = useHttpClient(true);

  const { i18n } = useTranslation();

  const resolver = useYupValidationResolver(EditMatchingInfoValidation());

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const { handleSubmit, getValues, setValue, watch } = form;

  const assignedDriverId = watch("assignedDriver")?.id;

  useEffect(() => {
    if (assignedDriverId) {
      const defaultTruck = watch("assignedDriver").defaultTruck;
      setValue("assignedTruck", defaultTruck);
    }
  }, [assignedDriverId]);

  useEffect(() => {
    if (order) {
      const tempOrder = { ...order };

      setValue("assignedDriver", tempOrder?.assignedDriver);
      setValue("assignedTruck", tempOrder?.assignedTruck);
    }
  }, [order]);
  const submitHandler = async () => {
    let values = getValues();

    //

    const requestData = {
      ...order,
      orderId: order.id,
      assignedDriverId: values.assignedDriver?.id,
      assignedTruckId: values.assignedTruck?.id,
    };
    delete values.assignedTruck;
    delete values.assignedDriver;

    try {
      const updatedOrder = await sendRequest(api.orders.patch(requestData));
      toast.success(`Order Matching Info updated successfully`);
      successCallback(updatedOrder);
      onClose();
    } catch {}
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    isLoading,
    i18n,
    form,
    submitHandler,
    onError,
    handleSubmit,
  };
}
