import * as yup from "yup";

//----------------------------------------------------------

export default () => {
  return yup.object().shape({
    actualDeliveryTime: yup.date().required("Required"),
    actualDeliveryCity: yup.mixed().required("Required"),
    actualDeliveryDistrict: yup.mixed().required("Required"),
    actualDeliveryCoordinates: yup
      .mixed()
      .test(
        "isValidCoordinates",
        "Invalid coordinates format it should be in lat,lng format",
        (value) => {
          // Check if the input is separated by commas
          if (value) {
            const coordinates = value.split(",");

            return (
              coordinates.length === 2 &&
              /^\d/.test(coordinates[0]) &&
              /\d$/.test(coordinates[1])
            );
          }
          return false;
        }
      )
      .required("Required"),
    actualDeliveryStreet: yup.mixed().required("Required"),
    orderRating: yup.mixed().required("Required"),
    driverShift: yup.mixed().required("Required"),
    serializedItems: yup
      .array()
      // .of(
      //   yup.object().shape({
      //     serialNumbers: yup
      //       .array()
      //       .min(1)
      //       .of(yup.string().required("Required")),
      //   })
      // )
      .test(
        "stock items validation",
        "Please add all serial numbers.",

        (values) => {
          if (!values) return false;

          for (let i = 0; i < values.length; i++) {
            if (
              !values[i].serialNumbers ||
              values[i].serialNumbers.length === 0
            ) {
              return false;
            }
          }

          return true;
        }
      ),
  });
};
