import enums from "enums/index";
import { get, values } from "lodash";
import React, { useEffect, useState } from "react";

//-------------------------------------------------------------------------------------

export default function useSerializedItems({ form }) {
  const [isAssignModalOpen, setIsAssignModalOpen] = React.useState(false);
  const [editedItem, setEditedItem] = React.useState(null);
  const { handleSubmit, getValues, setValue } = form;

  const [stockItems, setStockItems] = useState([]);

  const handleAssignItem = React.useCallback(
    (data) => {
      setEditedItem(data);
      setIsAssignModalOpen(true);
    },
    [setIsAssignModalOpen, setEditedItem]
  );

  const onAssignModalClose = React.useCallback(() => {
    setIsAssignModalOpen(false);
    setEditedItem(null);
  }, [setIsAssignModalOpen, setEditedItem]);

  const onRemoveModalClose = React.useCallback(
    (record) => {
      setStockItems((prevItems) => {
        const newItems = prevItems.filter((item) => item.key !== record.key);
        setValue("items", newItems);
        return newItems;
      });
    },
    [stockItems]
  );

  const onEdit = (serialNumbers, itemKey) => {
    const oldSerializedItems = form.watch("serializedItems");
    const newSerializedItems = oldSerializedItems.map((item) => {
      if (item.id === itemKey) {
        return { ...item, serialNumbers };
      } else return item;
    });
    setValue("serializedItems", newSerializedItems);
  };

  return {
    isAssignModalOpen,
    editedItem,
    stockItems,
    onAssignModalClose,
    handleAssignItem,
    onRemoveModalClose,
    // onAdd,
    onEdit,
  };
}
