import api from "services/api";
import * as yup from "yup";

export default [
  {
    column: 1,
    fields: [
      {
        name: "spend",
        type: "number",
        label: "Amount (USD)",
        validation: yup.mixed().required("Required"),
      },
      {
        name: "channelId",
        fetchedName: "channel",
        type: "autocomplete",
        label: "Channel",
        options: {
          getData: api.marketingSpendChannel.getAll,
          getOptionLabel: (option) => option.name,
          getSubmitValue: (option) => option?.id,
          loadOnFocus: true,
          blurOnSelect: false,
        },
        validation: yup.mixed().required("Required"),
      },
      {
        name: "date",
        type: "date",
        label: "Date",
        validation: yup.mixed().required("Required"),
      },
      {
        name: "notes",
        type: "text",
        label: "Notes",
      },
    ],
  },
];
