import StockTransfersApprovalList from "views/StockManagement/StockTransfersApproval/pages/StockTransferApprovalList/StockTransfersApprovalList";
import React from "react";
import enums from "enums/index";

// appear in breadcrumbs and page headers
const listPageName = "Stock Transfers Approval";

const layout = "/admin";

const path = "stock-transfers";

const resource = [
  enums.Resource.STOCK_MANAGEMENT,
  enums.Resource.STOCK_MANAGEMENT_STOCK_TRANSFERS_APPROVAL,
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: "checklist",
  render: () => <StockTransfersApprovalList />,
  action: "read",
  resource,
  layout: layout,
  randomKey: true,
};
