import translations from "constants/translations/index";
import api from "services/api";
import * as yup from "yup";
import manufacturersForm from "./manufacturersForm";
import sizeForm from "./sizeForm";

export default [
  {
    column: 1,
    fields: [
      {
        name: "sapCode",
        type: "text",
        label: "SAP Code",
        validation: yup.mixed(),
      },
      {
        name: "group",
        type: "select",
        label: "Group",
        options: {
          data: Object.keys(translations["en"].productCategory).map((key) => ({
            value: key,
            name: translations["en"].productCategory[key],
          })),
        },
      },
      {
        name: "type",
        type: "select",
        label: "Type",
        options: {
          data: Object.keys(translations["en"].productType).map((key) => ({
            value: key,
            name: translations["en"].productType[key],
          })),
        },
        validation: yup.mixed().required("Required"),
      },
      {
        name: "description",
        type: "text",
        label: "Description",
        validation: yup.mixed(),
        options: {
          multiline: true,
          rows: 5,
        },
      },
      {
        name: "sizeId",
        fetchedName: "size",
        type: "autocomplete",
        label: "Size",
        options: {
          getData: api.sizes.getAll,
          getOptionLabel: (option) => option?.name,
          getSubmitValue: (option) => option?.id,
          addNew: true,
          formStructure: sizeForm,
          freeSolo: true,
          dataResource: api.sizes,
          blurOnSelect: false,
          loadOnFocus: true,
        },
      },
      {
        name: "unitPrice",
        type: "number",
        label: "Unit Price",
        validation: yup.mixed().required("Required"),
      },
      {
        name: "unitCost",
        type: "number",
        label: "Unit Cost",
        validation: yup.mixed(),
      },
      {
        name: "manufacturerId",
        fetchedName: "manufacturer",
        type: "autocomplete",
        label: "Manufacturer",
        options: {
          getData: () => api.manufacturers.getAll(),
          getOptionLabel: (option) => option.name,
          getSubmitValue: (option) => option?.id,
          addNew: true,
          formStructure: manufacturersForm,
          freeSolo: true,
          dataResource: api.manufacturers,
          blurOnSelect: false,
        },
        validation: yup.mixed(),
      },
      {
        name: "serialRequired",
        type: "boolean",
        label: "Is Serial Required?",
        validation: yup.mixed(),
      },
    ],
  },
];
