import DataList from "components/DataList/DataList";
import FormContainer from "components/Forms/FormContainer/FormContainer";
import enums from "enums/index";
import React from "react";
import api from "services/api";
import orderIssueTypeForm from "views/Settings/orderIssueTypeForm";

// appear in breadcrumbs and page headers
const listPageName = "Order Issue Types";
const createPageName = "Create Order Issue Type";
const editPageName = "Edit Order Issue Type";

// appears on alerts; example: Entity created successfully
const entityName = "Order Issue Type";

const resource = enums.Resource.SETTINGS;

const layout = "/admin";

const path = "order-issue-types";

const formStructure = orderIssueTypeForm;

const dataResource = api.orderIssueTypes;

const columns = [
  {
    title: "Order Issue Type",
    dataIndex: "name",
    canBeHighlighted: true,
  },
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: "edit",
  render: () => (
    <DataList
      entityName={entityName}
      path={path}
      columns={columns}
      actions={{ edit: true, delete: true }}
      dataResource={dataResource}
      resource={resource}
      listPageName={listPageName}
      formStructure={formStructure}
      formType={enums.FormContainerType.MODAL}
    />
  ),
  action: "read",
  resource,
  layout: layout,
  // randomKey: true,
  children: true,
  superAdmin: true,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          create
        />
      ),
      layout: layout,
      randomKey: true,
      action: "create",
      superAdmin: true,
      resource,
    },
    {
      path: `/${path}/:id/edit`,
      name: editPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          edit
        />
      ),
      layout: layout,
      randomKey: true,
      action: "update",
      superAdmin: true,
      resource,
    },
  ],
};
