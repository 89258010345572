import { dangerColor } from "assets/jss/material-dashboard-pro-react";

const styles = (theme) => ({
  root: {
    padding: 0,
    // paddingRight: 70,
  },
  section: {
    width: "100%",
    marginBottom: 80,
  },
  headerContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    marginBottom: -10,
  },
  header: {
    marginTop: 30,
    marginBottom: 15,
  },
  addButton: {
    padding: "5px 15px",
    zIndex: 20,
    display: "flex",
    alignItems: "center",
    marginRight: 10,
  },
  addIcon: {
    width: "16px",
    zIndex: "4",
    color: "white",
    transition: "all 0.3s",
    width: "20px !important",
    height: "20px !important",
    marginBottom: 2,
    marginRight: 5,
  },
  actionsContainer: {
    display: "flex",
    width: "fit-content",
  },
  tableContainer: {
    width: "100%",
    marginTop: 25,
  },
  removeButton: {
    margin: "0px 5px",
  },
  removeIcon: {
    width: "30px !important",
    height: "30px !important",
  },
  error: {
    color: dangerColor[0],
    marginTop: 15,
    paddingLeft: 5,
  },
  noTransfersMessage: {
    fontSize: 20,
    fontWeight: 400,
    marginTop: 40,
    marginBottom: 30,
  },
});

export default styles;
