import enums from "enums/index";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import AddStockTransferSheetValidation from "./AddStockTransferSheetModal.validation";

//-------------------------------------------------------------------------------------

export default function useAddStockTransferSheetModal({
  onClose,
  successCallback,
}) {
  const [sendRequest, isLoading] = useHttpClient(true);

  const { i18n } = useTranslation();

  const dataResource = React.useMemo(() => api.stockItemsTransfers, []);

  const resolver = useYupValidationResolver(AddStockTransferSheetValidation());

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const { handleSubmit, getValues, setValue } = form;
  const submitHandler = async () => {
    let values = getValues();

    values = {
      ...values,
      transfersDocumentUrl: values?.transfersSheet,
      toId: values.to?.id,
    };

    try {
      const newTransfers = await sendRequest(
        dataResource.uploadTransferSheet(values)
      );

      onClose();

      toast.success(`Transfer Requests added successfully`);
    } catch (err) {
      console.log(err);
    }
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    isLoading,
    i18n,
    form,
    submitHandler,
    onError,
    handleSubmit,
  };
}
