import enums from "enums/index";

export const orderStatusColors = {
  [enums.OrderStatus.LEAD]: "#F7DC6F",
  [enums.OrderStatus.PLANNED]: "#FFA500",
  [enums.OrderStatus.ASSIGNED]: "#BB9F88",
  [enums.OrderStatus.IN_PROGRESS]: "#1E90FF",
  [enums.OrderStatus.COMPLETED]: "#32CD32",
  [enums.OrderStatus.CANCELLED]: "#f44336",
  [enums.OrderStatus.PENDING_STOCK_APPROVAL]: "#9e9e9e",
  [enums.OrderStatus.STOCK_REJECTED]: "#FFA500",
};

export const driverShiftStatusColors = {
  [enums.DriverShiftStatus.ACTIVE]: "#1E90FF",
  [enums.DriverShiftStatus.COMPLETED]: "#32CD32",
};
