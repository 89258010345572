import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModal/CustomModal";
import FormField from "components/Forms/FormField/FormField";
import GridContainer from "components/Grid/GridContainer.js";
import React from "react";
import GridItem from "components/Grid/GridItem.js";
import PropTypes from "prop-types";
import api from "services/api";
import styles from "./SerializedItemModal.styles";
import enums from "enums/index";
import { displayProductName } from "utils/index";
import InfoWithLabel from "components/InfoWithLabel/InfoWithLabel";
import useSerializedItemModal from "./SerializedItemModal.hook";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function SerializedItemModal(props) {
  const {
    isOpen,
    onClose,
    item: itemProp,
    onEdit,
    order,
    completeOrderForm,
  } = props;

  const classes = useStyles();

  const {
    submitHandler,
    form,
    isLoading,
    handleSubmit,
    onError,
  } = useSerializedItemModal({
    itemProp,
    onClose,
    order,
    completeOrderForm,
    onEdit,
  });

  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ margin: 0 }}>
            <CardBody>
              <div className={classes.formHeader}>
                <React.Fragment>
                  <EditIcon className={classes.icon} /> Add Serial Number(s)
                </React.Fragment>
              </div>
              <form
                style={{ padding: 20, paddingBottom: 0 }}
                onSubmit={(e) => {
                  if (e) {
                    if (typeof e.preventDefault === "function") {
                      e.preventDefault();
                    }
                    if (typeof e.stopPropagation === "function") {
                      e.stopPropagation();
                    }
                  }
                  handleSubmit(submitHandler, onError)();
                }}
                autoComplete="off"
              >
                <InfoWithLabel
                  label="Product"
                  info={itemProp ? displayProductName(itemProp.product) : "N/A"}
                />
                <InfoWithLabel
                  label="Amount"
                  info={itemProp ? itemProp.amount : "N/A"}
                />
                <FormField
                  md={12}
                  name="serialNumbers"
                  type="text"
                  label={`Please Enter ${itemProp.amount} Serial Number(s)`}
                  form={form}
                  options={{
                    rows: 4,
                    multiline: true,
                  }}
                />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    color="primary"
                    type="submit"
                    style={{ margin: "20px 0" }}
                    create
                    loadFromRight
                    loading={isLoading}
                  >
                    Add
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </CustomModal>
  );
}

SerializedItemModal.defaultProps = {
  onClose: () => {},
};

SerializedItemModal.propTypes = {
  item: PropTypes.object,
  onClose: PropTypes.func,
};
