import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Skeleton from "@material-ui/lab/Skeleton";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import FormFieldGridItem from "components/Forms/FormField/FormFieldGridItem";
import GridContainer from "components/Grid/GridContainer.js";
import Loading from "components/Loading/Loading";
import PropTypes from "prop-types";
import api from "services/api";
import { useCarsForm } from "./CarsForm.hook";
import styles from "./CarsForm.styles";
import React from "react";
import { displayCarName, displayCustomerName } from "utils/index";
import GridItem from "components/Grid/GridItem";
import FormField from "components/Forms/FormField/FormField";
import Info from "components/Typography/Info";
import InfoWithLabel from "components/InfoWithLabel/InfoWithLabel";
import enums from "enums/index";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function CarsForm(props) {
  const { edit, create, car, owner } = props;

  const { isLoading, isGetLoading, form, data, watch, onSubmit } = useCarsForm(
    props
  );

  const classes = useStyles();
  const maker = watch("maker");
  const model = watch("model");
  const year = watch("year");
  return (
    <div className={classes.formRoot}>
      <div className={classes.formHeader}>
        {create ? (
          <AddIcon className={classes.icon} />
        ) : (
          <EditIcon className={classes.icon} />
        )}
        {edit ? (
          isGetLoading ? (
            <Skeleton variant="rect" height={25} width={250} />
          ) : (
            `Edit ${car?.owner?.name}'s Car`
          )
        ) : (
          `Create Customer Car`
        )}
      </div>

      <Loading loading={isGetLoading} style={{ height: "60vh" }}>
        <form
          style={{ padding: 40, paddingBottom: 0, paddingTop: 0 }}
          onSubmit={onSubmit}
          autoComplete="off"
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <FormField
                name="owner"
                md={12}
                fetchedName="owner"
                type="autocomplete"
                label="Owner"
                form={form}
                options={{
                  getData: api.customers.getAll,
                  getOptionLabel: (option) => displayCustomerName(option),
                  getSubmitValue: (option) => option?.id,
                  shouldThrottle: true,
                  blurOnSelect: false,
                }}
              />
              <FormField
                type="autocomplete"
                form={form}
                md={12}
                name="maker"
                fetchedName="maker"
                label="Car Maker"
                options={{
                  getData: api.carMakers.getAll,
                  getOptionLabel: (option) => option.name,
                  getSubmitValue: (option) => option?.id,
                  loadOnFocus: true,
                  blurOnSelect: false,
                }}
              />
              <FormField
                type="autocomplete"
                md={12}
                form={form}
                name="model"
                fetchedName="model"
                label="Car Model"
                options={{
                  getData: () =>
                    api.carModels.getCarModelByMakerName(maker?.id),
                  getOptionLabel: (option) => option.name,
                  getSubmitValue: (option) => option?.id,
                  loadOnFocus: true,
                  blurOnSelect: false,
                  disabled: !maker,
                  disabledTooltipText: "Please select a car maker first",
                }}
              />

              <FormField
                type="autocomplete"
                form={form}
                md={12}
                name="year"
                fetchedName="year"
                label="Car Year"
                options={{
                  getData: () =>
                    api.carModels.getCarYearsByModelName(model?.name),
                  getOptionLabel: (option) =>
                    option?.year?.toString() || "Unknown",
                  loadOnFocus: true,
                  blurOnSelect: false,
                  disabled: !model,
                  disabledTooltipText: "Please select a car model first",
                }}
              />
              <InfoWithLabel
                label="Selected Car Model"
                info={
                  year
                    ? displayCarName(year)
                    : car
                    ? displayCarName(car.model)
                    : "N/A"
                }
              />
              <InfoWithLabel
                label="Recommended Battery Size"
                info={year?.stockBatterySize?.name || "N/A"}
              />

              <InfoWithLabel
                label="Recommended Wiper Blade Size"
                info={year?.stockWiperBladeSize?.name || "N/A"}
              />

              <FormField
                name="batterySize"
                md={12}
                fetchedName="batterySizeId"
                type="autocomplete"
                form={form}
                label="Battery Size"
                options={{
                  getData: api.sizes.getAll,
                  filters: { type: enums.SizeType.BATTERIES },
                  getOptionLabel: (option) => option?.name,
                  getSubmitValue: (option) => option?.id,

                  loadOnFocus: true,
                }}
              />
              <FormField
                name="wiperBladeSize"
                md={12}
                fetchedName="wiperBladeSizeId"
                type="autocomplete"
                form={form}
                label="Wiper Blade Size"
                options={{
                  getData: api.sizes.getAll,
                  filters: { type: enums.SizeType.WB },
                  getOptionLabel: (option) => option?.name,
                  getSubmitValue: (option) => option?.id,

                  loadOnFocus: true,
                }}
              />
            </GridItem>
          </GridContainer>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="primary"
              type="submit"
              loading={isLoading}
              style={{ margin: "20px 0" }}
              create={create}
              edit={edit}
              loadFromRight
            />
          </div>
        </form>
      </Loading>
    </div>
  );
}

CarsForm.propTypes = {
  edit: PropTypes.bool,
  create: PropTypes.bool,
};
